import React, { useState, useEffect } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import useSWR from "swr";

import { fetcher } from "../../api";
import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Trusted by": { is: "Treyst af" },
  "companies.": { is: "fyrirtækjum." },
  "Tracked time so far: ": { is: "Skráður tími hingað til: " },
  " seconds.": { is: " sekúndur."},
  "That is ": { is: "Það eru "},
  " years!": { is: " ár!"},
};

const t = key => translate(key, translations);

// Locale aware formatter. For example 1,333,337 in US and 1.333.337 in IS.
const locale = navigator.language || "en-US";
const formatter_no_decimals = new Intl.NumberFormat(locale, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

/**
 * A textual social proof on the front page. Displays a text like:
 *
 *   Trusted by 403 companies.
 *
 *     Tracked time so far:
 *        17,745,978,540
 *           seconds.
 *
 *      That is 562 years!
 *
 * Tried using CSS animations to get a quick changing effect while just
 * updating the DOM every 1 second. Could not get it to work just right. Could
 * not figure out how to avoid the number 99 to scroll really fast down to 0 in
 * one second. Looked great otherwise. If attempted again later:
 * https://css-tricks.com/animating-number-counters/
 */
function SocialProof(props) {
  const [companies, setCompanies] = useState(403);
  const [seconds, setSeconds] = useState(17745944303);
  const [rate, setRate] = useState(61); // number of open time entries

  /**
   * Fetches data for the social proof and periodically refresh it and update
   * local component state to mirror the new reality.
   *
   * Note that this usage pattern of SWR is unusual. Usually you would work
   * with the returned data. But because we keep ticking the total time up
   * we have no need for the returned data but just set state explicitly.
   *
   * Example JSON response from /social_statistic:
   *
   *   {
   *     "companies": 380,
   *     "totalSeconds": 15455216744,
   *     "rate": 50
   *    }
   */
  useSWR("/social_statistic", fetcher, {
    refreshInterval: 1000 * 60, // 1 minute
    onSuccess: (data, key, config) => {
      setCompanies(data.companies);
      setSeconds(data.totalSeconds);
      setRate(data.rate);
    },
  });

  /** Updates the second counter at an interval. */
  useEffect(() => {
    // According to GPT4 100ms refresh interval is perfectly fine
    // in terms of client performance, even on mobile phones.
    const updatesPerSecond = 10;

    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds + rate / updatesPerSecond);
    }, 1000 / updatesPerSecond);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [rate]);

  const secsStrLocaleAware = formatter_no_decimals.format(Math.floor(seconds));
  const years = Math.floor(seconds / 31536000);

  return (
    <section>
      <Grid>
        <Row>
          <Col xs={10} xsOffset={1} md={8} mdOffset={2}>
            <p className="social-proof">
              {t("Trusted by")} {companies} {t("companies.")}
              <br />
              <br />
              {t("Tracked time so far: ")}
              <br />
              <span className="social-proof-seconds">{secsStrLocaleAware}</span>
              <br />
              {t(" seconds.")}
            </p>

            <p className="bg-dark text-center">
              <br />
              {t("That is ")} {years} {t(" years!")}
            </p>
          </Col>
        </Row>
      </Grid>
    </section>
  );
}

export default SocialProof;
