import React from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";

import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Location missing": { is: "Staðsetningu vantar" },
  "No GPS location is associated with this time entry.": {
    is: "Engin GPS staðsetning fylgir þessari vinnu færslu."
  },
  "Possible reasons": { is: "Mögulegar ástæður" },
  "reason1": {
    en: "The time entry was manually added through the timavera.com website.",
    is: "Færslunni var handvirkt bætt við af stjórnanda á timavera.is vefnum.",
  },
  "reason2": {
    en: "The phone could not acquire a reliable location quickly enough " +
      "when opening or closing the time entry.",
    is: "Farsími starfsmanns náði ekki áræðanlegu GPS sambandi í tæka tíð " +
      "við að opna eða loka vinnu færslunni.",
  },
  "reason3": {
    en: "The employee does not grant the Tímavera app location privileges",
    is: "Starfsmaður gefur Tímaveru appinu ekki GPS réttindi.",
  },
  "reason4": {
    en: "The employee has turned GPS off entirely on their phone",
    is: "Starfsmaður hefur alfarið slökt á GPS í símanum.",
  },

  "Close window": { is: "Loka glugga" },
};

const t = key => translate(key, translations);

/**
 * A modal with static text explaining what could cause a GPS location to be
 * missing on a work log.
 */
function MissingLocationModal(props) {
  const { show, onCloseModal } = props;

  return (
    <Modal show={show} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Location missing")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{t("No GPS location is associated with this time entry.")}</p>
        <p>{t("Possible reasons")}:</p>

        <ul>
          <li>{t("reason1")}</li>
          <li>{t("reason2")}</li>
          <li>{t("reason3")}</li>
          <li>{t("reason4")}</li>
        </ul>
      </Modal.Body>

      <Modal.Footer>
        <Button block={true} onClick={onCloseModal}>
          {t("Close window")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MissingLocationModal;
