import React, { useEffect, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import { Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import { checkIfLoggedIn } from "../../actions";
import { FullScreenWhiteLoadingSpinner } from "../../components/LoadingSpinner";
import Frontpage from "../frontpage";
import Header from "../../components/Header";
import { LoggedOut } from "./../../components/modals";

import favicon16x16 from "../../images/favicons/favicon-16x16.png";
import favicon32x32 from "../../images/favicons/favicon-32x32.png";
import appleTouchIcon from "../../images/favicons/apple-touch-icon.png";

/**
 * The dynamic import() statements below specifies how we want pages to be
 * bundled together in chunks. We do this so the entire frontend client isn't
 * loaded on the front page. Over the years the client has gotten bigger to the
 * point it was slowing down visitors on the front page and giving us a poor
 * performance score which may impact our SEO score.
 *
 * We group pages into chunks or bundles. By including the weird
 * webpackChunkName: 'Dashboard' comment in front of the import route we can
 * control the name of the chunk ('Dashboard') and what pages go into what
 * chunk. We group pages into chunks to minimize loading. When a company has
 * logged in it loads the Dashboard chunks (JS & CSS). If/when the dashboard
 * starts to become too big we can just break it up into more granular chunks
 * by changing the Webpack chunk name. We can also skip specifying a
 * webpackChunkName. Then each page will be loaded as a separate chunk, and it
 * will be given a number name. However it is beneficial to name the chunks so
 * in case we get an error in Rollbar without a stacktrace we at least get a
 * clue where the bug might be.
 *
 * Note that it is important that we import the pages below from their own
 * files. That is, we should not import them from src/pages/index.js. If we did
 * that we would just create one big bundle because that file statically
 * imports and exports all the pages.
 */
// Other external routes
const Privacy = lazy(() =>
  import(/* webpackChunkName: 'Privacy' */ "../privacy")
);
const Terms = lazy(() => import(/* webpackChunkName: 'Terms' */ "../terms"));
const Cookies = lazy(() =>
  import(/* webpackChunkName: 'Cookies' */ "../cookies")
);
const Changelog = lazy(() =>
  import(/* webpackChunkName: 'Changelog' */ "../changelog")
);

// Download / App Install bundle
const AppInstall = lazy(() =>
  import(/* webpackChunkName: 'DownloadApp' */ "../a")
);
const Download = lazy(() =>
  import(/* webpackChunkName: 'DownloadApp' */ "../download")
);

// Dashboard bundle
const Setup = lazy(() =>
  import(/* webpackChunkName: 'Dashboard' */ "../setup/index")
);
const Dashboard = lazy(() =>
  import(/* webpackChunkName: 'Dashboard' */ "../dashboard")
);
const ChangePasswordLink = lazy(() =>
  import(/* webpackChunkName: 'Dashboard' */ "../login/ChangePasswordLink")
);
const Login = lazy(() =>
  import(/* webpackChunkName: 'Dashboard' */ "../login/Login")
);
const LoginForgotPassword = lazy(() =>
  import(/* webpackChunkName: 'Dashboard' */ "../login/LoginForgotPassword")
);
const LoginLink = lazy(() =>
  import(/* webpackChunkName: 'Dashboard' */ "../login/LoginLink")
);
const LoginTimeClock = lazy(() =>
  import(/* webpackChunkName: 'TimeClock' */ "../login/LoginTimeClock")
);
const Report = lazy(() =>
  import(/* webpackChunkName: 'Dashboard' */ "../Report")
);

// Timeclock bundle
const TimeClock = lazy(() =>
  import(/* webpackChunkName: 'TimeClock' */ "../TimeClock")
);

// Admin bundle
const Admin = lazy(() =>
  import(/* webpackChunkName: 'Admin' */ "../admin/admin")
);
const AdminLogin = lazy(() =>
  import(/* webpackChunkName: 'Admin' */ "../admin/login")
);
const AdminScripts = lazy(() =>
  import(/* webpackChunkName: 'Admin' */ "../admin/scripts")
);

// Emplooyee bundle
const EmployeeDashboard = lazy(() =>
  import(/* webpackChunkName: 'Employee' */ "../employee/dashboard")
);
const EmployeeLogin = lazy(() =>
  import(/* webpackChunkName: 'Employee' */ "../employee/login")
);

const AppWrapper = ({ user, error, dispatch }) => {
  useEffect(() => {
    dispatch(checkIfLoggedIn());
  }, [dispatch]);

  const appRoutes = loggedIn => {
    if (loggedIn === "COMPANY") {
      // Routes that require a company to be logged in
      return [
        <Route exact path="/app" component={Dashboard} key={1} />,
        <Route exact path="/app/report" component={Report} key={2} />,
        <Route exact path="/app/setup" component={Setup} key={4} />,
      ];
    }
    return null;
  };

  const timeClockRoutes = loggedIn => {
    if (loggedIn === "COMPANY_TIME_CLOCK") {
      // Routes that require a company to be logged in in time clock mode
      return [<Route exact path="/time_clock" component={TimeClock} key={1} />];
    }
  };

  const adminRoutes = loggedIn => {
    if (loggedIn === "ADMIN") {
      // Routes that require an admin to be logged in
      return [
        <Route exact path="/admin/app" component={Admin} key={1} />,
        <Route exact path="/admin/scripts" component={AdminScripts} key={2} />,
      ];
    }
    return null;
  };

  const employeeRoutes = loggedIn => {
    if (loggedIn === "EMPLOYEE") {
      // Routes that require an employee to be logged in
      return [
        <Route
          exact
          path="/employee/app"
          component={EmployeeDashboard}
          key={1}
        />,
      ];
    }
    return null;
  };

  /**
   * Routes for changelog update posts. Each path technically only requires
   * "/changelog/YYYY-MM-DD" but in practice the paths look like this:
   * "/changelog/YYYY-MM-DD/title-of-update-post". The last bit of the path is
   * deliberately not required. We do this to grant us flexibility to change
   * update post titles later on without breaking links and it future proofs
   * our site if we add a language selector.
   */
  const updatePostRoutes = () => {
    return [
      <Route
        key="2022-11-23"
        path="/changelog/2022-11-23"
        exact={false}
        component={lazy(() => import("../changelog/2022-11-23"))}
      />,
      <Route
        key="2022-10-15"
        path="/changelog/2022-10-15"
        exact={false}
        component={lazy(() => import("../changelog/2022-10-15"))}
      />,
      <Route
        key="2022-09-26"
        path="/changelog/2022-09-26"
        exact={false}
        component={lazy(() => import("../changelog/2022-09-26"))}
      />,
      <Route
        key="2022-06-01"
        path="/changelog/2022-06-01"
        exact={false}
        component={lazy(() => import("../changelog/2022-06-01"))}
      />,
      <Route
        key="2022-02-22"
        path="/changelog/2022-02-22"
        exact={false}
        component={lazy(() => import("../changelog/2022-02-22"))}
      />,
      <Route
        key="2022-01-30"
        path="/changelog/2022-01-30"
        exact={false}
        component={lazy(() => import("../changelog/2022-01-30"))}
      />,
      <Route
        key="2022-01-28"
        path="/changelog/2022-01-28"
        exact={false}
        component={lazy(() => import("../changelog/2022-01-28"))}
      />,
      <Route
        key="2022-01-12"
        path="/changelog/2022-01-12"
        exact={false}
        component={lazy(() => import("../changelog/2022-01-12"))}
      />,
      <Route
        key="2021-12-31"
        path="/changelog/2021-12-31"
        exact={false}
        component={lazy(() => import("../changelog/2021-12-31"))}
      />,
    ];
  };

  return (
    <>
      <Helmet>
        {/* Favicons */}
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
      </Helmet>
      <Header />
      <Suspense fallback={<FullScreenWhiteLoadingSpinner />}>
        <Switch>
          <Route exact path="/" component={Frontpage} />
          <Route exact path="/a" component={AppInstall} />
          <Route exact path="/download" component={Download} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/login_link" component={LoginLink} />
          <Route
            exact
            path="/change_password_link"
            component={ChangePasswordLink}
          />
          <Route exact path="/login_time_clock" component={LoginTimeClock} />
          <Route exact path="/login_employee" component={EmployeeLogin} />
          <Route
            exact
            path="/forgot_password"
            component={LoginForgotPassword}
          />
          <Route exact path="/admin" component={AdminLogin} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/cookies" component={Cookies} />
          <Route exact path="/changelog" component={Changelog} />
          {updatePostRoutes()}

          {/* These routes require a user role to be rendered */}
          {adminRoutes(user.role)}
          {appRoutes(user.role)}
          {timeClockRoutes(user.role)}
          {employeeRoutes(user.role)}
          {/* Default to redirecting users to the home page */}
          <Redirect to="/" />
        </Switch>
      </Suspense>
      <LoggedOut
        show={error.shouldLogOut}
        routeToFrontPage={() => dispatch({ type: "LOG_OUT", payload: {} })}
      />
    </>
  );
};

export default connect(store => {
  return {
    user: store.user,
    error: store.error,
  };
})(AppWrapper);
