import React from "react";
import { Alert, Button, Modal, Form } from "react-bootstrap/lib";

import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Switch to time clock": { is: "Skipta yfir í stimpilklukku" },
  "Confirmation": { is: "Staðfesting" },
  "switch-to-time-clock-explanation-1": {
    en: "You are about to switch over to the 'time clock' account.",
    is: "Þú ert að fara skipta yfir í 'stimpilklukku' aðganginn.",
  },
  "switch-to-time-clock-explanation-2": {
    en: "All employees can use the website time clock on a single computer to clock their hours.",
    is: "Þar geta allir starfsmenn notað vefsíðu stimpilklukkuna á sameiginlegri tölvu til þess að klukka inn tímana sína.",
  },
  "switch-to-time-clock-explanation-3": {
    en: "To log back to your main company account, click on:",
    is: "Til þess að skipta aftur yfir í fyrirtækja aðganginn smellir þú á:"
  },
  "Log into company account": {
    is: "Skrá inn á fyrirtækja aðgang",
  },
  "Confirm": { is: "Staðfesta" },
  "Close": { is: "Loka" },
};

const t = key => translate(key, translations);

const SwitchToTimeClock = props => {
  const { show, onSubmit, onCloseModal } = props;

  return (
    <Modal show={show} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Switch to time clock")}</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
          onCloseModal();
        }}
      >
        <Modal.Body>
          <Alert bsStyle="info">
            <h4>{t("Confirmation")}</h4>
            <p>{t("switch-to-time-clock-explanation-1")}</p>
            <p>{t("switch-to-time-clock-explanation-2")}</p>
            <br />
            <p>{t("switch-to-time-clock-explanation-3")}</p>
            <p>
              <strong>{t("Log into company account")}</strong>
            </p>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            bsStyle="success"
            onClick={e => {
              e.preventDefault();
              onSubmit();
              onCloseModal();
            }}
          >
            {t("Confirm")}
          </Button>
          <Button onClick={onCloseModal}>{t("Close")}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SwitchToTimeClock;
