import React from "react";
import { Dropdown, MenuItem } from "react-bootstrap";
import { getLanguage } from "../utils/Translations";

/**
 * This will add a item to a list of options that allows users to deselect
 * (not filter by anything).
 */
const optionFrom = list => {
  const noneValueLabel = getLanguage() === "is" ? "Allt" : "Everything";

  let copyOfList = list.slice();
  const noneValue = { id: false, name: noneValueLabel };
  copyOfList.unshift(noneValue);
  return copyOfList;
};

/**
 * This is a down button component populated with a list of items in the format:
 *
 *   [
 *     {
 *       id: integer or false,
 *       name: 'some name',
 *     },
 *     ...
 *   ]
 *
 * The component will display the default title, pass down as a prop, if no
 * item is selected. If an item is selected the component will display the name
 * of that item (item.name).
 *
 * Params:
 *   data: The list of items that can be picked
 *   title: The default title that is displayed if nothing is selected
 *   disabled: boolean,
 *   selected: The selected item
 *   onFilter: Callback function that is called when a item is selected
 */
export default function FilterBy(props) {
  const { data, title, disabled, selected, onFilter } = props;

  const getTitle = () => {
    const fromSelected = optionFrom(data).find(item => item.id === selected);
    if (!fromSelected || fromSelected.id === false) {
      return title;
    }
    return fromSelected.name;
  };

  return (
    <Dropdown
      id="bg-nested-dropdown"
      vertical={true}
      block={true}
      disabled={disabled}
    >
      <Dropdown.Toggle
        block={true}
        // For some weird reason the vertical prop in
        // <Dropdown.Toggle> must be a string instead of bool. See:
        // https://github.com/react-bootstrap/react-bootstrap/issues/2043
        vertical="true"
        bsStyle={selected === false ? "default" : "warning"}
      >
        {getTitle()}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {optionFrom(data).map((item, i) => {
          return (
            <MenuItem
              key={i}
              eventKey={i}
              onClick={() => {
                onFilter(item.id);
              }}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
