import sortBy from "../utils/SortBy";

export default function reducer(
  state = {
    data: [],
    sortedData: [],
    sortedBy: {
      attribute: "latest_work_log",
      isDesc: true,
    },
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case "CREATE_PROJECT":
    case "FETCH_PROJECTS":
    case "CLOSE_PROJECT":
    case "OPEN_PROJECT":
    case "EDIT_PROJECT": {
      return { ...state, fetching: true };
    }
    case "CREATE_PROJECT_REJECTED":
    case "FETCH_PROJECTS_REJECTED":
    case "CLOSE_PROJECT_REJECTED":
    case "OPEN_PROJECT_REJECTED":
    case "EDIT_PROJECT_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_PROJECTS_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.slice(0),
        sortedData: action.payload.slice(0),
      };
    }
    case "CREATE_PROJECT_FULLFILLED": {
      const newState = {
        ...state,
        fetching: false,
        fetched: true,
      };
      newState.data.unshift(action.payload);
      newState.sortedData.unshift(action.payload);
      return newState;
    }
    case "CLOSE_PROJECT_FULFILLED":
    case "OPEN_PROJECT_FULFILLED":
    case "EDIT_PROJECT_FULFILLED": {
      state.data.forEach((item, i) => {
        if (item.id === action.payload.id) state.data[i] = action.payload;
      });
      state.sortedData.forEach((item, i) => {
        if (item.id === action.payload.id) state.sortedData[i] = action.payload;
      });
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: state.data,
        sortedData: state.sortedData,
      };
    }
    case "SORT_PROJECT": {
      const attribute = action.payload;
      const res = sortBy(
        state.sortedBy.attribute,
        attribute,
        state.sortedBy.isDesc,
        state.data
      );

      return {
        ...state,
        sortedData: res.sorted,
        sortedBy: {
          attribute: attribute,
          isDesc: res.isDesc,
        },
      };
    }
    default: {
      return state;
    }
  }
}
