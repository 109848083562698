import React, { Component } from "react";
import { Button, Table } from "react-bootstrap/lib";
import { Alert, ButtonToolbar } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import Imgix from "react-imgix";

import {
  DownloadProjectExcel,
  DownloadEmployeeExcel,
  DownloadSummaryProjectsExcel,
  DownloadSummaryEmployeesExcel,
  getExcelFileNameDateRange,
} from "./DownloadExcel";
import { getFormattedDate, toHoursAndMinutes } from "../utils/DateFormat";
import { numberToFixed, numberToFixedString } from "../utils/Numbers";
import { isImageComment } from "../utils/HelperFunctions";
import { translate } from "../utils/Translations";
import { Spacer } from "../pages/setup/SetupLayout";

// prettier-ignore
const translations = {
  "All projects": { is: "Öll verkefni" },
  "Projects": { is: "Verkefni" },
  "All employees": { is: "Allir starfsmenn" },
  "Employees": { is: "Starfsmenn" },

  "Date": { is: "Dagsetning" },
  "Employee": { is: "Starfsmaður" },
  "Hours": { is: "Tímar" },
  "Normal": { is: "Dagvinna" },
  "Overtime": { is: "Yfirvinna" },
  "Lunch": { is: "Hádegi" },
  "Comments": { is: "Athugasemdir" },
  "Images": { is: "Myndir" },
  "Image": { is: "Mynd" },

  "Entries": { is: "Færslur" },
  "Project": { is: "Verkefni" },

  "Print": { is: "Prenta" },
  "Print for: ": { is: "Prenta fyrir: " },
  "Total hours": { is: "Samtals tímar" },

  "overtimeThresholdMessage": {
    en: "Please note that project overtime can not be calculated if overtime " +
      "should be paid after X many hours worked (for example 8 hours). It " +
      "works as expected for employee reports.",
    is: "Vinsamlegast athugið að ekki er hægt að reikna yfirvinnu verkefna " +
      "ef að það er valið að borga yfirvinnu eftir X klst unnar (til dæmis " +
      "eftir 8 klst). Sú yfirvinna er birt og reiknast rétt í starfsmanna " +
      "skýrslum.",
  },

  "Tímavera all projects": { is: "Tímavera öll verkefni" },
  "Tímavera all employees": { is: "Tímavera allir starfsmenn" },
};

const t = key => translate(key, translations);

/** A clickable image comment */
function ImageComment({ comment }) {
  // Serve images from our image CDN imigx instead of directly from S3
  const imgixUrl = comment.replace(
    "https://timavera-images.s3-eu-west-1.amazonaws.com/",
    "https://timavera.imgix.net/"
  );

  // When an image is clicked to be opened serve it as an 80% quality JPG. It
  // would be ideal to be able to serve the unoptimized original image.
  // However, at the time of writing the Tímavera app blanket assigns the file
  // extension .jpeg and Content-Type "image/jpeg" to all images despite being
  // potentially of a different format. If the app would correctly save source
  // images we would be to serve the source image directly and conditionally
  // serve JPG versions when the source is an HEIF image from iPhones that has
  // no browser support. Another reason to go JPG here is that if a manager
  // clicks an image there is a chance the image will be saved. Then it will be
  // beneficial to offer a plain old image that is supported everywhere. Users
  // might run into issues with .avif and .webp. But this might not be an
  // issue, needs testing.
  const sourceUrl = imgixUrl + "?fm=jpg&q=80";

  const imgStyle = {
    objectFit: "cover",
    marginBottom: "5px",
  };

  return (
    <a href={sourceUrl} target="_blank" rel="noopener noreferrer">
      <Imgix
        src={imgixUrl}
        sizes="20vw"
        className="img-responsive img-centered"
        htmlAttributes={{
          loading: "lazy",
          style: imgStyle,
        }}
      />
    </a>
  );
}

/** Displays a text comment or an image if settings match up */
function Comment({ comment, displayComments, displayImages }) {
  if (comment === "") return null;

  if (isImageComment(comment)) {
    return displayImages ? <ImageComment comment={comment} /> : null;
  } else {
    return displayComments ? <p>{comment}</p> : null;
  }
}

/**
 * Returns the appropriate table header depending on if comments and/or images
 * should be shown. Returns null if both should be hidden.
 */
function ThCommentsImages({ displayComments, displayImages }) {
  let text = "";

  if (displayComments && displayImages) {
    text = t("Comments") + " & " + t("Images");
  } else if (displayComments) {
    text = t("Comments");
  } else if (displayImages) {
    text = t("Images");
  } else {
    return null;
  }

  return <th className="text-center">{text}</th>;
}

/** Returns a table cell for comments and/or images */
function TdCommentsImages({ comments, displayComments, displayImages }) {
  if (!displayComments && !displayImages) return null;

  return (
    <td>
      {comments.map(comment => (
        <Comment
          key={Math.random()}
          comment={comment}
          displayComments={displayComments}
          displayImages={displayImages}
        />
      ))}
    </td>
  );
}

class Report extends Component {
  printSummaryProjectsRef = null;
  printSummaryEmployeesRef = null;
  printProjectReport = []; // references for project tables to print
  printEmployeeReport = []; // references for employee tables to print

  /**
   * Returns an object with the data for the projects summary table for Excel.
   *
   * Example of expected input:
   * {
   *   "Project A": {
   *     over_time: 0,
   *     regular_time: 1337,
   *   },
   *
   *   "Project B": {
   *     over_time: 0,
   *     regular_time: 1337,
   *   },
   * }
   *
   * There are other attributes but those are the ones that matter.
   */
  mapSummaryProjectsToExcel = projects => {
    return Object.entries(projects).map(([projectName, projectReport]) => {
      const regularTime = projectReport.regular_time;
      const overTime = projectReport.over_time;

      return {
        project: projectName,
        hours: parseFloat(numberToFixed(regularTime + overTime)),
        regulartime: parseFloat(numberToFixed(regularTime)),
        overtime: parseFloat(numberToFixed(overTime)),
      };
    });
  };

  /**
   * Returns an object with the data for the employees summary table for Excel.
   *
   * Example of expected input:
   * {
   *   "employee a": {
   *     over_time: 0,
   *     regular_time: 1337,
   *   },
   *
   *    "employee b": {
   *      over_time: 0,
   *      regular_time: 1337,
   *    },
   * }
   */
  mapSummaryEmployeesToExcel = employees => {
    return Object.entries(employees).map(([employeeName, employeeReport]) => {
      const regularTime = employeeReport.regular_time;
      const overTime = employeeReport.over_time;

      return {
        employee: employeeName,
        hours: parseFloat(numberToFixed(regularTime + overTime)),
        regulartime: parseFloat(numberToFixed(regularTime)),
        overtime: parseFloat(numberToFixed(overTime)),
      };
    });
  };

  mapProjectToExcel = project => {
    // const { hideProjectOvertime } = this.props;

    return project.logs.map(log => {
      return {
        date: getFormattedDate(log.date),
        entries:
          "" +
          toHoursAndMinutes(log.start) +
          " - " +
          toHoursAndMinutes(log.end),
        employee: log.employee,
        hours: parseFloat(numberToFixed(log.regular_time + log.over_time)),
        regulartime: parseFloat(numberToFixed(log.regular_time)),
        overtime: parseFloat(numberToFixed(log.over_time)),
        comments: log.comments,
      };
    });
  };

  mapEmployeeToExcel = employee => {
    return Object.keys(employee.date).map(date => {
      const data = employee.date[date];
      return {
        date: getFormattedDate(date),
        entries: data.logs
          .map(
            log =>
              "" +
              toHoursAndMinutes(log.start) +
              " - " +
              toHoursAndMinutes(log.end)
          )
          .join(", \n"),
        projects: data.logs.map(log => log.project).join(", \n"),
        hours: parseFloat(numberToFixed(data.regular_time + data.over_time)),
        regulartime: parseFloat(numberToFixed(data.regular_time)),
        overtime: parseFloat(numberToFixed(data.over_time)),
        comments: data.logs.map(log => log.comments).flat(),
      };
    });
  };

  render() {
    const {
      report,
      from,
      to,
      displayComments,
      displayImages,
      displayLunchDeductions,
      hideProjectOvertime,
    } = this.props;

    const period = getExcelFileNameDateRange(from, to);

    return (
      <div>
        {/* ======================== SUMMARY TABLES ======================== */}
        {/* Projects summary table, one line: one project */}
        <div ref={el => (this.printSummaryProjectsRef = el)}>
          <h3>{t("All projects")}</h3>
          <Table responsive striped bordered condensed hover>
            <thead>
              <tr>
                <th className="text-center">{t("Project")}</th>
                <th className="text-center">{t("Hours")}</th>
                {!hideProjectOvertime && (
                  <>
                    <th className="text-center">{t("Normal")}</th>
                    <th className="text-center">{t("Overtime")}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {Object.keys(report.projects).map((name, i) => {
                const project = report.projects[name];
                const hours = numberToFixedString(
                  project.regular_time + project.over_time
                );

                return (
                  <tr key={i}>
                    <td>
                      <a
                        href={`/app#project-${i}`}
                        className="hidden-print-link"
                      >
                        {name}
                      </a>
                    </td>
                    <td className="text-center">{hours}</td>
                    {!hideProjectOvertime && (
                      <>
                        <td className="text-center">
                          {numberToFixedString(project.regular_time)}
                        </td>
                        <td className="text-center">
                          {numberToFixedString(project.over_time)}
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <ButtonToolbar>
          <ReactToPrint
            trigger={() => (
              <Button className="hidden-print">{t("Print")}</Button>
            )}
            content={() => this.printSummaryProjectsRef}
          />
          <DownloadSummaryProjectsExcel
            data={this.mapSummaryProjectsToExcel(report.projects)}
            filename={`${t("Tímavera all projects")} ${period}`}
          />
        </ButtonToolbar>

        <Spacer />

        {/* Employees summary table, one line: one employee */}
        <div ref={el => (this.printSummaryEmployeesRef = el)}>
          <h3>{t("All employees")}</h3>
          <Table responsive striped bordered condensed hover>
            <thead>
              <tr>
                <th className="text-center">{t("Employee")}</th>
                <th className="text-center">{t("Hours")}</th>
                <th className="text-center">{t("Normal")}</th>
                <th className="text-center">{t("Overtime")}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(report.employees).map((name, i) => {
                const employee = report.employees[name];
                const hours = numberToFixedString(
                  employee.regular_time + employee.over_time
                );

                return (
                  <tr key={i}>
                    <td>
                      <a
                        href={`/app#employee-${i}`}
                        className="hidden-print-link"
                      >
                        {name}
                      </a>
                    </td>
                    <td className="text-center">{hours}</td>
                    <td className="text-center">
                      {numberToFixedString(employee.regular_time)}
                    </td>
                    <td className="text-center">
                      {numberToFixedString(employee.over_time)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <ButtonToolbar>
          <ReactToPrint
            trigger={() => (
              <Button className="hidden-print">{t("Print")}</Button>
            )}
            content={() => this.printSummaryEmployeesRef}
          />
          <DownloadSummaryEmployeesExcel
            data={this.mapSummaryEmployeesToExcel(report.employees)}
            filename={`${t("Tímavera all employees")} ${period}`}
          />
        </ButtonToolbar>

        <Spacer />

        {/* =========================== PROJECTS =========================== */}
        <h2>{t("Projects")}</h2>
        {hideProjectOvertime && (
          <Alert>
            <p>{t("overtimeThresholdMessage")}</p>
          </Alert>
        )}

        {/* Each individual project table */}
        {Object.keys(report.projects).map((name, i) => {
          const project = report.projects[name];
          return (
            <div key={i} id={`project-${i}`}>
              <div ref={el => (this.printProjectReport[i] = el)}>
                <h3>{name}</h3>
                <Table
                  responsive
                  striped
                  bordered
                  condensed
                  hover
                  style={{ marginTop: "1em" }}
                >
                  <thead>
                    <tr>
                      <th className="text-center">{t("Date")}</th>
                      <th className="text-center">{t("Entries")}</th>
                      <th className="text-center">{t("Employee")}</th>
                      <th className="text-center">{t("Hours")}</th>
                      {!hideProjectOvertime && (
                        <>
                          <th className="text-center">{t("Normal")}</th>
                          <th className="text-center">{t("Overtime")}</th>
                        </>
                      )}
                      {displayLunchDeductions && (
                        <th className="text-center">{t("Lunch")}</th>
                      )}
                      <ThCommentsImages
                        displayComments={displayComments}
                        displayImages={displayImages}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {project.logs.map((log, i2) => {
                      return (
                        <tr key={i2}>
                          <td className="text-center">
                            {getFormattedDate(log.date)}
                          </td>
                          <td className="text-center">
                            <p key={Math.random()}>
                              {toHoursAndMinutes(log.start)} -{" "}
                              {toHoursAndMinutes(log.end)}
                            </p>
                          </td>
                          <td>{log.employee}</td>
                          <td className="text-center">
                            {numberToFixedString(
                              log.regular_time + log.over_time
                            )}
                          </td>
                          {!hideProjectOvertime && (
                            <>
                              <td className="text-center">
                                {numberToFixedString(log.regular_time)}
                              </td>
                              <td className="text-center">
                                {numberToFixedString(log.over_time)}
                              </td>
                            </>
                          )}
                          {displayLunchDeductions && (
                            <td className="text-center">
                              {numberToFixedString(-log.lunch_deduction)}
                            </td>
                          )}
                          <TdCommentsImages
                            comments={log.comments}
                            displayComments={displayComments}
                            displayImages={displayImages}
                          />
                        </tr>
                      );
                    })}
                    <tr className="tableTotalRow">
                      <td colSpan="3" className="text-right">
                        <span style={{ marginRight: "0.5em" }}>
                          {t("Total hours")}
                        </span>
                      </td>
                      <td className="text-center">
                        {numberToFixedString(
                          project.regular_time + project.over_time
                        )}
                      </td>
                      {!hideProjectOvertime && (
                        <>
                          <td className="text-center">
                            {numberToFixedString(project.regular_time)}
                          </td>
                          <td className="text-center">
                            {numberToFixedString(project.over_time)}
                          </td>
                        </>
                      )}
                      {displayLunchDeductions && <td />}
                      {displayComments && <td />}
                    </tr>
                  </tbody>
                </Table>
              </div>
              <ButtonToolbar
                className="hidden-print"
                style={{ marginBottom: "3em" }}
              >
                <ReactToPrint
                  trigger={() => <Button>{t("Print for: ") + name}</Button>}
                  content={() => this.printProjectReport[i]}
                />
                <DownloadProjectExcel
                  data={this.mapProjectToExcel(project)}
                  filename={`${name} ${period}`}
                  exportComments={displayComments}
                  exportImages={displayImages}
                  bsStyle="default"
                />
              </ButtonToolbar>
            </div>
          );
        })}

        {/* ========================== EMPLOYEES =========================== */}
        <h2 style={{ marginTop: "2em" }}>{t("Employees")}</h2>

        {/* Each individual employee table */}
        {Object.keys(report.employees).map((name, i) => {
          const employee = report.employees[name];
          return (
            <div key={i} id={`employee-${i}`}>
              <div ref={el => (this.printEmployeeReport[i] = el)}>
                <h3>{name}</h3>
                <Table
                  responsive
                  striped
                  bordered
                  condensed
                  hover
                  style={{ marginTop: "1em" }}
                >
                  <thead>
                    <tr>
                      <th className="text-center">{t("Date")}</th>
                      <th className="text-center">{t("Entries")}</th>
                      <th className="text-center">{t("Project")}</th>
                      <th className="text-center">{t("Hours")}</th>
                      <th className="text-center">{t("Normal")}</th>
                      <th className="text-center">{t("Overtime")}</th>
                      {displayLunchDeductions && (
                        <th className="text-center">{t("Lunch")}</th>
                      )}
                      <ThCommentsImages
                        displayComments={displayComments}
                        displayImages={displayImages}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(employee.date).map((date, i2) => {
                      const data = employee.date[date];
                      return (
                        <tr key={i2}>
                          <td className="text-center">
                            {getFormattedDate(date)}
                          </td>
                          <td className="text-center">
                            {data.logs.map(n => {
                              return (
                                <p key={Math.random()}>
                                  {toHoursAndMinutes(n.start)} -{" "}
                                  {toHoursAndMinutes(n.end)}
                                </p>
                              );
                            })}
                          </td>
                          <td>
                            {data.logs.map(n => {
                              return <p key={Math.random()}>{n.project}</p>;
                            })}
                          </td>
                          <td className="text-center">
                            {numberToFixedString(
                              data.regular_time + data.over_time
                            )}
                          </td>
                          <td className="text-center">
                            {numberToFixedString(data.regular_time)}
                          </td>
                          <td className="text-center">
                            {numberToFixedString(data.over_time)}
                          </td>
                          {displayLunchDeductions && (
                            <td className="text-center">
                              {numberToFixedString(-data.lunch_deduction)}
                            </td>
                          )}
                          <TdCommentsImages
                            comments={data.logs.flatMap(log => log.comments)}
                            displayComments={displayComments}
                            displayImages={displayImages}
                          />
                        </tr>
                      );
                    })}
                    <tr className="tableTotalRow">
                      <td colSpan="3" className="text-right">
                        <span style={{ marginRight: "0.5em" }}>
                          {t("Total hours")}
                        </span>
                      </td>
                      <td className="text-center">
                        {numberToFixedString(
                          employee.regular_time + employee.over_time
                        )}
                      </td>
                      <td className="text-center">
                        {numberToFixedString(employee.regular_time)}
                      </td>
                      <td className="text-center">
                        {numberToFixedString(employee.over_time)}
                      </td>
                      {displayLunchDeductions && <td />}
                      {displayComments && <td />}
                    </tr>
                  </tbody>
                </Table>
              </div>
              <ButtonToolbar
                className="hidden-print"
                style={{ marginBottom: "3em" }}
              >
                <ReactToPrint
                  trigger={() => <Button>{t("Print for: ") + name}</Button>}
                  content={() => this.printEmployeeReport[i]}
                />
                <DownloadEmployeeExcel
                  data={this.mapEmployeeToExcel(employee)}
                  filename={`${name} ${period}`}
                  exportComments={displayComments}
                  exportImages={displayImages}
                  bsStyle="default"
                />
              </ButtonToolbar>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Report;
