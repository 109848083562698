import React from "react";
import { Glyphicon, Grid, Row, Col } from "react-bootstrap";

import { translateWithGlobals } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Super simple time tracking for contractors": {
    is: "Ofur einföld tímaskráning fyrir verktaka",
  },

  "Measure employee time": { is: "Nákvæm tímaskráning" },
  "description1": {
    en: "Don’t take guesses on your most expensive resource or risk under " +
      "or overcharging clients. Take back control by accurately tracking " +
      "your contracting hours.",
    is: "Ekki giska á stærsta kostnaðarliðinn og undir eða ofrukka verkaupa. " +
      "Taktu stjórn með nákvæmum tímaskráningum verkefna og starfsmanna.",
  },

  "Simplicity": { is: "Einfalt" },
  "description2": {
    en: "Business owners and managers get an overview of ongoing projects " +
      "and employee hours on the Tímavera website.",
    is: "Eigendur og stjórnendur fá yfirsýn á einum stað yfir" +
      " tímaskráningar verkefna og starfsmanna á vefsíðunni.",
  },

  "Track employee hours": { is: "Einföld stimpilklukka" },
  "description3": {
    en: "Employees track their hours using their smartphones through a " +
      "simple app with optional geo-location support.",
    is: "Starfsmenn skrá tímaskráningar með ofur einföldu stimpilklukku " +
      "appi í snjallsíma sínum.",
  },

  "Get personalised support": { is: "Persónuleg þjónusta" },
  "description4": {
    en: "Reliability and availability is our top priority. Get personal and " +
      "timely support from the Tímavera founders.",
    is: "Áreiðanleiki og uppitími er í algjörum forgangi. Fáðu persónulega " +
      "þjónustu frá stofnendum Tímaveru.",
  },
};

const t = key => translateWithGlobals(key, translations);

/**
 * The initial brief explanation of what Tímavera is. 3 main selling points
 * with icons.
 */
export default function About(props) {
  return (
    <section id="hero" className="bg-dark text-center">
      <Grid>
        <Row>
          <Col md={6} mdOffset={3}>
            <div className="about-content">
              <span style={{ fontSize: "3em" }}>
                <Glyphicon glyph="time" />
              </span>
              <h3>{t("Measure employee time")}</h3>
              <p>{t("description1")}</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} mdOffset={3}>
            <div className="about-content">
              <span style={{ fontSize: "3em" }}>
                <Glyphicon glyph="ok-sign" />
              </span>
              <h3>{t("Simplicity")}</h3>
              <p>{t("description2")}</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} mdOffset={3}>
            <div className="about-content">
              <span style={{ fontSize: "3em" }}>
                <Glyphicon glyph="cog" />
              </span>
              <h3>{t("Track employee hours")}</h3>
              <p>{t("description3")}</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} mdOffset={3}>
            <div className="about-content">
              <span style={{ fontSize: "3em" }}>
                <Glyphicon glyph="heart" />
              </span>
              <h3>{t("Get personalised support")}</h3>
              <p>{t("description4")}</p>
            </div>
          </Col>
        </Row>
      </Grid>
    </section>
  );
}
