import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";

import "./testimonialsvideos.css";
import evaros_thumbnail from "../../images/testimonial/eva_ros_testimonal_video_thumbnail.jpg";
import agust_thumbnail from "../../images/testimonial/agust_testimonal_video_thumbnail.jpg";
import { translateWithGlobals } from "../../utils/Translations";

// prettier-ignore
const translations = {
  // Ágúst
  "TESTIMONIALS_AGUST_YOUTUBE_TITLE": {
    "en": "YouTube video: Ágúst's testimonial on Tímavera timetracking",
    "is": "YouTube myndband: Umsögn Ágústs málarameistara um tímaskráningu " +
      "Tímaveru",
  },
  "TESTIMONIALS_AGUST_YOUTUBE_SRC": {
    "en": "https://www.youtube-nocookie.com/embed/PnezMPMfqbg?rel=0&modestbranding=1",
    "is": "https://www.youtube-nocookie.com/embed/auYSHPYGT7E?rel=0&modestbranding=1",
  },
  "TESTIMONIALS_AGUST_SUMMARY": {
    "en": "Easy, user friendly, simple and comfortable to use.",
    "is": "Auðvelt, notendavænt, einfalt og þægilegt í notkun.",
  },
  "TESTIMONIALS_AGUST_TITLE_COMPANY": {
    "en": "Master Painter, ÁG Málun ehf.",
    "is": "Málarameistari, ÁG Málun ehf.",
  },
  "TESTIMONIALS_AGUST_STARTED": {
    "en": "Started February 2018",
    "is": "Byrjaði Febrúar 2018",
  },

  // Eva Rós
  "TESTIMONIALS_EVA_YOUTUBE_TITLE": {
    "en": "YouTube video: Eva's testimonial on Tímavera timetracking",
    "is": "YouTube myndband: Umsögn Evu hjá Veistluþjónum um tímaskráningu " +
      "Tímaveru",
  },
  "TESTIMONIALS_EVA_YOUTUBE_SRC": {
    "en": "https://www.youtube-nocookie.com/embed/hb0l3zmBJT8?rel=0&modestbranding=1",
    "is": "https://www.youtube-nocookie.com/embed/mwVDB2k7L6A?rel=0&modestbranding=1",
  },
  "TESTIMONIALS_EVA_SUMMARY": {
    "en": "Great timesaver at the end of every month.",
    "is": "Mikill tímasparnaður í kringum hver einustu mánaðarmót.",
  },
  "TESTIMONIALS_EVA_TITLE_COMPANY": {
    "en": "Founder of Veisluþjónar ehf. planning & catering",
    "is": "Eigandi Veisluþjónar ehf.",
  },
  "TESTIMONIALS_EVA_STARTED": {
    "en": "Started February 2018",
    "is": "Byrjaði Febrúar 2018",
  },
};

const t = key => translateWithGlobals(key, translations);

/**
 * Initially displays a thumbnail image with a red play button in the center.
 * If the user clicks anywhere in the image an YouTube iframe with the video is
 * loaded and starts to play when loaded.
 *
 * In the first iteration of having YouTube videos on our frontpage we would
 * just load the YouTube embed iframes directly. However it came at a pretty
 * big performance cost, especially on mobile. The biggest problem was that the
 * iframe loaded the same 440KB JS file twice for each iframe. That's more than
 * our entire JS bundle. Then there were various other assets loaded. To fix
 * this we switched to ReactPlayer so the shared YouTube iframe assets can be
 * fetched only once. Further, we use the `light` mode on the player which
 * means we display an image and a play button instead of immediately loading
 * the iframe. This is a great gain in performance and privacy for our visitors
 * that have interest in the videos to begin with.
 */
const YouTubePlayer = ({ url, thumbnail }) => {
  const playButton = (
    <div className="playButtonContainer">
      <div className="playButton" />
    </div>
  );

  const playerConfig = {
    youtube: {
      playerVars: {
        // Play the video automatically once it loads. We would normally not do
        // this but since we use light mode in ReactPlayer nothings happens
        // until the user clicks the video thumbnail. If we set this to 0 the
        // user would have to click the video thumbnail and then click again to
        // play the video.
        autoplay: 1,
      },
    },
  };

  return (
    <ReactPlayer
      url={url}
      width="100%"
      height="100%"
      className="video-player"
      light={thumbnail}
      playIcon={playButton}
      config={playerConfig}
      controls={true}
    />
  );
};

/**
 * Video testimonials from our users.
 *
 * The videos are hosted by YouTube and embedded on our site with an <iframe>.
 * Few things to note about how the videos are embedded on the site:
 *
 *   1. The URL to the videos is youtube-nocookie.com. This is a more privacy
 *      aware option.
 *   2. rel=0 only show related videos at the end from our YouTube channel
 *   3. modestbranding=1 Don't show the YouTube brand in the player controls at
 *      the bottom
 */
export default function TestimonialsVideos(props) {
  return (
    <section id="testimonials-videos" className="testimonials bg-dark">
      <Grid>
        <Row>
          <Col className="text-center">
            <h2>{t("Testimonials")}</h2>
            <hr className="colored" />
          </Col>
        </Row>

        <Row style={{ marginTop: "2em" }}>
          <Col
            xs={10}
            sm={10}
            md={8}
            lg={8}
            xsOffset={1}
            smOffset={1}
            mdOffset={2}
          >
            <div className="video-container">
              <YouTubePlayer
                url={t("TESTIMONIALS_AGUST_YOUTUBE_SRC")}
                thumbnail={agust_thumbnail}
              />
            </div>

            <p className="lead" style={{ marginTop: "1em" }}>
              "{t("TESTIMONIALS_AGUST_SUMMARY")}"
            </p>

            <p>
              <strong>Ágúst Garðarsson</strong> <br />
              {t("TESTIMONIALS_AGUST_TITLE_COMPANY")} <br />
              {t("TESTIMONIALS_AGUST_STARTED")}
            </p>
          </Col>
        </Row>

        <Row style={{ marginTop: "4em" }}>
          <Col
            xs={10}
            sm={10}
            md={8}
            lg={8}
            xsOffset={1}
            smOffset={1}
            mdOffset={2}
          >
            <div className="video-container">
              <YouTubePlayer
                url={t("TESTIMONIALS_EVA_YOUTUBE_SRC")}
                thumbnail={evaros_thumbnail}
              />
            </div>

            <p className="lead" style={{ marginTop: "1em" }}>
              "{t("TESTIMONIALS_EVA_SUMMARY")}"
            </p>

            <p>
              <strong>Eva Rós Gústafsdóttir</strong> <br />
              {t("TESTIMONIALS_EVA_TITLE_COMPANY")} <br />
              {t("TESTIMONIALS_EVA_STARTED")}
            </p>
          </Col>
        </Row>
      </Grid>
    </section>
  );
}
