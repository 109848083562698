import React from "react";
import { Grid, Row, Col, Image } from "react-bootstrap";

import { translate, lang } from "../../utils/Translations";

/** Dashboard screenshots */
import enDash from "../../images/dashboard/2023/timavera_timetracking_dashboard_screenshot.png";
import isDash from "../../images/dashboard/2023/timavera_timaskraning_stjornbord_skjaskot.png";

/** App screenshots */
import englishLogin from "../../images/app/2022/english/1_login.png";
import englishProjects from "../../images/app/2022/english/2_clockedout.png";
import englishClockedIn from "../../images/app/2022/english/3_clockedin.png";
import englishAddComment from "../../images/app/2022/english/4_comment.png";
import englishEntries from "../../images/app/2022/english/5_entries.png";
import englishEntry from "../../images/app/2022/english/6_entry_no_year.png";
import icelandicLogin from "../../images/app/2022/icelandic/1_login.png";
import icelandicProjects from "../../images/app/2022/icelandic/2_clockedout.png";
import icelandicClockedIn from "../../images/app/2022/icelandic/3_clockedin.png";
import icelandicAddComment from "../../images/app/2022/icelandic/4_comment.png";
import icelandicEntries from "../../images/app/2022/icelandic/5_entries.png";
import icelandicEntry from "../../images/app/2022/icelandic/6_entry_no_year.png";
import { Spacer } from "../setup/SetupLayout";

const APP_STORE_HREF =
  "https://itunes.apple.com/us/app/klukkinn/id1309092162?mt=8";
const PLAY_STORE_HREF =
  "https://play.google.com/store/apps/details?id=is.klukkinn.app";

// prettier-ignore
const translations = {
  "About Tímavera": { is: "Um Tímaveru" },
  "The app": { is: "Appið" },
  "screenshots-from-the-app": {
    en: "Screenshots from the Tímavera timeclock app:",
    is: "Skjáskot úr Tímaveru stimpilklukku appinu:",
  },

  "ABOUT_PARAGRAPH_1": {
    en: "Tímavera is a time tracking app and website for contractors and " +
      "small contracting businesses.",
    is: "Tímavera er tímaskráningarkerfi og stimpilklukka fyrir verktaka og " +
      "lítil fyrirtæki með einfaldar þarfir.",
  },

  "How it works": { is: "Hvernig það virkar" },
  "howItWorks1-part1": {
    en: "Employees record their time through the Tímavera app – available " +
      "for ",
    is: "Starfsmenn skrá tíma sína í gegnum Tímaveru stimpilklukku appið á ",
  },
  " and ": { is: " og " },

  "howItWorks2": {
    en: "Business owners and managers get a company account to the Tímavera " +
      "website where they can view and manage projects.",
    is: "Eigendur og stjórnendur fá fyrirtækja aðgang að vefsíðunni þar sem " +
      "sýslað er með verkefni.",
  },
  "howItWorks3": {
    en: "Simply add new users and projects through the website.",
    is: "Nýjum verkefnum og starfsmönnum er bætt við á einfaldan máta í " +
      "gegnum vefsíðuna.",
  },
  "howItWorks4": {
    en: "Get a detailed overview of the number of hours clocked per employee " +
      "per project.",
    is: "Fáðu yfirsýn yfir skráðar vinnustundir starfsmanna og verkefna.",
  },
  "howItWorks5": {
    en: "Run reports for simple overtime calculations.",
    is: "Útbúðu skýrslur með einföldum yfirvinnu útreikningum.",
  },

  "app for employees to record time": {
    is: "app fyrir starfsmenn til að skrá tíma"
  },
  "Website": { is: "Vefsíða" },
  "for owners to see and manage hours worked": {
    is: "fyrir stjórnendur til að sjá og sýsla með unna tíma"
  },

  "ABOUT_PARAGRAPH_2": {
    en: "Owners or managers get a company account to the website where " +
      "a good overview is given for the number of hours clocked per " +
      "employees and projects. Reports with simple overtime calculations if " +
      "needed are offered on the websites. New users and projects are created " +
      "on the website.",
    is: "Stjórnendur og eða eigendur fyrirtækis fá fyrirtækja aðgang að " +
      "vefsíðunni þar sem hægt er að fá góða yfirsýn yfir unna tíma hvers " +
      "starfsmanns og verkefnis. Skýrslur með einföldum yfirvinnu " +
      "útreikningum ef þörf er á er í boði á vefsíðunni. Á vefsíðunni eru " +
      "nýjir notendur og verkefni búin til.",
  },

  "ABOUT_INTRO": {
    en: "We designed Tímavera to be simple and free from unnecessary features.",
    is: "Við hönnuðum Tímaveru til vera einföld lausn án óþarfa virkni.",
  },
  "ABOUT_PROBLEM_1": {
    en: "No extra equipment or physical setup required.",
    is: "Ekki þarf nein auka tæki eða uppsetningu.",
  },
  "ABOUT_PROBLEM_2": {
    en: "Owners access the website to manage their projects and employees " +
      "use their own phones.",
    is: "Eigendur og stjórnendur nota vefsíðu til að sýsla með verkefni og " +
      "starfsmenn nota sína eigin snjallsíma.",
  },
  "ABOUT_PROBLEM_3": {
    en: "Workers can use geo-location tracking for better time management, " +
      "promoting transparency for clients, bosses, and colleagues.",
    is: "Starfsmenn geta notað GPS staðsetningar í tímaskráningum til að " +
      "auka traust fyrir verkkaupa, eigendur og samstarfsmenn.",
  },
  "ABOUT_PROBLEM_4": {
    en: "Workers can leave comments on their work logs to point out errors " +
      "or mention if they had to purchase supplies.",
    is: "Starfsmenn geta skrifað texta athugasemdir á vinnufærslur ef " +
      "leiðrétta þarf tímaskráningar eða ef efni var keypt.",
  },
  "ABOUT_PARAGRAPH_5": {
    en: "The time tracking system is in active development. We have and " +
      "will always prioritise simplicity, functionality and reliability.",
    is: "Tímaskráningarkerfið er í virkri þróun. Við höfum og munum " +
      "ávallt forgangsraða einfaldleika, virkni og áreiðanleika.",
  },

  "Upload images": { is: "Hlaða upp myndum" },
  "IMAGES1": {
    en: "Employees can easily upload images in the app for various use " +
      "cases. For example: ",
    is: "Starfsmenn geta á einfaldan máta hlaðið upp myndum í appinu sem " +
      "kemur til hagsbóta í nokkrum tilfellum. Til dæmis: ",
  },
  "IMAGES2": {
    en: "To document progress to keep stakeholders informed.",
    is: "Til að skjalfesta framvindu verkefnis fyrir eigendur, stjórnendur " +
      "og verkkaupa.",
  },
  "IMAGES3": {
    en: "Proof of proper work completion, which can help if a dispute arises.",
    is: "Sönnun á réttum verkefnaskilum. Kemur sér vel ef upp kemur " +
      "ágreiningur eða ef aðrir verktakar eyðilögðu vinnu.",
  },
  "IMAGES4": {
    en: "Images of invoices to remember which project it belongs to and " +
      "ensuring it is not forgotten.",
    is: "Myndir af reikningum sem áminning hvaða verkefni þeir tilheyra og " +
      "til að tryggja að kostnaður gleymist ekki.",
  },
  "IMAGES5": {
    en: "Proof of unforeseen circumstances if more work is required.",
    is: "Skjalfesting á ófyrirséðum aðstæðum sem þarfnast frekari vinnu.",
  },

  "PERMISSIONS_TITLE": {
    en: "Employee permissions",
    is: "Starfsmanna réttindi",
  },
  "PERMISSIONS_INTRO": {
    en: "Managers can choose to grant some or all employees extra " +
      "permissions in the app:",
    is: "Stjórnendur geta veitt völdum eða öllum starfsmönnum auka " +
      "réttindi í appinu:",
  },
  "PERMISSIONS_1_1": {
    en: "Add and edit time entries",
    is: "Handskrá og breyta vinnufærslum",
  },
  "PERMISSIONS_1_2": {
    en: "If an employee forgets to clock in or out they can fix it " +
      "themselves. Also useful if workers prefer to manually enter time " +
      "entries at the end of the day or week. Greatly reduces admin work.",
    is: "Ef starfsmaður gleymir að stimpla sig inn eða út geta þeir lagað " +
      "það sjálfir. Einnig hentugt ef starfsmenn kjósa frekar að handskrá " +
      "tímaskráningar í lok dags eða viku. Minni vinna fyrir stjórnendur.",
  },
  "PERMISSIONS_2_1": {
    en: "Create projects",
    is: "Búa til verkefni",
  },
  "PERMISSIONS_2_2": {
    en: "Useful when a company has multiple small projects that only take " +
      "minutes or a few hours. With this permission an employee can create a " +
      "project directly in the app and clock in right away.",
    is: "Gagnlegt fyrir fyrirtæki með mörg minni verkefni sem taka aðeins " +
      "mínútur eða nokkrar klukkustundir. Með þessum réttindum getur " +
      "starfsmaður búið til nýtt verkefni í appinu og stimplað sig inn um " +
      "leið.",
  },

  "Screenshot from the Tímavera website dashboard": {
    is: "Skjáskot frá vefsíðu mælaborði Tímaveru",
  },

  // Screenshots alternative text
  "screenshot_alt_dashboard": {
    en: "Screenshot of the Tímavera website dashboard. List of time " +
      "entries by employees on different projects.",
    is: "Skjáskot af Tímaveru vefsíðu stjórnborðinu. Listi af vinnu " +
      "færslum starfsmanna á mismunandi verkefnum.",
  },
  "screenshot_alt_login": {
    en: "Screenshot of the login screen in the Tímavera app. Inputs for " +
      "company name, employee username and password. Red sign in button.",
    is: "Skjáskot af innskráningar skjá í Tímaveru appinu. Inntök fyrir " +
      "fyrirtækja nafn, notendanafn starfsmanns og lykilorð. Rauðir " +
      "innskráningar takki.",
  },
  "screenshot_alt_projects": {
    en: "Screenshot of list of projects in the Tímavera app. Each " +
      "project is a big red button. Projects shown are Project A, Jobsite " +
      "B, Contract C. In the upper left corner is a cog icon that shows " +
      "the settings screen. In the upper right corner is a triple equals " +
      "icon that shows employees their time entries.",
    is: "Skjáskot af lista af verkefnum í Tímaveru appinu. Hvert verkefni " +
      "er stór rauður takki. Sýnd verkefni eru Verkefni A, Samningur B, " +
      "Vinnustaður C. Í efra vinstra horninu er takki af gír sem sýnir " +
      "Stillingar skjáinn. Í efra hægra horninu er þrefalt jafnt og takki " +
      "sem sýnir starfsmönnum vinnufærslur sínar.",
  },
  "screenshot_alt_clockedIn": {
    en: "Screenshot of the Tímavera app where an employee is clocked into " +
      "Project A. The time elapsed is 03:13:37. A big red button labeled " +
      "Clock Out is in the center. A smaller Add comment button is below.",
    is: "Skjáskot úr Tímaveru appinu sem sýnir starfsmann stimplaðan inn " +
      "í Verkefni A og tíminn tifar. Liðinn tími er 03:13:37. Stór rauður " +
      "takki sem stendur á Klukk Út er í miðjunni. Smærri Bæta við ahugsemd " +
      "takki er fyrir neðan.",
  },
  "screenshot_alt_addComment": {
    en: "Screenshot of adding a comment to a time entry in the Tímavera " +
      "app. The comment being added to Project A is 'Foundation complete. " +
      "Bought materials worth $1337'.",
    is: "Skjáskot af að bæta við athugasemd við vinnufærslu í Tímaveru " +
      "appinu. Athugasemdin er 'Grunnur tilbúinn. Keypti efni fyrir 133.337 " +
      "kr'.",
  },
  "screenshot_alt_entries": {
    en: "Screenshot of the Tímavera app showing a list of 3 time entries.",
    is: "Skjáskot af Tímaveru appinu sem sýnir lista af 3 vinnufærslum.",
  },
  "screenshot_alt_entry": {
    en: "Screenshot of the Tímavera app showing a detail screen of a single " +
      "time entry. Duration: 7 hours. Start time: 08:00, Wed 4 Jan 2023. End " +
      "time: 15:00, Wed 4 Jan 2023. Project A. Comments: Foundation " +
      "complete. Bought materials for $1337.",
    is: "Skjáskot af Tímaveru appinu sem sýnir staka vinnufærslu. Lengd: 7 " +
      "klst. Upphaf: 08:00, miðvikudagur 4. janúar 2023. Endir: 15:00, " +
      "miðvikudagur 4. janúar 2023. Verkefni A. Athugasemdir: Grunnur " +
      "tilbúinn. Keypti efni fyrir 133.337kr.",
  },
};

const t = key => translate(key, translations);

const dropShadow = {
  filter: "drop-shadow(5px 5px 10px #2222227F)",
};

/** A more in-depth description of what Tímavera is in text. */
export default function Description(props) {
  let dashboardScreenshot = enDash;
  let appScreenshotLogin = englishLogin;
  let appScreenshotProjects = englishProjects;
  let appScreenshotClockedIn = englishClockedIn;
  let appScreenshotAddComment = englishAddComment;
  let appScreenshotEntries = englishEntries;
  let appScreenshotEntry = englishEntry;

  if (lang === "is") {
    dashboardScreenshot = isDash;
    appScreenshotLogin = icelandicLogin;
    appScreenshotProjects = icelandicProjects;
    appScreenshotClockedIn = icelandicClockedIn;
    appScreenshotAddComment = icelandicAddComment;
    appScreenshotEntries = icelandicEntries;
    appScreenshotEntry = icelandicEntry;
  }

  return (
    <section id="about" className="bg-dark">
      <Grid className="container">
        <Row>
          <Col className="text-center">
            <h2>{t("About Tímavera")}</h2>
            <hr className="colored" />
          </Col>
        </Row>

        {/* First text description */}
        <Row className="text-center">
          <Col xs={12} md={8} mdOffset={2}>
            <br />
            <p>{t("ABOUT_PARAGRAPH_1")}</p>
          </Col>
        </Row>

        <Row>
          <Col
            xs={10}
            xsOffset={1}
            sm={8}
            smOffset={2}
            md={6}
            mdOffset={3}
            lg={4}
            lgOffset={4}
          >
            <p
              className="text-center"
              style={{ marginBottom: "1.5em", marginTop: "1.5em" }}
            >
              {t("How it works")}:
            </p>
            <ol>
              <li style={{ marginBottom: "1.5em" }}>
                {t("howItWorks1-part1")}
                <a href={APP_STORE_HREF}>iPhone</a>
                {t(" and ")}
                <a href={PLAY_STORE_HREF}>Android</a>.
              </li>
              <li style={{ marginBottom: "1.5em" }}>{t("howItWorks2")}</li>
              <li style={{ marginBottom: "1.5em" }}>{t("howItWorks3")}</li>
              <li style={{ marginBottom: "1.5em" }}>{t("howItWorks4")}</li>
              <li style={{ marginBottom: "1.5em" }}>{t("howItWorks5")}</li>
            </ol>
          </Col>
        </Row>

        <Spacer />

        <Row>
          <Col className="text-center">
            <h2>{t("The app")}</h2>
          </Col>
        </Row>

        <Row className="text-center">
          <Col xs={12} md={8} mdOffset={2}>
            <p className="text-center">{t("screenshots-from-the-app")}</p>
          </Col>
        </Row>

        {/* First set of app screenshots */}
        <Row>
          <Col md={4} mdOffset={2} sm={6} smOffset={0} xs={8} xsOffset={2}>
            <Image
              src={appScreenshotLogin}
              responsive
              alt={t("screenshot_alt_login")}
              style={dropShadow}
              loading="lazy"
            />
          </Col>
          <Col md={4} sm={6} smOffset={0} xs={8} xsOffset={2}>
            <Image
              src={appScreenshotProjects}
              responsive
              alt={t("screenshot_alt_projects")}
              style={dropShadow}
              loading="lazy"
            />
          </Col>
        </Row>

        {/* Second set of app screenshots */}
        <Row style={{ marginTop: "2em", marginBottom: "2em" }}>
          <Col md={4} mdOffset={2} sm={6} smOffset={0} xs={8} xsOffset={2}>
            <Image
              src={appScreenshotClockedIn}
              responsive
              alt={t("screenshot_alt_clockedIn")}
              style={dropShadow}
              loading="lazy"
            />
          </Col>
          <Col md={4} sm={6} smOffset={0} xs={8} xsOffset={2}>
            <Image
              src={appScreenshotAddComment}
              responsive
              alt={t("screenshot_alt_addComment")}
              style={dropShadow}
              loading="lazy"
            />
          </Col>
        </Row>

        {/* Third set of app screenshots */}
        <Row style={{ marginTop: "2em", marginBottom: "2em" }}>
          <Col md={4} mdOffset={2} sm={6} smOffset={0} xs={8} xsOffset={2}>
            <Image
              src={appScreenshotEntries}
              responsive
              alt={t("screenshot_alt_entries")}
              style={dropShadow}
              loading="lazy"
            />
          </Col>
          <Col md={4} sm={6} smOffset={0} xs={8} xsOffset={2}>
            <Image
              src={appScreenshotEntry}
              responsive
              alt={t("screenshot_alt_entry")}
              style={dropShadow}
              loading="lazy"
            />
          </Col>
        </Row>

        {/* Second text description */}
        <Row className="text-justify">
          <Col xs={12} md={8} mdOffset={2}>
            <p>{t("ABOUT_INTRO")}</p>
            <ul>
              <li>{t("ABOUT_PROBLEM_1")}</li>
              <li>{t("ABOUT_PROBLEM_2")}</li>
              <li>{t("ABOUT_PROBLEM_3")}</li>
              <li>{t("ABOUT_PROBLEM_4")}</li>
            </ul>
            <p>
              <em>{t("ABOUT_PARAGRAPH_5")}</em>
            </p>
          </Col>
        </Row>

        {/* Screenshot of the web dashboard */}
        <Row style={{ marginTop: "2em" }}>
          <Col xs={12}>
            <Image
              src={dashboardScreenshot}
              responsive
              alt={t("screenshot_alt_dashboard")}
              loading="lazy"
              style={{
                marginBottom: "2em",
                ...dropShadow,
              }}
            />
            <p className="text-center">
              {t("Screenshot from the Tímavera website dashboard")}
            </p>
          </Col>
        </Row>

        {/* Images */}
        <Row style={{ marginTop: "2em" }}>
          <Col xs={12} md={8} mdOffset={2}>
            <h2 className="text-center">{t("Upload images")}</h2>
            <hr className="colored" />
            <p>{t("IMAGES1")}</p>
            <ul>
              <li>{t("IMAGES2")}</li>
              <li>{t("IMAGES3")}</li>
              <li>{t("IMAGES4")}</li>
              <li>{t("IMAGES5")}</li>
            </ul>
          </Col>
        </Row>

        {/* Images */}
        <Row style={{ marginTop: "2em" }}>
          <Col xs={12} md={8} mdOffset={2}>
            <h2 className="text-center">{t("PERMISSIONS_TITLE")}</h2>
            <hr className="colored" />

            <p>{t("PERMISSIONS_INTRO")}</p>
            <ul>
              <li>
                <strong>{t("PERMISSIONS_1_1")}</strong>. {t("PERMISSIONS_1_2")}
              </li>
              <li>
                <strong>{t("PERMISSIONS_2_1")}</strong>. {t("PERMISSIONS_2_2")}
              </li>
            </ul>
          </Col>
        </Row>
      </Grid>
    </section>
  );
}
