import React, { useState, useEffect } from "react";
import {
  Checkbox,
  ControlLabel,
  Button,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
} from "react-bootstrap";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import moment from "moment";

import { CenteredLoadingSpinner } from "../LoadingSpinner";
import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Employee": { is: "Starfsmaður" },
  "Project": { is: "Verk" },
  "days selected": { is: "dagar valdir" },
  "Select date(s)": { is: "Veldu dag/a" },
  "The day after": { is: "Deginum eftir" },
  "Select at least one day": { is: "Veldu að minnsta kosti 1 dag" },
  "Out time has to be after In time": { is: "Út tími verður að vera á eftir Inn tíma" },

  "Add time entry": { is: "Búa til vinnu færslu" },
  "Edit time entry": { is: "Breyta vinnu færslu" },
  "In Time": { is: "Inn Tími" },
  "Out Time": { is: "Út Tími" },
  "Edit entry": { is: "Breyta færslu" },
  "Delete entry": { is: "Eyða færslu" },
  "Close window": { is: "Loka glugga" },
};

const t = key => translate(key, translations);

const dateToMoment = date => moment([date.year, date.month - 1, date.day]);
const dateTimeToMoment = (date, time) =>
  moment(`${date.year}-${date.month}-${date.day} ${time}`, "YYYY-MM-DD HH:mm");

const now = moment();

// A style that imitates well enough our current Bootstrap theme for a few
// custom <input> components. It is far from ideal to hardcode it like this.
// It is done because the "form-control" class that would normally be used
// clashed with other styles (too far-reaching). This is a bare-bone subset.
export const inputStyle = {
  height: "34px",
  padding: "6px 12px",
  fontSize: "14px",
  lineHeight: "1.42857143",
  color: "#555",
  border: "1px solid #ccc",
  backgroundColor: "#fff",
};

const ValidDatesError = ({ isValid }) => {
  if (isValid) return null;
  return (
    <HelpBlock style={{ color: "red", marginBottom: "1em" }}>
      {t("Select at least one day")}
    </HelpBlock>
  );
};

const ValidTimeError = ({ isValid }) => {
  if (isValid) return null;
  return (
    <HelpBlock style={{ color: "red", marginBottom: "1em" }}>
      {t("Out time has to be after In time")}
    </HelpBlock>
  );
};

function CreateWorkLog(props) {
  const { show, employees, projects, onCloseModal, onSubmit } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState(false);
  const [projectId, setProjectId] = useState(false);
  const [startTime, setStartTime] = useState(now.format("HH:mm"));
  const [endTime, setEndTime] = useState(now.format("HH:mm"));
  const [outTimeTheDayAfter, setOutTimeTheDayAfter] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    if (isLoading) return;

    // Map props to state to represent the default values
    if (employees) {
      setEmployeeId(employees?.[0]?.id ?? false);
    }
    // Map props to state to represent the default values
    if (projects) {
      setProjectId(projects?.[0]?.id ?? false);
    }
    // Each time we open the modal we should use the current time for suggestion
    // We do this here rather than when we close the modal as a user can close
    // the moodal at 15:00 and open then 5 minutes later but the suggestion
    // would still be 15:00 rather than 15:05
    if (show) {
      const now = moment();
      setStartTime(now.format("HH:mm"));
      setEndTime(now.format("HH:mm"));
      setSelectedDates([]);
    }
  }, [employees, projects, show, isLoading]);

  if (!employeeId || !projectId) return <div />;

  const handleEmployeeChange = e => setEmployeeId(parseInt(e.target.value, 10));
  const handleProjectChange = e => setProjectId(parseInt(e.target.value, 10));

  const closeModal = () => {
    // Reset values
    setEmployeeId(employees[0].id);
    setProjectId(projects[0].id);
    onCloseModal();
  };

  const validDates = () => selectedDates.length !== 0;

  const validTime = () => {
    if (outTimeTheDayAfter) return true;

    const commonDate = "2000-01-01";
    const start = moment(commonDate + " " + startTime);
    const end = moment(commonDate + " " + endTime);
    return start.isBefore(moment(end));
  };

  const getDatePickerPlaceholder = () => {
    if (selectedDates.length === 0) return t("Select date(s)");
    if (selectedDates.length === 1) {
      const date = selectedDates[0];
      return dateToMoment(date).format("ddd D. MMM");
    }
    return "" + selectedDates.length + " " + t("days selected");
  };

  const submit = e => {
    e.preventDefault();
    setIsLoading(true);

    // Collect all dates into a list of data objects
    const listOfData = selectedDates.map(date => {
      const start = dateTimeToMoment(date, startTime);
      const end = dateTimeToMoment(date, endTime);
      if (outTimeTheDayAfter) {
        end.add(1, "days");
      }
      return {
        start_time: start.toISOString(),
        end_time: end.toISOString(),
        employee_id: employeeId,
        project_id: projectId,
      };
    });

    // We expect the "onSubmit" callback to return a promise of all worklogs being created.
    onSubmit(listOfData).then(() => {
      setIsLoading(false);
      closeModal();
    });
  };

  // TODO: Move this under reusable utility
  const renderCustomCalendarInput = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      disabled={isLoading}
      placeholder={t("Select date(s)")}
      value={getDatePickerPlaceholder()}
      style={{
        ...inputStyle,
        // Make it grey when it is disabled.
        backgroundColor: isLoading ? "#eee" : "#fff",
      }}
      // a styling class (just as an example if we want to customize later)
      className="my-custom-input-class"
    />
  );

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Add time entry")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={submit}>
        <Modal.Body>
          <FormGroup controlId="formCreateWorkLogControl1">
            <ControlLabel>{t("Employee")}</ControlLabel>
            <FormControl
              disabled={isLoading}
              componentClass="select"
              value={employeeId}
              onChange={handleEmployeeChange}
            >
              {employees.map((item, i) => {
                return (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>

          <FormGroup controlId="formCreateWorkLogControl2">
            <ControlLabel>{t("Project")}</ControlLabel>
            <FormControl
              disabled={isLoading}
              componentClass="select"
              value={projectId}
              onChange={handleProjectChange}
            >
              {projects.map((item, i) => {
                return (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>

          <FormGroup controlId="formCreateWorkLogControl3">
            <ControlLabel>{t("Select date(s)")}</ControlLabel>
            <br />
            <DatePicker
              disabled={isLoading}
              value={selectedDates}
              onChange={setSelectedDates}
              renderInput={renderCustomCalendarInput}
              shouldHighlightWeekends
            />
          </FormGroup>

          <FormGroup controlId="formCreateWorkLogControl4">
            <ControlLabel>{t("In Time")}</ControlLabel>
            <br />
            <input
              disabled={isLoading}
              type="time"
              value={startTime}
              onChange={e => setStartTime(e.target.value)}
              style={inputStyle}
            />
          </FormGroup>

          <FormGroup controlId="formCreateWorkLogControl5">
            <ControlLabel>{t("Out Time")}</ControlLabel>
            <br />
            <input
              disabled={isLoading}
              type="time"
              value={endTime}
              onChange={e => setEndTime(e.target.value)}
              style={inputStyle}
            />
            <Checkbox
              disabled={isLoading}
              checked={outTimeTheDayAfter}
              onChange={() => setOutTimeTheDayAfter(!outTimeTheDayAfter)}
              style={{
                display: "inline-block",
                marginLeft: "1em",
              }}
            >
              {t("The day after")}
            </Checkbox>
          </FormGroup>

          <ValidDatesError isValid={validDates()} />
          <ValidTimeError isValid={validTime()} />
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? <CenteredLoadingSpinner /> : null}
          <Button
            type="submit"
            bsStyle="success"
            block={true}
            disabled={!validDates() || !validTime() || isLoading}
            onClick={submit}
          >
            {t("Add time entry")}
          </Button>
          <Button block={true} onClick={closeModal}>
            {t("Close window")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateWorkLog;
