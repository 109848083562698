import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Checkbox,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  HelpBlock,
} from "react-bootstrap/lib";

import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import moment from "moment";

import { translate } from "../../utils/Translations";
import { inputStyle } from "./CreateWorkLog";

// prettier-ignore
const translations = {
  "Select Out time to close entry": { is: "Veljið Út tíma til að loka færslu" },
  "Edit entry": { is: "Breyta færslu" },
  "Are you certain?": { is: "Ertu alveg viss?" },
  "TimeEntryDeletionExplanation": {
    en: "If you delete the time entry it can " +
        "not be recovered. All comments and locations associated with the " +
        "entry will be deleted as well.",
    is: "Ef þú eyðir færslunni er engin leið " +
        "til að fá hana aftur. Allar athugasemdir og staðsetningar sem að " +
        "tengjast færslunni glatast.",
  },
  "Yes I want to delete the entry": { is: "Já ég vil eyða færslunni" },
  "Project": { is: "Verkefni"},
  "Date": { is: "Dagsetning" },
  "Cancel": { is: "Hætta við" },
  "In Time": { is: "Inn Tími" },
  "Out Time": { is: "Út Tími" },
  "The day after" : { is: "Deginum eftir" },
  "No changes have been made": { is: "Engar breytingar hafa verið gerðar" },
  "Out time has to be after In time": { is: "Út tími verður að vera á eftir Inn tíma" },
  "Delete entry": { is: "Eyða færslu" },
  "Close window": { is: "Loka glugga" },
};

const t = key => translate(key, translations);

const dateToMoment = date => moment([date.year, date.month - 1, date.day]);
const dateTimeToMoment = (date, time) =>
  moment(`${date.year}-${date.month}-${date.day} ${time}`, "YYYY-MM-DD HH:mm");
const momentToDate = momentDate => {
  return {
    year: momentDate.year(),
    month: momentDate.month() + 1,
    day: momentDate.date(),
  };
};

function ChangeWorkLog({
  show,
  workLog,
  projects,
  onCloseModal,
  onSubmit,
  onDelete,
}) {
  const [projectId, setProjectId] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);
  const [startTime, setStartTime] = useState(false);
  const [endTime, setEndTime] = useState(false);
  const [outTimeTheDayAfter, setOutTimeTheDayAfter] = useState(false);
  const [showVerifyDelete, setShowVerifyDelete] = useState(false);

  useEffect(() => {
    // Map props to state to represent the default values
    if (workLog) {
      setProjectId(workLog.project.id);
      setSelectedDate(momentToDate(moment(workLog.start_time)));
      setStartTime(moment(workLog.start_time).format("HH:mm"));
      if (workLog.end_time) {
        setEndTime(moment(workLog.end_time).format("HH:mm"));
        setOutTimeTheDayAfter(
          !moment(workLog.end_time).isSame(moment(workLog.start_time), "day")
        );
      } else {
        setEndTime(false);
        setOutTimeTheDayAfter(false);
      }
    }
  }, [workLog]);

  // This is very important, we only want to render once we know that we have
  // received the workLog prop and we have mapped workLogs values as the
  // default state! We know that all workLogs have a startTime
  if (!workLog || !startTime) return <div />;

  const handleProjectChange = e => setProjectId(parseInt(e.target.value, 10));
  const handleStartTimeChange = e => {
    // Prevent any deletion
    if (e.target.value.length < startTime.length) return;
    setStartTime(e.target.value);
  };
  const handleEndTimeChange = e => {
    // Prevent any deletion
    if (e.target.value.length < endTime.length) return;
    setEndTime(e.target.value);
  };

  const closeModal = () => {
    setProjectId(false);
    setSelectedDate(false);
    setStartTime(false);
    setEndTime(false);
    setShowVerifyDelete(false);
    onCloseModal();
  };

  const projectChanged = projectId !== workLog.project.id;
  const startTimeChanged = !dateTimeToMoment(selectedDate, startTime).isSame(
    moment(workLog.start_time, "YYYY-MM-DD HH:mm")
  );
  const endTimeChanged = () => {
    if (!endTime) return false;
    if (!outTimeTheDayAfter) {
      return !dateTimeToMoment(selectedDate, endTime).isSame(
        moment(workLog.end_time, "YYYY-MM-DD HH:mm")
      );
    }
    return !dateTimeToMoment(selectedDate, endTime)
      .add(1, "day")
      .isSame(moment(workLog.end_time, "YYYY-MM-DD HH:mm"));
  };

  const changesMade = () => {
    const hasAnythingChanged =
      projectChanged || startTimeChanged || endTimeChanged();
    return hasAnythingChanged;
  };

  const validDates = () => {
    // If the worklog is open and we aren't closing it, we don't need to
    // validate whether the start time is before end time
    if (!endTime) return true;
    if (outTimeTheDayAfter) return true;
    const start = dateTimeToMoment(selectedDate, startTime);
    const end = dateTimeToMoment(selectedDate, endTime);
    return start.isBefore(moment(end));
  };

  const isValidState = () => {
    return changesMade() && validDates() && !showVerifyDelete;
  };

  const submit = e => {
    e.preventDefault();
    let data = {
      id: workLog.id,
    };

    if (startTimeChanged) {
      data.new_start_time = dateTimeToMoment(selectedDate, startTime);
    }

    if (endTimeChanged()) {
      if (outTimeTheDayAfter) {
        data.new_end_time = dateTimeToMoment(selectedDate, endTime).add(
          1,
          "day"
        );
      } else {
        data.new_end_time = dateTimeToMoment(selectedDate, endTime);
      }
    }

    if (projectChanged) {
      data.new_project_id = projectId;
    }
    onSubmit(data);
    closeModal();
  };

  const erase = e => {
    e.preventDefault();
    onDelete(workLog.id);
    closeModal();
  };

  const showDeleteButton = () => {
    if (!onDelete) return null;
    return (
      <Button
        bsStyle="danger"
        block={true}
        onClick={() => setShowVerifyDelete(true)}
      >
        {t("Delete entry")}
      </Button>
    );
  };

  const getDatePickerPlaceholder = () => {
    if (!selectedDate) return t("Date");
    return dateToMoment(selectedDate).format("ddd D. MMM");
  };

  // TODO: Move this under reusable utility
  const renderCustomCalendarInput = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder={t("Date")}
      value={getDatePickerPlaceholder()}
      style={inputStyle}
      // a styling class (just as an example if we want to customize later)
      className="my-custom-input-class"
    />
  );

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Edit entry")}</Modal.Title>
      </Modal.Header>
      {showVerifyDelete ? (
        <div>
          <Alert bsStyle="danger">
            <h4>{t("Are you certain?")}</h4>
            <p>{t("TimeEntryDeletionExplanation")}</p>
          </Alert>
          <Modal.Footer>
            <Button bsStyle="danger" onClick={erase}>
              {t("Yes I want to delete the entry")}
            </Button>
            <Button onClick={closeModal}>{t("Cancel")}</Button>
          </Modal.Footer>
        </div>
      ) : (
        <Form onSubmit={submit}>
          <Modal.Body>
            <FormGroup controlId="formChangeWorkLogControl1">
              <ControlLabel>{t("Project")}</ControlLabel>
              <FormControl
                componentClass="select"
                value={projectId}
                onChange={handleProjectChange}
              >
                {projects.map((item, i) => {
                  return (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </FormControl>
            </FormGroup>
            <FormGroup controlId="formCreateWorkLogControl2">
              <ControlLabel>{t("Date")}</ControlLabel>
              <br />
              <DatePicker
                value={selectedDate}
                onChange={setSelectedDate}
                renderInput={renderCustomCalendarInput}
                shouldHighlightWeekends
              />
            </FormGroup>

            <FormGroup controlId="formChangeWorkLogControl3">
              <ControlLabel>{t("In Time")}</ControlLabel>
              <br />
              <input
                type="time"
                value={startTime}
                onChange={handleStartTimeChange}
                style={inputStyle}
              />
            </FormGroup>

            <FormGroup controlId="formCreateWorkLogControl4">
              <ControlLabel>{t("Out Time")}</ControlLabel>
              <br />
              {endTime ? (
                <>
                  <input
                    type="time"
                    value={endTime}
                    onChange={handleEndTimeChange}
                    style={inputStyle}
                  />
                  <Checkbox
                    checked={outTimeTheDayAfter}
                    onChange={() => setOutTimeTheDayAfter(!outTimeTheDayAfter)}
                    style={{
                      display: "inline-block",
                      marginLeft: "1em",
                    }}
                  >
                    {t("The day after")}
                  </Checkbox>
                </>
              ) : (
                <Button onClick={() => setEndTime(startTime)}>
                  {t("Select Out time to close entry")}
                </Button>
              )}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              {!changesMade() && (
                <HelpBlock style={{ color: "red" }}>
                  {t("No changes have been made")}
                </HelpBlock>
              )}
              {!validDates() && (
                <HelpBlock style={{ color: "red" }}>
                  {t("Out time has to be after In time")}
                </HelpBlock>
              )}
            </div>

            <Button
              type="submit"
              bsStyle="success"
              block={true}
              disabled={!isValidState()}
              onClick={submit}
            >
              {t("Edit entry")}
            </Button>

            {showDeleteButton()}

            <Button block={true} onClick={closeModal}>
              {t("Close window")}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
}

export default ChangeWorkLog;
