import moment from "moment";
import { isIcelandicDomain, isTimaveraIe } from "./HelperFunctions";

/**
 * Converts an ISO datetime string into an Icelandic date string.
 *
 * @param dateString Example: "2018-05-05T15:35:44.929996+00:00"
 * @returns {string} "lau 5. maí 2018"
 */
export function toIcelandicDate(dateString) {
  if (!dateString) return "";

  // As a temporary (i.e. permanent) hack, set the language locale to
  // Icelandic. We can't have this once we start offering our product in more
  // locales, but since we are only focused on Icelandic right now we will let
  // this slide.
  moment.locale("is");

  return moment(dateString).format("ddd D. MMM YYYY");
}

/**
 * Reduces an ISO datetime string into the HH:mm time it occurred at, "13:37"
 * for example. Note that no rounding is done. So the time 13:37:59 (59 seconds
 * past 13:337) would still be displayed as "13:37".
 *
 * @param dateString Example: "2018-05-05T15:35:44.929996+00:00"
 * @returns {string} "15:35"
 */
export function toHoursAndMinutes(dateString) {
  if (!dateString) return "";

  return moment(dateString).format("HH:mm");
}

/**
 * Converts an ISO datetime string into an Icelandic date time string to our
 * liking. Note that no rounding is performed on the number of seconds.
 *
 * @param dateString "2018-05-05T15:35:44.929996+00:00"
 * @returns {string} "lau 5. maí 2018 16:35"
 */
export function toIcelandicDateTimeString(dateString) {
  if (!dateString) return "";

  // As a temporary (i.e. permanent) hack, set the language locale to
  // Icelandic. We can't have this once we start offering our product in more
  // locales, but since we are only focused on Icelandic right now we will let
  // this slide.
  moment.locale("is");

  return moment(dateString).format("ddd D. MMM YYYY HH:mm");
}

/**
 * Formats an ISO date string (YYYY-MM-DD) in the appropriate date notation
 * depending on what domain we are on. This function is a bit of a hack, a
 * stepping stone towards a more proper localisation.
 *
 * Examples for 1st of December 2022:
 *   + "2022-12-01", .is: "01.12.2022"
 *   + "2022-12-01", .ie: "01-12-2022"
 *   + "2022-12-01", default: "2022-12-01"
 *
 * @param dateString {string} ISO date string, example: "2022-12-01"
 * @returns {string} Example: "01.12.2022" if on .is
 */
export function getFormattedDate(dateString) {
  if (isIcelandicDomain) return moment(dateString).format("DD.MM.YYYY");
  if (isTimaveraIe) return moment(dateString).format("DD-MM-YYYY");

  return dateString;
}
