import React from "react";
import { Button, Glyphicon } from "react-bootstrap/lib";

const passwordTokenStyle = {
  fontFamily: "monospace",
  background: "#ececec",
  verticalAlign: "middle",

  // The 12.5px is a 'good-enough' hack to get the passcode and
  // refresh button to align perfectly with each other.
  padding: "12.5px 18px",
};

/**
 * Displays a monospaced password token with a grey background and next to it
 * a refresh button that generates a new password token.
 */
function PasswordToken(props) {
  const { token, onRefreshToken } = props;

  if (!onRefreshToken) return <span style={passwordTokenStyle}>{token}</span>;

  return (
    <div>
      <span style={passwordTokenStyle}>{token}</span>
      <Button
        bsStyle="default"
        bsSize="small"
        onClick={onRefreshToken}
        style={{ marginLeft: "1em" }}
      >
        <Glyphicon glyph="refresh" />
      </Button>
    </div>
  );
}

export default PasswordToken;
