import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form, FormControl, FormGroup } from "react-bootstrap";
import { translate } from "../../utils/Translations";
import { cancelBilling } from "../../actions";

const translations = {
  "Cancel subscription": { is: "Hætta í áskrift" },
  "This means you can no longer use Tímavera": {
    is: "Þetta þýðir að þú munt ekki geta haldið áfram að nota Tímaveru",
  },
  "Are you sure you want to cancel your subscription?": {
    is: "Ertu viss um að þú viljir hætta í áskrift?",
  },
  "Yes I am sure I want to cancel my subscription": {
    is: "Já ég er viss um að ég vilji hætta í áskrift",
  },
  "No I want to continue using Tímavera": {
    is: "Nei ég vil halda áfram að nota Tímaveru",
  },
  "Sorry to see you go": { is: "Leitt að sjá þig fara" },
  "Before confirming, we would appriciate you leaving us a reason for your departure":
    {
      is: "Áður en þú staðfestir, þættum við vænt um að heyra hvað hefði mátt fara betur",
    },
  "(optional) reason for leaving": { is: "(valkvætt) hvað mætti betur fara" },
};

const FirstModalBody = props => {
  const { onCloseModal, onSure } = props;

  return (
    <>
      <Modal.Body>
        <p className="text-center">
          {t("Are you sure you want to cancel your subscription?")}
        </p>
        <p className="text-center">
          {t("This means you can no longer use Tímavera")}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" bsStyle="success" block={true} onClick={onSure}>
          {t("Yes I am sure I want to cancel my subscription")}
        </Button>
        <Button block={true} onClick={onCloseModal}>
          {t("No I want to continue using Tímavera")}
        </Button>
      </Modal.Footer>
    </>
  );
};

const SecondModalBody = props => {
  const { onCloseModal, dispatch } = props;
  const [reason, setReason] = useState("");

  const submit = e => {
    e.preventDefault();
    dispatch(cancelBilling(reason));
    onCloseModal();
  };

  return (
    <Form onSubmit={submit}>
      <Modal.Body>
        <p className="text-center">{t("Sorry to see you go")}</p>
        <p className="text-center">
          {t(
            "Before confirming, we would appriciate you leaving us a reason for your departure"
          )}
        </p>
        <FormGroup controlId="formCancelationReasonControl">
          <FormControl
            type="text"
            value={reason}
            onChange={e => setReason(e.target.value)}
            placeholder={t("(optional) reason for leaving")}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" bsStyle="success" block={true} onClick={submit}>
          {t("Cancel subscription")}
        </Button>
        <Button block={true} onClick={onCloseModal}>
          {t("No I want to continue using Tímavera")}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const t = key => translate(key, translations);

function CancelSubscription(props) {
  const { show, onCloseModal } = props;
  const [sure, setSure] = useState(false);

  const modalBody = sure ? (
    <SecondModalBody {...props} />
  ) : (
    <FirstModalBody {...props} onSure={() => setSure(true)} />
  );

  return (
    <Modal show={show} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Cancel subscription")}</Modal.Title>
      </Modal.Header>
      {modalBody}
    </Modal>
  );
}

export default connect(store => {
  return {};
})(CancelSubscription);
