import React from "react";
import { Modal } from "react-bootstrap";
import GoogleMap from "google-map-react";
import moment from "moment";

import { translate } from "../utils/Translations";
import { toHoursAndMinutes } from "../utils/DateFormat";

import { isTimaveraCoUk, isTimaveraIe } from "../utils/HelperFunctions";

// prettier-ignore
const translations = {
  "age": { is: "aldur" },
};

const t = key => translate(key, translations);

/**
 * In a 2023 analysis of our data 5 minutes was determined to be a good cutoff
 * point. In a histogram values above 5m (1.4%) appeared to be outliers.
 */
const ageThresholdInMins = 5;

/**
 * Convenience function that converts from how Tímavera stores latitude and
 * longitude co-ordinates to the format that the Google Maps API expects.
 * Converts { lat: "13.37", lon: "42.69" } to { lat: 13.37, lng: 42.69 }.
 * Note that the location object can contain extra attributes like accuracy
 * and created date time but this function is only concerned with the
 * co-ordinates.
 *
 * @param location {{lat: string, lon: string}}
 * @returns {{lng: number, lat: number}}
 */
const toLatLng = location => ({
  lat: parseFloat(location.lat),
  lng: parseFloat(location.lon),
});

function MyMap(props) {
  const { show, onCloseModal, locations } = props;

  const getDefaultCenter = () => {
    if (isTimaveraIe) return { lat: 53.3455758, lng: -6.2643692 }; // Dublin
    if (isTimaveraCoUk) return { lat: 51.5088966, lng: -0.1120348 }; // London

    return { lat: 64.1208874, lng: -21.8311693 }; // Reykjavík
  };

  const loadLocationMarkers = (map, maps, locations) => {
    locations.forEach((location, index) => {
      const ageInMinutes = moment
        .duration(moment(location.created).diff(moment(location.timestamp)))
        .asMinutes(); // returns a real number

      // If the time since the location was acquired is too far off. The
      // `timestamp` attribute is the date time of when the GPS location was
      // acquired. The `created` attribute is a date time of when the API
      // recorded the submission of the location. `timestamp` is null for
      // locations recorded in version 1.12 and earlier.
      const isTooOld = location.timestamp
        ? ageInMinutes > ageThresholdInMins
        : false;

      const markerOptions = {
        map: map,
        position: toLatLng(location),
      };

      let timeStamp = toHoursAndMinutes(
        // Locations created prior to v1.13 of the app did not record timestamp
        location.timestamp || location.created
      );

      const marker = new maps.Marker(markerOptions);
      const infoWindow = new maps.InfoWindow({
        content: isTooOld
          ? `${timeStamp}, ${t("age")} ${Math.round(ageInMinutes)}m`
          : timeStamp,
      });

      infoWindow.open(map, marker);

      // This displays the creation time of the
      // location when hovered over the marker.
      marker.addListener("mouseover", () => infoWindow.open(map, marker));
      marker.addListener("mouseout", () => infoWindow.close());
    });
  };

  /** Adds accuracy radii for all locations */
  const loadAccuracyRadii = (map, maps, locations) => {
    locations.forEach(location => {
      new maps.Circle({
        map, // by passing in the map here the circle gets properly added
        radius: location?.accuracy ?? 0,
        center: toLatLng(location),

        // Radius style
        strokeColor: "#0048ff",
        strokeOpacity: 0.0,
        strokeWeight: 1,
        fillColor: "#0048ff",
        fillOpacity: 0.35,
      });
    });
  };

  const adjustBoundsAndZoom = (map, maps, locations) => {
    const bounds = new maps.LatLngBounds();
    locations.forEach(location => {
      bounds.extend(toLatLng(location));
    });

    // Change the map bounds (co-ordinates of corners) to fit all locations
    map.fitBounds(bounds);

    // Zoom out by 1 level to make sure all accuracy radii and location labels
    // will fit well enough on the map. Math.min ensures that when locations
    // are really close to each other that the map does not zoom in too much.
    map.setZoom(Math.min(12, map.getZoom() - 1));
  };

  const onGoogleApiLoaded = ({ map, maps }) => {
    loadLocationMarkers(map, maps, locations);
    loadAccuracyRadii(map, maps, locations);
    adjustBoundsAndZoom(map, maps, locations);
  };

  return (
    <Modal show={show} onHide={onCloseModal} bsSize="lg">
      <GoogleMap
        style={{ height: "80vh", width: "100%", position: "relative" }}
        bootstrapURLKeys={{ key: "AIzaSyCxXDDS3BAhNrNxD_7cXRcuVw0Zsqt6DC0" }}
        onGoogleApiLoaded={onGoogleApiLoaded}
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={getDefaultCenter()}
        defaultZoom={10}
      />
    </Modal>
  );
}

export default MyMap;
