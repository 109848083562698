/**
 * Generate a 4-character passcode for employee app login. For simplicity the
 * passcodes are all in lower case, no numbers or special characters. To avoid
 * generating offensive 4 letter passcodes (slurs & curse words) we do not
 * permit usage of vowels. Without vowels you can not create real words in most
 * Latin based languages.
 *
 * Forbidden characters:
 *   + Vowels: a e i o u y (avoid possibility of creating real words)
 *   + Other: l (avoid similarity to 1 and I)
 *
 * With an alphabet size of 19 characters and 4 letters the total number of
 * possible passcodes are: 19^4 = 130,321. This is sufficiently large for our
 * use case because login credentials are composed of company name, employee
 * name and then the passcode. In addition to that we have plans to add rate
 * limiting to employee logins so brute force attacks are unlikely to succeed.
 *
 * @returns {string} Example: "uddz", "tgvr", etc
 */
export function generateLoginToken() {
  const tokenLength = 4;
  const curatedAlphabet = "bcdfghjkmnpqrstvwxz";

  let loginToken = "";

  for (let i = 0; i < tokenLength; i++) {
    let randomNumber = Math.floor(Math.random() * curatedAlphabet.length);
    loginToken += curatedAlphabet.substring(randomNumber, randomNumber + 1);
  }

  return loginToken;
}
