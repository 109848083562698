import React, { useState } from "react";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import Form from "react-bootstrap/lib/Form";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import HelpBlock from "react-bootstrap/lib/HelpBlock";
import { isValidEmail } from "../../utils/HelperFunctions";

function CreateCompany(props) {
  const { show, onSubmit, onCloseModal } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleNameChange = e => setName(e.target.value);
  const handleEmailChange = e => setEmail(e.target.value);
  const handlePasswordChange = e => setPassword(e.target.value);
  const handleConfirmPasswordChange = e => setConfirmPassword(e.target.value);

  const enableSubmit =
    name.length > 0 &&
    isValidEmail(email) &&
    password.length > 0 &&
    password === confirmPassword;
  const invalidPassword = password.length > 0 && password !== confirmPassword;

  return (
    <Modal show={show} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Bæta við fyrirtæki</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmit({ name, email, password });
        }}
      >
        <Modal.Body>
          <FormGroup controlId="formCompanyNameControl">
            <FormControl
              type="text"
              onChange={handleNameChange}
              placeholder="Nafn"
            />
          </FormGroup>
          <FormGroup controlId="formCompanyEmailControl">
            <FormControl
              type="text"
              onChange={handleEmailChange}
              placeholder="Email"
            />
          </FormGroup>
          <FormGroup controlId="formCompanyPasswordControl">
            <FormControl
              type="password"
              onChange={handlePasswordChange}
              placeholder="Lykilorð"
            />
          </FormGroup>
          <FormGroup controlId="formCompanyConfirmPasswordControl">
            <FormControl
              type="password"
              onChange={handleConfirmPasswordChange}
              placeholder="Staðfesta lykilorð"
            />
            {invalidPassword && (
              <HelpBlock style={{ color: "red" }}>
                Lykilorð og staðfest lykilorð er ekki eins
              </HelpBlock>
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!enableSubmit}
            type="submit"
            bsStyle="success"
            onClick={e => {
              e.preventDefault();
              onSubmit({ name, email, password });
            }}
          >
            Bæta við
          </Button>
          <Button onClick={onCloseModal}>Loka</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateCompany;
