import React from "react";
import { Grid, Row, Col, Image } from "react-bootstrap";

import { translateWithGlobals } from "../../utils/Translations";
import olafurPicture from "./../../images/team/olafur320x320.jpg";
import jonrhPicture from "./../../images/team/jonrh320x320.jpg";

// prettier-ignore
const translations = {
  "Co-Founder & Software Developer": { is: "Stofnandi & Forritari" },
  "Co-Founder & Master Painter": { is: "Stofnandi & Málarameistari" },

  "paragraph1": {
    en: "Tímavera ehf. was founded in May 2017 in Iceland by two software " +
      "developers and two painters.",
    is: "Tímavera ehf. var stofnað í Maí 2017 af tveimur forriturum og tveim " +
      "málurum.",
  },
  "paragraph2": {
    en: "Finnbogi and Jón Hafdal are both master painters, who have been " +
      "operating their own businesses for years. They struggled to find an " +
      "easy-to-use time tracking system that catered to their type of " +
      "business – that of small contracting companies.",
    is: "Finnbogi og Jón Hafdal eru báðir málarameistarar sem hafa rekið sín " +
      "eigin málningarfyrirtæki um árabil. Þeir áttu í erfiðleikum með að " +
      "finna einfalt tímaskráningarkerfi sem hentaði þeirra rekstri: smærri " +
      "verktaka fyrirtæki.",
  },
  "paragraph3": {
    en: "Ólafur learned of their frustration and quickly envisioned a simple " +
      "app based time tracking system. Ólafur created the original website " +
      "and recruited Jón Rúnar to build the mobile app.",
    is: "Ólafur komst á snoðir um erfiðleika þeirra og kom snögglega með " +
      "hugmynd að einföldu timaskráningar appi. Ólafur útbjó fyrstu " +
      "útgáfuna af vefsíðunni og fékk svo félaga sinn Jón Rúnar til að " +
      "smíða appið.",
  },
  "paragraph4": {
    en: "News about Tímavera spread through the community of contractors " +
      "and small business owners within Iceland. Early users found that " +
      "Tímavera helped them manage project and employee time in a simple " +
      "way that met their needs.",
    is: "Eftir það fór boltinn svo að rúlla. Verktakar og smærri fyrirtæki " +
      "í bransanum bættust smátt og smátt við þar sem þau sáu þörfum sínum " +
      "mætt í einföldu kerfi.",
  },
  "paragraph5": {
    en: "Since the beginning the company has enjoyed a modest but steady " +
      "growth. The company has always been in full ownership of its founders " +
      "with no outside investment. Profitably run and no debt. That has " +
      "enabled a healthy customer focused product development which we hope " +
      "and plan to continue for years to come.",
    is: "Frá upphafi hefur fyrirtækið vaxið jafnt og þétt. Fyrirtækið hefur " +
      "frá upphafi alltaf verið í eigu stofnenda og aldrei tekið ytri " +
      "fjárfestingu. Rekið með hagnaði og engar skuldir. Það hefur gert " +
      "fyrirtækinu kleyft að stunda heilbrigða vöruþróun í þágu " +
      "viðskiptavina sem við við vonum og áformum að halda áfram um ókomin ár.",
  }
};

const t = key => translateWithGlobals(key, translations);

const EmployeeProfile = props => {
  const isImageDefined = Boolean(props.employee.image);
  const image = (
    <Image
      src={props.employee.image}
      className="img-centered"
      style={{ filter: "opacity(0.9)" }}
      rounded
      responsive
      loading="lazy"
      alt={`${props.employee.name}`}
    />
  );

  return (
    <Col
      xs={8}
      xsOffset={2}
      sm={4}
      smOffset={0}
      md={4}
      mdOffset={0}
      style={{ marginTop: "2em" }}
    >
      {isImageDefined ? image : null}
      <div>
        <h3>{props.employee.name}</h3>
        <p>{props.employee.title}</p>
      </div>
    </Col>
  );
};

/**
 * Info about the company, pictures, names and titles of the people behind
 * Tímavera ehf.
 */
export default function AboutTheCompany(props) {
  const timaveraEmployees = {
    olafur: {
      name: "Ólafur Magnússon",
      title: t("Co-Founder & Software Developer"),
      image: olafurPicture,
    },
    jonsi: {
      name: "Jón Rúnar Helgason",
      title: t("Co-Founder & Software Developer"),
      image: jonrhPicture,
    },
    finnbogi: {
      name: "Finnbogi Þorsteinsson",
      title: t("Co-Founder & Master Painter"),
    },
    jonhafdal: {
      name: "Jón Hafdal Sigurðarson",
      title: t("Co-Founder & Master Painter"),
    },
  };

  return (
    <section id="company" className="bg-dark">
      <Grid className="container text-center">
        <Row>
          <Col>
            <h2>{t("About the company")}</h2>
            <hr className="colored" />
          </Col>

          <Col
            xs={10}
            xsOffset={1}
            sm={10}
            smOffset={1}
            md={8}
            mdOffset={2}
            lg={8}
            className="text-center"
          >
            <div className="text-justify">
              <p>{t("paragraph1")}</p>
            </div>
            <div className="text-justify">
              <p>{t("paragraph2")}</p>
            </div>
            <div className="text-justify">
              <p>{t("paragraph3")}</p>
            </div>
            <div className="text-justify">
              <p>{t("paragraph4")}</p>
            </div>
            <div className="text-justify">
              <p>{t("paragraph5")}</p>
            </div>

            <Row>
              <Col sm={2} md={2} />
              <EmployeeProfile employee={timaveraEmployees.olafur} />
              <EmployeeProfile employee={timaveraEmployees.jonsi} />
            </Row>

            <Row>
              <Col sm={2} md={2} />
              <EmployeeProfile employee={timaveraEmployees.finnbogi} />
              <EmployeeProfile employee={timaveraEmployees.jonhafdal} />
            </Row>
          </Col>
        </Row>
      </Grid>
    </section>
  );
}
