import { statusCheck } from "../api";
import { translate } from "./Translations";
import { RollbarError, RollbarWarning } from "./Rollbar";

// prettier-ignore
const translations = {
  "noInternetOrApiDown": {
    en: "Network connectivity issue. Could not complete the network request.",
    is: "Netsambands örðuleikar. Gat ekki klárað fyrirspurnina.",
  },
  "noInternet": {
    en: "Internet connectivity may be down.",
    is: "Netsamband gæti verið niðri.",
  },
  "timaveraAPIDown": {
    en: "Could not connect to Tímavera servers. We have been notified.",
    is: "Gat ekki tengst vefþjóni Tímaveru. Okkur hefur verið gert viðvart.",
  },
  "timaveraAPITimeout": {
    en: "The Tímavera servers did not respond. Please try again.",
    is: "Vefþjónar Tímaveru svöruðu ekki. Vinsamlegast reyndu aftur.",
  },
  "timaveraAPIError": {
    en: "The Tímavera servers had a hiccup. Sorry about that! We have been " +
      "notified and will work on a fix as soon as possible.",
    is: "Vefþjónar Tímaveru lentu í smá klandri. Afsakið! Okkur hefur verið " +
      "gert viðvart og við vinnum að leiðréttingu eins fljótt og hægt er.",
  },
  "timaveraAPIRateLimit": {
    en: "Too many requests in a short amount of time. Please retry after 1 " +
      "minute. This helps us to fight spam. Sorry about that!",
    is: "Of margar fyrirspurnir á stuttum tíma. Vinsamlegast reyndu aftur " +
      "eftir 1 mínútu. Þetta hjálpar okkur að verjast gegn árásum. Innilega " +
      "afsakið!",
  },
  "unknownError": {
    en: "We ran into an unknown error. Sorry about that! We have been " +
      "notified and will investigate and fix the issue as soon as possible.",
    is: "Upp kom óþekkt villa. Afsakið! Okkur hefur verið gert viðvart. " +
      "Við munum skoða og laga villuna eins fljótt og hægt er.",
  }
};

const t = key => translate(key, translations);

/**
 * A convenience function that accepts an Axios error object and returns
 * another object with some of the more important attributes extracted from
 * it. The also does some existence checks so if something is not there we
 * do not get more errors.
 *
 * @param error Axios error object
 * @returns {{response: (*|string), message: (*|string), status: (*|string)}}
 */
const unpackErrorObject = error => ({
  message: error?.message || `No error message`,
  status: error?.response?.status || `No status code`,
  response: error?.response?.data || `No response`,
});

/**
 * Handle error scenarios that can happen when making network requests.
 *
 * It is important to note that at the end of this function is a catch-all else
 * clause. This means that this function should be called last in any error
 * handling code.
 *
 * Note: at the time of writing we report every scenario to Rollbar either
 * through an error or a warning. This is likely excessive in the long term but
 * may be informative as we get more comfortable with how this logic behaves.
 * The plan is then to scale back on the reporting. For example there is not
 * much we can do about a client loosing internet connectivity.
 *
 * @param error Axios error object returned from a failed Promise
 * @param codeLocation {string} what part of our code did it come from
 * @param onError A callback function when an error is encountered. It passes
 *   in a single string, a localised client friendly error message.
 */
export function handleNetworkErrors(error, codeLocation, onError) {
  const { noInternet, noInternetOrAPIDown, apiTimeout, apiError, rateLimit } =
    typeOfErrors(error);
  const reportError = title => reportRollbarError(error, title, codeLocation);
  const reportWarn = title => reportRollbarWarning(error, title, codeLocation);

  if (noInternet) {
    onError(t("noInternet"));
    reportWarn("No internet connectivity, navigator.onLine is false");
  } else if (noInternetOrAPIDown) {
    // Give immediate feedback of what the issue may be. Required
    // because the next step will take few seconds to resolve.
    onError(t("noInternetOrApiDown"));

    // When we have this network error it can mean the client does not have
    // internet connectivity or the API is down. I have unfortunately not been
    // able to find a better pattern that distinguishes between the two types
    // of errors. That is, both types of errors have the same error message
    // "Network Error". So for now, the best thing I can think of is to do a
    // status check to a website that we know is reliably always available.
    statusCheck()
      .then(response => {
        // Got a successful response from the status check which means the
        // client has internet connectivity, which means our API must be down.
        onError(t("timaveraAPIDown"));
        reportWarn("Tímavera API down or unreachable");
      })
      .catch(error => {
        // The status check failed which means that the client has no internet
        // connectivity or the status check website we use is also down.
        onError(t("noInternet"));
        reportWarn("No internet connectivity");
      });
  } else if (apiTimeout) {
    onError(t("timaveraAPITimeout"));
    reportWarn("Tímavera API timeout");
  } else if (apiError) {
    onError(t("timaveraAPIError"));
    reportError("Tímavera API error");
  } else if (rateLimit) {
    onError(t("timaveraAPIRateLimit"));
    reportWarn("Tímavera API rate limit");
  } else {
    onError(t("unknownError"));
    reportError("Unknown error");
  }
}

/**
 * Report an error to our error tracking service Rollbar. This function would
 * be used for handled error scenarios, but we wish to have the results of the
 * error logged in Rollbar and therefore notified on Slack.
 *
 * @param error Axios error object
 * @param errorTitle {string} our best description of the error
 * @param codeLocation {string} approximate source area in our codebase
 */
function reportRollbarError(error, errorTitle, codeLocation = "") {
  const { message, status, response } = unpackErrorObject(error);

  RollbarError(errorTitle, {
    error: sanitizeError(error),
    codeLocation: codeLocation,
    message: message,
    status: status,
    response: response,
  });
}

/**
 * Report a warning to our error tracking service Rollbar. This function would
 * be used for handled error scenarios, but we wish to have the results of the
 * error/warning logged in Rollbar and therefore notified on Slack.
 *
 * @param error Axios error object
 * @param warningTitle {string} our best description of the error
 * @param codeLocation {string} approximate source area in our codebase
 */
function reportRollbarWarning(error, warningTitle, codeLocation = "") {
  const { message, status, response } = unpackErrorObject(error);

  RollbarWarning(warningTitle, {
    error: sanitizeError(error),
    codeLocation: codeLocation,
    message: message,
    status: status,
    response: response,
  });
}

/**
 * Converts error.config.data in an Axios error from string to object if it is
 * indeed an object. If it is not a string representation of an object the
 * string is left untouched. This is done so that Rollbar's default scrubbing
 * behavior can take effect. That is, masking fields like "password". If the
 * `data` value is a string representation of an object Rollbar does not mask
 * or scrub sensitive information from the string.
 *
 * Example value for error.config.data in a failed login request:
 *
 *   "{\"username\":\"test\",\"password\":\"test\"}"
 *
 * After the conversion data becomes the following object:
 *
 *   {
 *     "username": "test",
 *     "password": "test",
 *   }
 *
 * Before Rollbar sends data it masks as follows:
 *
 *   {
 *     "username": "test",
 *     "password": "****",
 *   }
 *
 * @param error Axios error object
 */
function sanitizeError(error) {
  if (typeof error?.config?.data === "string") {
    // A crude way to deep copy the error object. It is an acceptable approach
    // because attributes that can not be serialised to strings (such as
    // functions) can not be sent to Rollbar anyway.
    let newError = JSON.parse(JSON.stringify(error));

    try {
      const dataAsObject = JSON.parse(error.config.data);

      // Replace with object if `data` was a string representation of an object
      if (typeof dataAsObject === "object" && dataAsObject !== null) {
        newError.config.data = dataAsObject;
      }
    } catch (error) {
      // If parsing fails, do nothing and leave the original value.
    }

    return newError;
  } else {
    return error;
  }
}

/**
 * Returns an object where all the properties are booleans indicating if a
 * given error type or scenario applies to the given Axios error. The consumer
 * of this function would extract the properties as needed. Example usage:
 *
 *   const { apiError, apiTimeout } = typeOfErrors(error);
 *   const { companyAlreadyExists } = typeOfErrors(error);
 *
 * @param error Axios error object
 * @returns {{
 *    apiTimeout: boolean,
 *    noInternet: boolean,
 *    noInternetOrAPIDown: boolean,
 *    apiError: boolean,
 *    companyAlreadyExists: boolean
 * }}
 */
export function typeOfErrors(error) {
  const { message, status, response } = unpackErrorObject(error);

  return {
    /* ======================= Network or API errors ======================= */
    // When navigator.onLine is false we are guaranteed the client does not
    // have any connectivity. Example scenarios are wifi is off, lan cable is
    // disconnected. When onLine is true we likely have connectivity, but we
    // may not. False positives exist. For example when blocked by a firewall
    // or no mobile credits. We can therefore not rely on onLine === true.
    noInternet: navigator?.onLine === false,
    noInternetOrAPIDown: message === `Network Error`,
    apiTimeout: message.startsWith(`timeout`),
    apiError: status >= 500 && status < 600,
    rateLimit: status === 429,

    /* ======================== API specific errors ======================== */
    companyAlreadyExists:
      status === 409 && response === "A company with that name already exists",

    projectAlreadyExists:
      status === 409 && response?.error === "Project name already exists",

    employeeAlreadyExists:
      status === 409 && response?.error === "Employee already exists",

    // Company login
    wrongCompanyName: status === 401 && response === "Wrong company name",
    wrongCompanyPassword:
      status === 401 && response === "Wrong company password",
    companyNotFound: status === 404 && response === "Company not found",
    moreThanOneCompanyFoundForEmail:
      status === 422 && response === "More than one company found for email",

    // Employee login
    wrongEmployeeName: status === 401 && response === "Wrong employee name",
    wrongEmployeePass: status === 401 && response === "Wrong employee password",
    employeeDisabled:
      status === 401 && response === "Employee has been disabled",
  };
}
