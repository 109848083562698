import React, { useState } from "react";
import {
  Button,
  Checkbox,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
} from "react-bootstrap";

import { generateLoginToken } from "../../utils/LoginToken";
import { PasswordToken, SendSms } from "../";
import {
  manageWorklogs,
  createProjects,
  defaultEmployeePermissions,
} from "../../utils/Constants";
import { translateWithGlobals } from "../../utils/Translations";
import { isValidPhoneNumberForm } from "../SendSms";

// prettier-ignore
const translations = {
  "Create employee": { is: "Búa til starfsmann" },
  "Permissions": { is: "Réttindi" },
  "Password": { is: "Lykilorð" },
  "Add employee": { is: "Bæta við starfsmanni" },
  "Add employee & send SMS": { is: "Bæta við starfsmanni & senda SMS"},

  "Employee can manually add and edit time entries in the app": {
    is: "Starfsmaður má handskrá og breyta vinnufærslum í appinu",
  },
  "Employee can manually add projects in the app": { is: "Starfsmaður má búa til verkefni í appinu" },
};

const t = key => translateWithGlobals(key, translations);

function CreateEmployee(props) {
  const { show, onSubmit, onCloseModal } = props;
  const [name, setName] = useState("");
  const [token, setToken] = useState(generateLoginToken());
  const [needs, setNeeds] = useState(defaultEmployeePermissions);
  const [phoneNumber, setPhoneNumber] = useState("");

  const nameIsSet = name.length > 0;
  const phoneEmpty = phoneNumber === "";
  const phoneValid = isValidPhoneNumberForm(phoneNumber);
  const enableSubmit = nameIsSet && (phoneEmpty || phoneValid);

  const hasManageWorklogPermission = needs.includes(manageWorklogs);
  const needsWithoutManageWrklg = needs.filter(need => need !== manageWorklogs);

  const hasCreateProjectsPermission = needs.includes(createProjects);
  const needsWithoutCreateProjects = needs.filter(
    need => need !== createProjects
  );

  let submitLabel = t("Add employee");
  if (!phoneEmpty) submitLabel = t("Add employee & send SMS");

  const handleNameChange = e => setName(e.target.value);
  const refreshToken = () => setToken(generateLoginToken());
  const toggleManageWorklogPermission = () => {
    if (needs.includes(manageWorklogs)) {
      setNeeds(needsWithoutManageWrklg);
    } else {
      setNeeds([...needs, manageWorklogs]);
    }
  };
  const toggleCreateProjectsPermission = () => {
    if (needs.includes(createProjects)) {
      setNeeds(needsWithoutCreateProjects);
    } else {
      setNeeds([...needs, createProjects]);
    }
  };

  const setDefaultState = () => {
    setName("");
    setToken(generateLoginToken());
    setNeeds(defaultEmployeePermissions);
    setPhoneNumber("");
  };

  const closeModal = () => {
    onCloseModal();
    setDefaultState();
  };

  const submit = (name, token, needs, phoneNumber) => {
    onSubmit({
      name: name,
      token: token,
      needs: needs,
      phone_number: phoneNumber,
    });

    setDefaultState();
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Create employee")}</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={e => {
          e.preventDefault();
          submit(name, token, needs, phoneNumber);
        }}
      >
        <Modal.Body>
          <FormGroup controlId="formEmployeeNameControl">
            <ControlLabel>{t("Name")}</ControlLabel>
            <FormControl
              type="text"
              onChange={handleNameChange}
              placeholder={t("Name")}
              autoFocus={true}
            />
          </FormGroup>

          <FormGroup controlId="formEmployeeTokenControl">
            <ControlLabel>{t("Password")}</ControlLabel>
            <PasswordToken token={token} onRefreshToken={refreshToken} />
          </FormGroup>

          <FormGroup controlId="formEmployeePermissionControl">
            <ControlLabel>{t("Permissions")}</ControlLabel>
            <Checkbox
              checked={hasManageWorklogPermission}
              onChange={toggleManageWorklogPermission}
            >
              {t("Employee can manually add and edit time entries in the app")}
            </Checkbox>
            <Checkbox
              checked={hasCreateProjectsPermission}
              onChange={toggleCreateProjectsPermission}
            >
              {t("Employee can manually add projects in the app")}
            </Checkbox>
          </FormGroup>

          <SendSms phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!enableSubmit}
            type="submit"
            bsStyle="success"
            block={true}
            onClick={e => {
              e.preventDefault();
              submit(name, token, needs, phoneNumber);
            }}
          >
            {submitLabel}
          </Button>
          <Button block={true} onClick={closeModal}>
            {t("Close window")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateEmployee;
