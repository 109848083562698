import React, { useEffect, useState } from "react";

import "./LoadingSpinner.css";

/** A looping rotating dark grey square. */
const LoadingSpinner = props => {
  return <div className="sk-plane" />;
};

/**
 * The loading spinner horizontally centered with a bit of top and bottom
 * margin.
 */
export const CenteredLoadingSpinner = props => {
  return (
    <div className="horizontalCenter">
      <div className="sk-plane" />
    </div>
  );
};

/**
 * A looping rotating white square taking up the entire screen. For loading
 * pages.
 */
export const FullScreenWhiteLoadingSpinner = props => {
  return (
    <div className="center">
      <div className="sk-plane" style={{ backgroundColor: "white" }} />
    </div>
  );
};

/**
 * Returns a looping "•" -> "••" -> "•••" -> "•", etc text loading spinner that
 * is horizontally centered and with a bit of top and bottom margin.
 *
 * Has the same or similar height as the rotating square spinner. The purpose
 * of this spinner is to provide an alternative to avoid jarring rendering
 * glitches in WebKit based browsers. This effects all browsers on iOS, Safari
 * and other browsers such as Orion. Hidden elements that use CSS transform
 * animations such as `rotate()` will pop to the front and may bring other
 * elements with it. This issue surfaced when using the Paddle Checkout overlay
 * iframe when collecting credit card payments. Once the checkout was complete
 * our site in the background would get a callback and kick of the square
 * loading spinner which would glitch the whole site. This text loading spinner
 * does not have any CSS animations and so not subject to this WebKit bug.
 *
 * See:
 * + https://bugs.webkit.org/show_bug.cgi?id=250919
 * + https://github.com/jonrh/webkit-bug-2023-01-20
 */
export const TextLoadingSpinner = () => {
  const [dots, setDots] = useState("•");

  const style = {
    fontSize: "28px",
    fontWeight: "900",
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(dots.length === 3 ? "•" : `${dots}•`);
    }, 600);
    return () => clearInterval(interval);
  }, [dots]);

  return (
    <div className="horizontalCenter">
      <span style={style}>{dots}</span>
    </div>
  );
};

export default LoadingSpinner;
