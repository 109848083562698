import React from "react";

import NavigationBar from "./NavigationBar";
// import NavigationFront from "../pages/frontpage/NavigationFront";
import Footer from "./Footer";

/**
 * Renders a child component with the base Tímavera header and footer
 */
export default function HeaderFooter(props) {
  return (
    <>
      <NavigationBar />
      {/*<NavigationFront />*/}
      <main>{props.children}</main>
      <Footer />
    </>
  );
}
