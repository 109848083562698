import React from "react";

/**
 * A utility component to conditionally show given children. Example:
 *
 *   <Show showIf={true}>
 *     <p>Paragraph 1</p>
 *     <p>Paragraph 2</p>
 *   </Show>
 */
export const Show = ({ showIf, children }) => (showIf ? <>{children}</> : null);

/**
 * A utility component to conditionally render alternative content depending
 * on a boolean condition. For example display a loading spinner while loading.
 *
 * Example usage:
 *
 *   <Load show={<LoadingSpinner />} iff={transactions.fetching}>
 *     <p>Content shown when fetching is complete</p>
 *   </Load>
 *
 * An alternative that flows better in JSX instead of:
 *
 *  {transactions.fetching ? (
 *    <LoadingSpinner />
 *  ) : (
 *    <p>Content shown when fetching is complete</p>
 *  )}
 *
 * @param show React component for the alternative view while loading.
 * @param iff {boolean} true if loading should show, false otherwise. Named iff
 *   because "if" is a reserved keyword in JS.
 * @param children React component to render if not loading for example.
 */
export const Load = ({ show, iff, children }) => (iff ? show : children);
