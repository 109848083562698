import React from "react";
import { Grid, Row, Col } from "react-bootstrap";

import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Other Testimonials": { is: "Aðrar Umsagnir" },

  "Started November 2017": { is: "Byrjaði Nóvember 2017" },
  "TESTIMONIALS_EYTHOR": {
    en: "Incredibly valuable to get a reliable overview at the end of " +
      "the month.",
    is: "Snilld að fá áreiðanlega yfirsýn í lok mánaðar og verkefna.",
  },

  "Feb 2020 to May 2023": { is: "Feb 2020 til Maí 2023" },
  "TESTIMONIALS_SMARI": {
    en: "It is pretty clear that I will recommend Tímavera to everyone I " +
      "know in my business... The system, not to mention your support...",
    is: "Það er nokkuð ljóst að ég mun mæla með Tímaveru til allra sem að " +
      "ég þekki í mínum bransa... Kerfið, og ekki síst fyrir viðleitina " +
      "hjá ykkur...",
  }
};

const t = key => translate(key, translations);

const Testimonial = ({ name, company, time, testimonial }) => {
  return (
    <Row style={{ marginTop: "5em" }}>
      <Col xs={10} sm={10} md={8} lg={8} xsOffset={1} smOffset={1} mdOffset={2}>
        <p className="lead">"{testimonial}"</p>
        <p>
          <strong>{name}</strong> <br />
          {company} <br />
          {time}
        </p>
      </Col>
    </Row>
  );
};

/** Testimonials from our users praising our godly good product. */
export default function TestimonialsText(props) {
  return (
    <section id="testimonials" className="testimonials bg-dark">
      <Grid>
        <Row>
          <Col className="text-center">
            <h2>{t("Other Testimonials")}</h2>
            <hr className="colored" />
          </Col>
        </Row>
        <Testimonial
          name="Eyþór Bergvinsson"
          company="EB Málun ehf."
          time={t("Started November 2017")}
          testimonial={t("TESTIMONIALS_EYTHOR")}
        />
        <Testimonial
          name="Smári Eiríksson"
          company="Selhóll byggingafélag ehf."
          time={t("Feb 2020 to May 2023")}
          testimonial={t("TESTIMONIALS_SMARI")}
        />
      </Grid>
    </section>
  );
}
