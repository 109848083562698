import axios from "axios";
import {
  isStagingTimaveraCom,
  isStagingTimaveraIs,
  isTimaveraCom,
  isTimaveraCoUk,
  isTimaveraIe,
  isTimaveraIs,
} from "../utils/HelperFunctions";

const HOSTS = {
  PROD_IS: "https://api.timavera.is",
  PROD_COM: "https://api.timavera.com",
  PROD_IE: "https://api.timavera.ie",
  PROD_UK: "https://api.timavera.co.uk",
  STAGING_IS: "https://api.staging.timavera.is",
  STAGING_COM: "https://api.staging.timavera.com",
  LOCAL: "http://localhost:5001",
};

// The default API host endpoint
let HOST = HOSTS.LOCAL;

if (isTimaveraIs) HOST = HOSTS.PROD_IS;
if (isTimaveraIe) HOST = HOSTS.PROD_IE;
if (isTimaveraCoUk) HOST = HOSTS.PROD_UK;
if (isTimaveraCom) HOST = HOSTS.PROD_COM;
if (isStagingTimaveraIs) HOST = HOSTS.STAGING_IS;
if (isStagingTimaveraCom) HOST = HOSTS.STAGING_COM;

function request(method, url, data) {
  let headers = { "Content-Type": "application/json" };
  if (data?.headers) {
    headers = { ...headers, ...data.headers };
    delete data.headers;
  }

  const config = {
    method,
    data,
    url: HOST + url,
    headers: headers,
    withCredentials: true,
  };

  return axios(config)
    .then(res => res.data)
    .catch(error => Promise.reject(error));
}

/**
 * Axios' data fetcher for SWR. https://swr.vercel.app/docs/data-fetching#axios
 *
 * @param url {string} Example: "/api/worklogs"
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetcher = url =>
  axios.get(HOST + url, { withCredentials: true }).then(res => res.data);

function get(url) {
  return request("get", url);
}

function post(url, data = {}) {
  return request("post", url, data);
}

function put(url, data) {
  return request("put", url, data);
}

function addQueryString(params) {
  let filter = "";
  let symbol = "?";
  Object.entries(params)
    .filter(([key, value]) => value)
    .forEach(([key, value]) => {
      filter += symbol + key + "=" + value;
      symbol = "&";
    });
  return filter;
}

// function patch(url, data) {
//   return request('patch', url, data);
// }

// function remove(url, data) {
//   return request('delete', url, data);
// }

/**
 * A helper function to help determine if the client is without internet
 * connectivity or if our API is unavailable. It makes a simple GET request to
 * the corresponding frontend. For example https://timavera.is.
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function statusCheck() {
  // Special check when developing locally
  if (HOST === HOST.LOCAL) {
    return axios({
      method: "get",
      url: "https://timavera.is",
      timeout: 2 * 1000, // 2s
    });
  }

  return axios({
    method: "get",
    url: HOST.replace("api.", ""),
    timeout: 2 * 1000, // 2s
  });
}

export default {
  // Admin api
  authenticateAdmin: (user, pass) =>
    post("/admin/login", { username: user, password: pass }),
  adminLoginAs: id => get("/admin/login-as/" + id),
  getAdminScripts: () => get("/admin/scripts"),
  runAdminScript: (script, data) => post("/admin/scripts/" + script, data),

  // Company api
  authenticate: (user, pass) =>
    post("/api/login", { username: user, password: pass }),
  authenticateToken: token => post("/api/login_token", { token }),
  authenticateChangePasswordToken: data =>
    post("/api/change_password_token", data),
  authenticateTimeClock: (user, pass) =>
    post("/api/time_clock/login", { username: user, password: pass }),
  switchToTimeClock: () => put("/api/time_clock/login", {}),
  checkIfLoggedIn: () => post("/api/self", {}),
  checkIfLoggedInTimeClock: () => post("/api/time_clock/self", {}),
  forgotPassword: data => post("/api/forgot_password", data),
  logOut: () => post("/api/logout", {}),
  changePassword: data => put("/api/password", data),
  getCompanies: () => get("/admin/companies"),
  createPaydayBilling: data => post("/api/billing/payday", data),
  cancelBilling: data => post("/api/billing/delete", data),
  getEmployees: () => get("/api/employees"),
  getProjects: () => get("/api/projects"),
  getTransactions: params => {
    return get("/api/worklogs" + addQueryString(params));
  },
  getRefreshTransactions: (id, query) => {
    return get("/api/worklogs/" + id + "/refresh" + addQueryString(query));
  },
  getLocations: id => get("/api/worklogs/" + id + "/locations"),
  createCompany: data => post("/admin/companies", data),
  createEmployee: data => post("/api/employees", data),
  editEmployee: data => put("/api/employees", data),
  enableEmployee: id => put("/api/employees/" + id + "/enable"),
  disableEmployee: id => put("/api/employees/" + id + "/disable"),
  createProject: data => post("/api/projects", data),
  closeProject: id => put("/api/projects/" + id + "/close"),
  openProject: id => put("/api/projects/" + id + "/open"),
  editProject: data => put("/api/projects", data),
  addWorkLogComment: data => post("/api/worklogs/comments", data),
  addWorkLog: data => post("/api/worklogs", data),
  changeWorkLog: data => put("/api/worklogs", data),
  deleteWorkLog: id => post("/api/worklogs/" + id + "/delete"),
  createReport: params => get("/api/report" + addQueryString(params)),
  settings: data => post("/api/settings", data),
  requestTrial: data => post("/api/request-trial", data),
  addWorkLogTimeClock: data => post("/api/time_clock/create_work_log", data),
  closeWorkLogTimeClock: data => put("/api/time_clock/close_work_log", data),
  addWorkLogCommentTimeClock: data =>
    post("/api/time_clock/create_work_log_comment", data),
  updateLastSeenNotification: data =>
    put("/api/notifications/update_last_seen", data),

  // Employee api
  authenticateEmployee: (company, employee, pass) =>
    post("/employee/v2/login", {
      company_name: company,
      employee_name: employee,
      password: pass,
    }),
  getTransactionsForEmployee: params => {
    return get("/employee/worklogs" + addQueryString(params));
  },
  addWorkLogForEmployee: data => post("/employee/worklogs/add", data),
  addWorkLogCommentForEmployee: data =>
    post("/employee/worklogs/comments", data),
  getLocationsForEmployee: id => get("/employee/worklogs/" + id + "/locations"),
  changeWorkLogForEmployee: data => put("/employee/worklogs/edit", data),
};
