import React from "react";
import { Grid, Row, Col, Glyphicon } from "react-bootstrap";

import { translateWithGlobals } from "../utils/Translations";
import { getCopyrightString } from "../utils/HelperFunctions";
import TimaveraEmail from "./TimaveraEmail";

// prettier-ignore
const translations = {
  "Location": { "is": "Staðsetning" },
  "Reykjavík, Iceland": { "is": "Reykjavík, Ísland" },
  "Galway, Ireland": { "is": "Galway, Írland" },
  "Seattle, USA": { "is": "Seattle, USA" },
  "Call": { "is": "Hringdu" },
  "Email": { "is": "Tölvupóstur" },
  "Site sections": { "is": "Síður" },
  "Company number": { "is": "Kennitala" },
  "Privacy": { "is": "Persónuvernd" },
  "Terms": { "is": "Skilmálar" },
  "Cookies": { "is": "Vefkökur" },
  "Changelog": { "is": "Breytingarsaga" },
};

const t = key => translateWithGlobals(key, translations);

const verticalSpace = "3em";
const verticalSpacing = {
  marginTop: verticalSpace,
  marginBottom: verticalSpace,
};

/**
 * The bottom part of our page, phone numbers, location, email, copyright, etc.
 */
export default function Footer(props) {
  const links = [
    ["/#about", t("About Tímavera")],
    ["/#pricing", t("Pricing")],
    ["/#trial", t("Start Trial")],
    ["/#contact", t("Contact us")],
    ["/#company", t("About the company")],
    ["/#testimonials-videos", t("Testimonials")],
    ["/#login", t("Company login")],
    ["/privacy", t("Privacy")],
    ["/terms", t("Terms")],
    ["/cookies", t("Cookies")],
    ["/changelog", t("Changelog")],
  ];

  return (
    <footer className="footer">
      <Grid className="text-center">
        <Row>
          <Col md={4} style={verticalSpacing}>
            <h4>
              <Glyphicon glyph="phone" /> {t("Call")}
            </h4>
            <p>
              <a href="tel:+3545395118">+354 539 5118</a>
            </p>
          </Col>

          <Col md={4} style={verticalSpacing}>
            <h4>
              <Glyphicon glyph="map-marker" /> {t("Location")}
            </h4>
            <p>
              {t("Reykjavík, Iceland")} <br />
              {t("Galway, Ireland")} <br />
              {t("Seattle, USA")}
            </p>
          </Col>

          <Col md={4} style={verticalSpacing}>
            <h4>
              <Glyphicon glyph="envelope" /> {t("Email")}
            </h4>
            <p>
              <TimaveraEmail />
            </p>
          </Col>
        </Row>

        <Row>
          <Col
            xs={10}
            xsOffset={1}
            sm={10}
            smOffset={1}
            md={8}
            mdOffset={2}
            lg={8}
            lgOffset={2}
            style={{ marginBottom: verticalSpace }}
          >
            <h4>
              <Glyphicon glyph="bookmark" /> {t("Site sections")}
            </h4>
            <p>
              {/* For all links in the footer */}
              {links.map(([linkHref, linkLabel], index) => {
                // Don't add a spacer to the last link
                const spacerIfNotLastLink =
                  index + 1 !== links.length ? " - " : null;

                return (
                  <span key={index}>
                    <a href={linkHref}>{linkLabel}</a>
                    {spacerIfNotLastLink}
                  </span>
                );
              })}
            </p>
          </Col>
        </Row>

        <Row className="copyright" style={{ marginBottom: "4em" }}>
          <Col>
            <p className="small">
              {getCopyrightString()} <br />
              {t("Company number")}: 4706170630
            </p>
          </Col>
        </Row>
      </Grid>
    </footer>
  );
}
