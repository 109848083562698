// The default user is the anonymous user. These values should match the values
// in the initial Rollbar setup configuration in public/index.html.
const ANON_USER_ID = -1;
const ANON_USERNAME = "anonymous";

/** If the user logs out we switch to the anonymous user */
export function setRollbarToAnonymousUser() {
  setRollbarPerson(ANON_USER_ID, ANON_USERNAME);
}

/** Sets a company Rollbar person object to track errors */
export function setRollbarCompanyPerson(companyID, companyName) {
  setRollbarPerson(`CompanyID: ${companyID}`, `Company: "${companyName}"`);
}

/**
 * Sets an employee Rollbar person object to track errors. The company ID and
 * name inputs can be left blank if we do not have access to them at the call
 * site. However passing it along will be helpful and save us some time if
 * there are errors.
 *
 * Example calls:
 *    setRollbarEmployeePerson(242, "employee1", 313, "comp5");
 *    setRollbarEmployeePerson(242, "employee1");
 */
export function setRollbarEmployeePerson(
  employeeID,
  employeeName,
  companyID = null,
  companyName = null
) {
  const id = `CompanyID: ${companyID} EmployeeID: ${employeeID}`;
  const username = `Company: "${companyName}" Employee: "${employeeName}"`;

  setRollbarPerson(id, username);
}

/**
 * Augment the Rollbar person object so we can know which companies or
 * employees had errors. This is useful for when we can't figure out how an
 * error happened (we can reach out and ask). It is also helpful to be able to
 * inform our affected users of a bug we have since fixed and apologize
 * for the inconvenience.
 *
 * Since we can have multiple types of users (companies, employees) with
 * overlapping IDs we use a string convention to avoid clashes of IDs. We can
 * do this because the `id` and `username` attributes on the `person` object in
 * Rollbar are all strings.
 *
 * Example company Rollbar person object:
 * {
 *   id: `CompanyID: 313`,
 *   username: `Company: "comp5"`
 * }
 *
 * Example employee Rollbar person object:
 * {
 *   id: `CompanyID: 313 EmployeeID: 242`,
 *   username: `Company: "comp5" Employee: "user"`
 * }
 *
 * We can change this convention but we will loose the link to past errors in
 * Rollbar. Should not be a problem but something to be aware of.
 *
 * For docs see:
 *   https://docs.rollbar.com/docs/javascript#section-usage
 *   https://docs.rollbar.com/docs/rollbarjs-configuration-reference#section-payload
 *
 * @param id string
 * @param username string
 */
function setRollbarPerson(id, username) {
  const isRollbarAvailable =
    window.Rollbar && typeof window.Rollbar.configure === "function";

  if (isRollbarAvailable) {
    window.Rollbar.configure({
      payload: {
        person: {
          id: id,
          username: username,
        },
      },
    });
  }
}

export function RollbarError(message, props = {}) {
  const isRollbarAvailable =
    window.Rollbar && typeof window.Rollbar.error === "function";

  if (isRollbarAvailable) {
    window.Rollbar.error(message, props);
  }
}

export function RollbarWarning(message, props = {}) {
  const isRollbarAvailable =
    window.Rollbar && typeof window.Rollbar.warning === "function";

  if (isRollbarAvailable) {
    window.Rollbar.warning(message, props);
  }
}
