import React from "react";
import { Alert, Button } from "react-bootstrap";
import { translate } from "../utils/Translations";

// prettier-ignore
const translations = {
  "Error": { is: "Villa" },
  "Hide": { is: "Fela" },
};

const t = key => translate(key, translations);

function AlertDismissible(props) {
  const { message, onDismiss } = props;

  if (message) {
    return (
      <Alert bsStyle="danger" onDismiss={onDismiss}>
        <h3>
          {t("Error")}{" "}
          <span role="img" aria-label="Monkey">
            🙈
          </span>
        </h3>
        <p>{message}</p>
        <p>
          <Button onClick={onDismiss}>{t("Hide")}</Button>
        </p>
      </Alert>
    );
  }

  return null;
}

export default AlertDismissible;
