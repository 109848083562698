import { RollbarError } from "../utils/Rollbar";

const defaultState = {
  message: null,
  shouldLogOut: false,
};

function unpackErrorMessage(error) {
  // Lets unpack the error!
  if (!error.response) {
    return error;
  }

  if (error.response.data) {
    if (error.response.data.error) {
      return error.response.data.error;
    }
    return error.response.data;
  }

  return error.statusText ? error.statusText : error;
}

export default function reducer(state = defaultState, action) {
  const type = action.type;

  // All api requests that get rejected have SOMETHING_REJECTED as an action
  // type. We don't want to forward error handling on AUTHENTICATION errors so
  // we ignore all those.
  if (!type.includes("AUTHENTICATION") && type.includes("REJECTED")) {
    const error = action.payload;

    if (error.response && error.response.status === 401) {
      // Lets reroute to the frontpage on all
      // 401 (UNAUTHORIZED) as we aren't logged in
      return { ...state, shouldLogOut: true };
    }

    // This is a background request with no loading icons.
    // We don't want to display error message for failed background requests
    if (type.includes("FETCH_REFRESH_TRANSACTIONS_REJECTED")) {
      return state;
    }

    const message = unpackErrorMessage(error);
    if (typeof message === "string") {
      return { ...state, message: message };
    }

    RollbarError(
      `API error: ${action.type}. Payload JSON: '${JSON.stringify(message)}'`
    );

    return {
      ...state,
      message:
        "Unknown error. It has been recorded and we will investigate as " +
        "soon as we can. We are sorry for the inconvenience. Please get in " +
        "touch if it is urgent.",
    };
  }

  if (type === "CLEAR_ERROR" || type === "LOG_OUT") {
    return { ...state, message: null, shouldLogOut: false };
  }

  return state;
}
