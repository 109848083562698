import React from "react";
import {
  Col,
  Image,
  Nav,
  Navbar,
  NavItem,
  NavDropdown,
  Row,
  MenuItem,
} from "react-bootstrap";

import { t } from "../utils/Translations";
import timaveraLogo from "../images/logo-path.svg";

import { isIcelandicDomain } from "../utils/HelperFunctions";

const changeLanguage = language => {
  localStorage.setItem("language", language);
  window.location.reload();
};

/** Navigation bar at the top, selection burger, etc. */
export default function NavigationBar(props) {
  return (
    <Navbar inverse fixedTop collapseOnSelect className="seeThroughNavBar">
      <Navbar.Header>
        <Navbar.Brand>
          <a href="/#page-top">
            <Row>
              <Col xs={4}>
                <Image
                  src={timaveraLogo}
                  className="img-centered"
                  responsive
                  alt=""
                />
              </Col>
              <Col xs={8} style={{ marginLeft: "-1em" }}>
                Tímavera
              </Col>
            </Row>
          </a>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight>
          {isIcelandicDomain ? (
            <NavDropdown
              id="language-dropdown"
              title={localStorage.getItem("language") === "EN" ? "🇺🇸" : "🇮🇸"}
              active={false}
            >
              <MenuItem
                href=""
                active={false}
                onSelect={() => changeLanguage("IS")}
              >
                <span role="img" aria-label="icelandic language">
                  🇮🇸 Íslenska
                </span>
              </MenuItem>
              <MenuItem
                href=""
                active={false}
                onSelect={() => changeLanguage("EN")}
              >
                <span role="img" aria-label="english language">
                  🇺🇸 English
                </span>
              </MenuItem>
            </NavDropdown>
          ) : null}
          <NavItem eventKey={0} href="#page-top" className="hidden" />
          <NavItem eventKey={1} href="#about">
            {t("About Tímavera")}
          </NavItem>
          <NavItem eventKey={2} href="#pricing">
            {t("Pricing")}
          </NavItem>
          <NavItem eventKey={3} href="#trial">
            {t("Start Trial")}
          </NavItem>
          <NavItem eventKey={4} href="#login">
            {t("Log In")}
          </NavItem>
          {/*
              There are few things that are deliberately not included in the
              navigation bar, such as user testimonals, about the company, etc.
              Don't think it warrants the limited space. I see testimonials as
              being something extra, people just scroll by it, they don't
              actively look it up.
           */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
