export default function reducer(
  state = {
    scripts: [],
    data: [],
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case "FETCH_ADMIN_SCRIPTS":
    case "RUN_ADMIN_SCRIPT": {
      return { ...state, fetching: true, data: [] };
    }
    case "FETCH_ADMIN_SCRIPTS_REJECTED":
    case "RUN_ADMIN_SCRIPT_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_ADMIN_SCRIPTS_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        scripts: action.payload,
      };
    }
    case "RUN_ADMIN_SCRIPT_FULFILLED": {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
