const getValueByPath = (object, path) => {
  // Allows us to provide a path in case we have nested objects.
  // Example: We have worklog objects and they have a nested project.name attribute
  // this will allow us to sort the worklog objects based on the worklog.project.name attribute
  return path
    .split(".")
    .reduce((o, key) => (o && o[key] !== undefined ? o[key] : null), object);
};

const isBigger = (a, b, desc) => {
  if (typeof a === "string" && b) {
    // Strings need to be sorted via locale
    // since "A" != "a" and local characters matter
    if (a.localeCompare(b) === -1) return desc ? 1 : -1;
    if (a.localeCompare(b) === 1) return desc ? -1 : 1;
  }
  // Not a string so we do regular comparisons. We handle null cases first as a
  // loss. Then we compare sizes and order by desc/asc
  if (!a || a < b) return desc ? 1 : -1;
  if (!b || a > b) return desc ? -1 : 1;
  return 0;
};

export default function sortBy(oldAttribute, newAttribute, isDesc, data) {
  /**
    data is a list of identical objects that all have the attributes
    oldAttribute and newAttribute. The list is then sorted based on the
    newAttribute

    If we are sorting by the same attribute (new === old),
    we alternate between sorting the list in descending or ascending order.

    We then return the ordered list and whether it is in descending or
    ascending order.
  **/

  if (oldAttribute === newAttribute) {
    // Alternate between descending and ascending
    // for consecutive clicks on same attribute
    isDesc = !isDesc;
  } else if (newAttribute.endsWith("name")) {
    // If we ever are sorting something by name,
    // it is more intuitive to do so in ascending order
    isDesc = false;
  } else {
    // Default is descending
    isDesc = true;
  }

  const sorted = data.sort((a, b) => {
    const aValue = getValueByPath(a, newAttribute);
    const bValue = getValueByPath(b, newAttribute);
    const res = isBigger(aValue, bValue, isDesc);
    // The values are the same but since we have access to the old attribute as well
    // lets sort by that.
    // This is nice as it allows you to first sort by attribute X and then by Y.
    // The downside here is that people might not realise this and feel like it is non-deterministic
    // I guess most people won't notice this, but I love it as it allows me to sort by X and then Y.
    if (res === 0 && oldAttribute && oldAttribute !== newAttribute) {
      return isBigger(
        getValueByPath(a, oldAttribute),
        getValueByPath(b, oldAttribute),
        isDesc
      );
    }
    return res;
  });

  return { sorted, isDesc };
}
