import React from "react";

import { getTimaveraEmail } from "../utils/HelperFunctions";

/** Convenience <a> mailto component for Tímavera's email address */
const TimaveraEmail = () => {
  const email = getTimaveraEmail();

  return <a href={`mailto:${email}`}>{email}</a>;
};

export default TimaveraEmail;
