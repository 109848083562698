import React from "react";
import { Button, Modal } from "react-bootstrap";
import { translate } from "../../utils/Translations";

const translations = {
  "Please log in again": { is: "Vinsamlegast skráðu þig inn aftur" },
  "Your login has expired.": { is: "Innskráning þín rann út." },
  "Please click the button below to log back in.": {
    is: "Vinsamlegast smelltu á hnappinn að neðan til að skrá þig aftur inn.",
  },
  "Go to log in": { is: "Fara á innskráningarsíðu" },
};

const t = key => translate(key, translations);

function LoggedOut(props) {
  const { show, routeToFrontPage } = props;

  return (
    <Modal show={show} onHide={routeToFrontPage}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Please log in again")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">{t("Your login has expired.")}</p>
        <p className="text-center">
          {t("Please click the button below to log back in.")}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          bsStyle="success"
          block={true}
          onClick={routeToFrontPage}
        >
          {t("Go to log in")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LoggedOut;
