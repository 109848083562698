import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap-button-loader";
import {
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
} from "react-bootstrap";

import { authenticate } from "../actions";
import { translate } from "../utils/Translations";
import { handleNetworkErrors, typeOfErrors } from "../utils/ErrorHandling";

import s from "./EmployeeLoginForm.module.css";

// prettier-ignore
const translations = {
  "Company": { is: "Fyrirtæki" },
  "Password": { is: "Lykilorð" },
  "Log in as a company": { is: "Innskrá sem fyrirtæki" },

  "Wrong company name": { is: "Rangt fyrirtækjanafn" },
  "Wrong company password": { is: "Rangt lykilorð fyrirtækis" },

  "Network error": { is: "Netkerfisvilla" },
};

const t = key => translate(key, translations);

export const feedbackStyle = {
  // A hack to center the validation X in the center
  marginTop: "0.25em",
};

export const HelpBlockIfError = ({ isError, message }) =>
  isError ? <HelpBlock>{message}</HelpBlock> : null;

/**
 * Displays common network errors if any.
 *
 * This serves as a fallback display for error handling. It is assumed that all
 * business logic error scenarios have been handled before. If not the error is
 * caught and classified as an unknown error.
 *
 * @param error {object} Axios error object
 * @param handled {boolean} true if the error has previously been handled, if
 *   false a network or a catch-all unknown error is reported
 * @param codeLocation {string} A clue for use in Rollbar to know where the
 *   error originated from, for example "CompanyLoginForm.js"
 */
export const HelpBlockIfNetworkError = ({ error, handled, codeLocation }) => {
  if (!error || handled) return null;

  // Set and extract a human friendly error message text label
  let errorMessage = t("Network error"); // Default / fallback
  const setErrorMsg = errorMsg => (errorMessage = errorMsg);
  handleNetworkErrors(error, codeLocation, setErrorMsg);

  return (
    <FormGroup validationState="error">
      <HelpBlock>{errorMessage}</HelpBlock>
    </FormGroup>
  );
};

/**
 * A company name and password login form.
 *
 * @param isTimeClock {boolean} true if logging into web time clock, false
 *   otherwise
 */
const CompanyLoginForm = ({ isTimeClock = false }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");

  const { wrongCompanyName, wrongCompanyPassword } = typeOfErrors(user?.error);
  const companyLoginError = wrongCompanyName || wrongCompanyPassword;

  const onCompanyNameEdit = event => setCompanyName(event.target.value);
  const onPasswordEdit = event => setPassword(event.target.value);
  const onLoginSubmit = event => {
    event.preventDefault();
    dispatch(authenticate(companyName, password, isTimeClock));
  };

  return (
    <Form onSubmit={onLoginSubmit}>
      <FormGroup
        controlId="companyNameInput"
        bsSize="large"
        validationState={wrongCompanyName ? "error" : null}
      >
        <ControlLabel>{t("Company")}</ControlLabel>
        <FormControl
          disabled={user.fetching}
          type="text"
          onChange={onCompanyNameEdit}
          placeholder={t("Company")}
        />
        <FormControl.Feedback style={feedbackStyle} />
        <HelpBlockIfError
          isError={wrongCompanyName}
          message={t("Wrong company name")}
        />
      </FormGroup>

      <FormGroup
        controlId="companyPasswordInput"
        bsSize="large"
        validationState={wrongCompanyPassword ? "error" : null}
      >
        <ControlLabel>{t("Password")}</ControlLabel>
        <FormControl
          disabled={user.fetching}
          type="password"
          onChange={onPasswordEdit}
          placeholder={t("Password")}
        />
        <FormControl.Feedback style={feedbackStyle} />
        <HelpBlockIfError
          isError={wrongCompanyPassword}
          message={t("Wrong company password")}
        />
      </FormGroup>

      <HelpBlockIfNetworkError
        error={user?.error}
        handled={companyLoginError}
        codeLocation="CompanyLoginForm.js"
      />

      <Button
        type="submit"
        bsStyle="primary"
        bsSize="large"
        block
        disabled={user.fetching}
        loading={user.fetching}
        className={s.logInButton}
      >
        {t("Log in as a company")}
      </Button>
    </Form>
  );
};

export default CompanyLoginForm;
