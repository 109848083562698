import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { changePassword, switchToTimeClock } from "../actions";
import { CenteredLoadingSpinner } from "../components/LoadingSpinner";
import {
  ChangePassword,
  CancelSubscription,
  SwitchToTimeClock,
} from "./modals";
import { translate } from "../utils/Translations";

// prettier-ignore
const translations = {
  "Cancel subscription": { is: "Hætta í áskrift" },
  "Change password": { is: "Breyta lykilorði" },
  "Billing": { is: "Áskrift" },
  "Security": { is: "Öryggi" },
  "Account": { is: "Aðgangur" },
  "Switch to time clock account": { is: "Skipta yfir í stimpilklukku aðgang"},
};

const t = key => translate(key, translations);

const Settings = ({ user, dispatch }) => {
  const [showCancelSubscription, setShowCancelSubscription] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showTimeClock, setShowTimeClock] = useState(false);

  if (user.fetching) {
    return <CenteredLoadingSpinner />;
  }

  return (
    <>
      <h3>{t("Billing")}</h3>
      <Button
        disabled={!user.data.paying_customer}
        bsStyle="primary"
        onClick={() => setShowCancelSubscription(true)}
      >
        {t("Cancel subscription")}
      </Button>
      <br />
      <br />

      <h3>{t("Security")}</h3>
      <Button bsStyle="primary" onClick={() => setShowChangePassword(true)}>
        {t("Change password")}
      </Button>
      <br />
      <br />

      <h3>{t("Account")}</h3>
      <Button bsStyle="primary" onClick={() => setShowTimeClock(true)}>
        {t("Switch to time clock account")}
      </Button>

      {/*<h3>Language</h3>*/}
      {/*<h3>Report settings</h3>*/}

      {/*Modals*/}
      <CancelSubscription
        show={showCancelSubscription}
        onCloseModal={() => setShowCancelSubscription(false)}
      />
      <ChangePassword
        show={showChangePassword}
        onCloseModal={() => setShowChangePassword(false)}
        onSubmit={data => dispatch(changePassword(data))}
      />
      <SwitchToTimeClock
        show={showTimeClock}
        onCloseModal={() => setShowTimeClock(false)}
        onSubmit={data => dispatch(switchToTimeClock(data))}
      />
    </>
  );
};

export default connect(store => {
  return { user: store.user };
})(Settings);
