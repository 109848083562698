import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Glyphicon,
  Grid,
  Modal,
  Row,
} from "react-bootstrap";

import "./SetupLayout.css";
import { getTimaveraEmail, isSmallScreen } from "../../utils/HelperFunctions";
import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  // Exit modal confirmation
  "Confirm setup exit": { is: "Hætta uppsetningu" },
  "exit-explanation": {
    en: "Do you want to skip the setup steps and go straight to the " +
      "dashboard? There is no harm in doing so. Everything in the setup and " +
      "much more can be done on the dashboard.",
    is: "Viltu sleppa við uppsetningu og fara beint á stjórnborðið? Það er " +
      "minnsta mál og fullkomlega í lagi. Allt í uppsetningunni og margt " +
      "fleira er hægt að framkvæma á stjórnborðinu."
  },
  "Continue setup": { is: "Halda áfram í uppsetningu" },
  "Exit setup": { is: "Hætta uppsetningu" },

  "Setup": { is: "Uppsetning" },

  "Error": { is: "Villa" },
  "Errors": { is: "Villur" },
  "error-explanation": {
    en: "Please try refreshing the page. If the problem persists please get " +
      `in touch at ${getTimaveraEmail()}. We have been notified and will ` +
      "investigate as soon as possible.",
    is: "Vinsamlegast reyndu að endurhlaða síðunni. Ef vandamálið hverfur " +
      `ekki vinsamlegast hafðu samband á ${getTimaveraEmail()}. Okkur hefur ` +
      "verið gert viðvart og við munum skoða málið eins fljótt og auðið er.",
  },
};

const t = key => translate(key, translations);

export const Spacer = ({ padding = "2em" }) => {
  // There is no need for a spacer on small devices such as mobile phones.
  // In fact, it is detrimental because on mobiles the available viewport
  // height is much less because of the software keyboard when typing.
  if (isSmallScreen) return null;

  return <div style={{ paddingTop: padding, paddingBottom: padding }} />;
};

/** Defines the column content width responsiveness in a single place */
export const RowCol = ({ children }) => (
  <Row>
    <Col xs={10} xsOffset={1} sm={8} smOffset={2} md={6} mdOffset={3}>
      {children}
    </Col>
  </Row>
);

/**
 * A <p> paragraph wrapper that adds extra top spacing on larger screens but
 * skips it on smaller screens because of the limited height available when
 * typing on a software keyboards.
 */
export const P = ({ children }) => {
  // Add extra margins on desktops but skip it on mobile because of
  // the limited height available when typing on a software keyboards.
  let paragraphStyle = { marginTop: "2em" };
  if (isSmallScreen) paragraphStyle = {};

  return (
    <p className="text-center" style={paragraphStyle}>
      {children}
    </p>
  );
};

/** Modal that pops up when pressing X in the upp right hand corner if  */
const CloseSetupModal = ({ show, onCloseModal, onExitOnboarding }) => (
  <Modal show={show} onHide={onCloseModal}>
    <Modal.Header closeButton>
      <Modal.Title>{t("Confirm setup exit")}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>{t("exit-explanation")}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle="success" bsSize="large" onClick={onCloseModal} block>
        {t("Continue setup")}
      </Button>
      <Button bsSize="large" onClick={onExitOnboarding} block>
        {t("Exit setup")}
      </Button>
    </Modal.Footer>
  </Modal>
);

/** Displays a list of errors in a red alert. Hidden if no errors. */
const ErrorAlert = ({ errors }) => {
  if (errors.length === 0) return null;

  const title = errors.length === 1 ? t("Error") : t("Errors");

  return (
    <RowCol>
      <Alert bsStyle="danger" style={{ marginTop: "2em" }}>
        <h4>{title}:</h4>
        <ul>
          {errors.map(error => (
            <li key={error}>{error}</li>
          ))}
        </ul>
        <br />
        <p>{t("error-explanation")}</p>
      </Alert>
    </RowCol>
  );
};

/** Back button in upper left, shown on login and entry screens. */
const BackButton = ({ onClick, showBackButton }) => {
  const buttonClass = showBackButton ? "" : "setupButtonHidden";
  const onClickFn = showBackButton ? onClick : () => {};

  return (
    <Button bsStyle="link" onClick={onClickFn} className={buttonClass}>
      <Glyphicon glyph="chevron-left" className="setupBackCloseButton" />
    </Button>
  );
};

/** Close button in upper right, shown on all screens. */
const CloseButton = ({ onClick }) => (
  <Button bsStyle="link" onClick={onClick}>
    <Glyphicon glyph="remove" className="setupBackCloseButton" />
  </Button>
);

/** Overall page layout for the setup. All content is contained here. */
const SetupLayout = ({ children, prevScreen, showBackButton, errors }) => {
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [exitOnboarding, setExitOnboarding] = useState(false);

  if (exitOnboarding) return <Redirect to="/app" />;

  return (
    <div className="onboarding bg-white">
      <div className="setupBackCloseContainer">
        <BackButton onClick={prevScreen} showBackButton={showBackButton} />
        <h1 className="setupHeading">{t("Setup")}</h1>
        <CloseButton onClick={() => setShowCloseModal(true)} />
      </div>

      <ErrorAlert errors={errors} />

      <Grid>{children}</Grid>

      <Spacer />
      <Spacer />

      <CloseSetupModal
        show={showCloseModal}
        onCloseModal={() => setShowCloseModal(false)}
        onExitOnboarding={() => setExitOnboarding(true)}
      />
    </div>
  );
};

export default SetupLayout;
