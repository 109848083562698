import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { translateWithGlobals } from "../../utils/Translations";
import { SendSms } from "../";
import { isValidPhoneNumberForm } from "../SendSms";

// prettier-ignore
const translations = {
  "Send SMS": { is: "Senda innskráningar upplýsingar með SMS" },
  "Close window": { "is": "Loka glugga" },
  "Phone number": { is: "Símanúmer" },
};

const t = key => translateWithGlobals(key, translations);

function SendLoginInformation(props) {
  const { show, onSubmit, onCloseModal, employee } = props;
  const [phoneNumber, setPhoneNumber] = useState(employee?.phone_number ?? "");
  const enableSubmit = isValidPhoneNumberForm(phoneNumber);

  const closeModal = () => {
    setPhoneNumber("");
    onCloseModal();
  };

  const submit = e => {
    e.preventDefault();
    onSubmit({
      id: employee.id,
      new_name: employee.name,
      new_token: employee.login_token,
      phone_number: phoneNumber,
    });
    setPhoneNumber("");
    onCloseModal();
  };

  if (!employee) return <div />;

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Send SMS")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submit}>
        <Modal.Body>
          <SendSms
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            overrideLabel={t("Phone number")}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!enableSubmit}
            type="submit"
            bsStyle="success"
            block={true}
            onClick={submit}
          >
            {t("Send SMS")}
          </Button>
          <Button block={true} onClick={closeModal}>
            {t("Close window")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default SendLoginInformation;
