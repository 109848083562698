import React, { useState } from "react";
import { Button, Modal, Form, FormControl, FormGroup } from "react-bootstrap";

import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Create project": { is: "Búa til verkefni" },
  "Project name": { is: "Nafn á verkefni" },
  "Add project": { is: "Bæta við verkefni" },
  "Close window": { is: "Loka glugga" },
};

const t = key => translate(key, translations);

function CreateProject({ show, onSubmit, onCloseModal }) {
  const [name, setName] = useState("");

  const handleNameChange = e => setName(e.target.value);
  const submitDisabled = name.length === 0;

  return (
    <Modal show={show} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Create project")}</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmit({ name });
        }}
      >
        <Modal.Body>
          <FormGroup controlId="formProjectNameControl">
            <FormControl
              type="text"
              onChange={handleNameChange}
              placeholder={t("Project name")}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={submitDisabled}
            type="submit"
            bsStyle="success"
            block={true}
            onClick={e => {
              e.preventDefault();
              onSubmit({ name });
            }}
          >
            {t("Add project")}
          </Button>
          <Button onClick={onCloseModal} block={true}>
            {t("Close window")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateProject;
