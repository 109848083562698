import sortBy from "../utils/SortBy";

export default function reducer(
  state = {
    data: [],
    sortedBy: {
      attribute: "created",
      isDesc: true,
    },
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case "CREATE_COMPANY":
    case "FETCH_COMPANIES": {
      return { ...state, fetching: true };
    }
    case "CREATE_COMPANY_REJECTED":
    case "FETCH_COMPANIES_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_COMPANIES_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload,
      };
    }
    case "CREATE_COMPANY_FULLFILLED": {
      const newState = {
        ...state,
        fetching: false,
        fetched: true,
      };
      newState.data.unshift(action.payload);
      return newState;
    }
    case "SORT_COMPANY": {
      const attribute = action.payload;
      const res = sortBy(
        state.sortedBy.attribute,
        attribute,
        state.sortedBy.isDesc,
        state.data
      );

      return {
        ...state,
        data: res.sorted,
        sortedBy: {
          attribute: attribute,
          isDesc: res.isDesc,
        },
      };
    }
    default: {
      return state;
    }
  }
}
