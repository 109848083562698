import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { logger } from "redux-logger";
import promise from "redux-promise-middleware";
import reducers from "./reducers";

const REDUX_LOGGER_ENABLED = false;
const middlewares = [promise(), thunk];

if (REDUX_LOGGER_ENABLED) {
  middlewares.push(logger);
}

const store = createStore(reducers, applyMiddleware(...middlewares));

export default store;
