import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import Label from "react-bootstrap/lib/Label";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import Imgix from "react-imgix";

import { Show } from "../Show";
import { commentListIsEmpty } from "../../utils/HelperFunctions";
import { CenteredLoadingSpinner } from "../LoadingSpinner";
import { translateWithGlobals } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Comments": { is: "Athugasemdir" },
  "Add comment": { is: "Bæta við athugasemd" },

  "Employee": { is: "Starfsmaður" },
  "Company": { is: "Fyrirtæki" },
  "Timeclock website": { is: "Vefsíðu stimpilklukka" },
  "No comments have been added": {
    is: "Engum athugasemdum hefur verið bætt við",
  },
};

const t = key => translateWithGlobals(key, translations);

/**
 * Get a human-readable label for who created a comment. Comes from the
 * column `created_by` in the `workLogs` table. One idea to improve this
 * would be return the actual name of the company or employee. For example
 * return "malco, " instead of "Fyrirtæki, ".
 */
const createdByText = createdBy => {
  if (createdBy === "Employee") return `${t("Employee")}, `;
  if (createdBy === "Company") return `${t("Company")}, `;
  if (createdBy === "CompanyTimeClock") return `${t("Timeclock website")}, `;

  // For when createdBy is "Unknown" and all other invalid values. The value
  // "Unknown" was used as the default value for all past work log comments
  // when the database column was added. This was because we can't know if an
  // employee or the company created it. Most of the time it was probably the
  // employee but, we do not want to fabricate history. Better to just display
  // nothing.
  return "";
};

function Comment({ comment }) {
  const commentStr = comment.comment;
  const isImageComment = commentStr.startsWith("https://timavera-images");
  const commentAuthor = createdByText(comment?.created_by ?? "");
  const commentCreatedTime = moment(comment.created).format(
    "HH:mm, D MMM YYYY"
  );

  if (commentStr === "") return null;

  return (
    <div style={{ marginBottom: "15px" }}>
      <p>
        <strong>{commentAuthor + commentCreatedTime}</strong>: <br />
        {!isImageComment && commentStr}
      </p>
      {isImageComment && <ImageComment comment={commentStr} />}
    </div>
  );
}

function ImageComment({ comment }) {
  const [isLoaded, setIsLoaded] = useState(false);

  // Serve images from our image CDN imigx instead of directly from S3
  const imgixUrl = comment.replace(
    "https://timavera-images.s3-eu-west-1.amazonaws.com/",
    "https://timavera.imgix.net/"
  );

  const sourceUrl = imgixUrl + "?fm=jpg&q=80";
  const imgContainerStyle = !isLoaded ? { display: "none" } : null;
  const onImageLoad = () => setIsLoaded(true);

  return (
    <a href={sourceUrl} target="_blank" rel="noopener noreferrer">
      {!isLoaded && <CenteredLoadingSpinner />}
      <div style={imgContainerStyle}>
        <Imgix
          src={imgixUrl}
          sizes="(max-width: 767px) calc(100vw - 52px), 568px"
          htmlAttributes={{
            style: { maxWidth: "100%" },
            onLoad: onImageLoad,
            onError: onImageLoad,
          }}
        />
      </div>
    </a>
  );
}

function CommentList({ comments }) {
  if (commentListIsEmpty(comments)) {
    return (
      <h4>
        <Label>{t("No comments have been added")}</Label>
      </h4>
    );
  }

  return comments.map((comment, index) => (
    <Comment comment={comment} key={index} />
  ));
}

function WorkLogComments(props) {
  const { show, id, workLogComments, onCloseModal, onSubmit } = props;
  const [comment, setComment] = useState("");
  const userIsDeactivatedEmployee = useSelector(
    state => state.user?.data?.disabled ?? false
  );

  const hasPermissionToAddComments = !userIsDeactivatedEmployee;

  const closeModal = () => {
    setComment("");
    onCloseModal();
  };

  const submit = e => {
    e.preventDefault();
    const data = { work_log_id: id, comment: comment };
    onSubmit(data);
    closeModal();
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Comments")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submit}>
        <Modal.Body>
          <CommentList comments={workLogComments} />
        </Modal.Body>
        <Modal.Footer>
          <Show showIf={hasPermissionToAddComments}>
            <FormGroup controlId="formWorkLogCommentControl">
              <FormControl
                type="text"
                value={comment}
                onChange={e => setComment(e.target.value)}
                placeholder={t("Add comment")}
              />
            </FormGroup>
            <Button
              type="submit"
              bsStyle="success"
              disabled={!comment.length}
              block={true}
              onClick={submit}
            >
              {t("Add comment")}
            </Button>
          </Show>
          <Button block={true} onClick={closeModal}>
            {t("Close window")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default WorkLogComments;
