import React, { useState } from "react";
import moment from "moment";

import { Alert, Button, Glyphicon, Table } from "react-bootstrap";

import { toIcelandicDate } from "../utils/DateFormat";
import SortableTableHeader from "./SortableTableHeader";
import EditEmployee from "./modals/EditEmployee";
import SendLoginInformation from "./modals/SendLoginInformation";
import { PasswordToken } from "./";
import { translate } from "../utils/Translations";
import { toIcelandicDateTimeString } from "../utils/DateFormat";

// prettier-ignore
const translations = {
  "Employee passwords": { is: "Lykilorð starfsmanna" },
  "Send SMS": { is: "Senda SMS" },
  "Name": { is: "Nafn" },
  "Edit": { is: "Breyta" },
  "Created": { is: "Búinn til" },
  "Last entry": { is: "Síðasta færsla" },
  "No entry yet": { is: "Engar færslur enn" },
  "Active employees": { is: "Virkir starfsmenn" },
  "Inactive employees": { is: "Óvirkir starfsmenn" },
  "No employees have been created": { is: "Engir starfsmenn hafa verið búnir til" },
};

const t = key => translate(key, translations);

const getLatestWorkLogData = employee => {
  if (!employee.latest_work_log) {
    return <td>Engar færslur enn</td>;
  }
  const isToday = moment(employee.latest_work_log).isSame(moment(), "day");
  const style = isToday ? { fontWeight: "bold" } : {};
  return (
    <td style={style}>{toIcelandicDateTimeString(employee.latest_work_log)}</td>
  );
};

function EmployeesTable(props) {
  const { employees, sortedBy, onEditEmployee, onSendSms, onSortEmployees } =
    props;
  const zeroEmployees = employees.length === 0;
  const EmptyEmployeesTableNoticeRow = (
    <tr>
      <td colSpan={9} style={{ textAlign: "center" }}>
        <Alert bsStyle="warning" style={{ marginBottom: 0 }}>
          {t("No employees have been created")}
        </Alert>
      </td>
    </tr>
  );

  return (
    <Table responsive striped bordered condensed hover>
      <thead>
        <tr>
          <SortableTableHeader
            name={t("Name")}
            attribute="name"
            sortedBy={sortedBy}
            onSort={onSortEmployees}
          />
          <SortableTableHeader
            name={t("Created")}
            attribute="created"
            sortedBy={sortedBy}
            onSort={onSortEmployees}
          />
          <SortableTableHeader
            name={t("Last entry")}
            attribute="latest_work_log"
            sortedBy={sortedBy}
            onSort={onSortEmployees}
          />
          <th className="text-center">{t("Edit")}</th>
          <th className="text-center">{t("Employee passwords")}</th>
        </tr>
      </thead>
      <tbody>
        {/* Display a notice if the employee table is empty */}
        {zeroEmployees ? EmptyEmployeesTableNoticeRow : null}

        {/* Employees, one per row */}
        {employees.map((employee, i) => {
          return (
            <tr key={i}>
              <td>{employee.name}</td>
              <td>{toIcelandicDate(employee.created)}</td>
              {getLatestWorkLogData(employee)}
              <td className="text-center">
                <Button
                  bsStyle="default"
                  bsSize="xsmall"
                  onClick={() => onEditEmployee(employee)}
                >
                  <Glyphicon glyph="edit" />
                </Button>
              </td>
              <td className="text-center" style={{ verticalAlign: "middle" }}>
                <PasswordToken token={employee.login_token} />
                <Button
                  bsStyle="default"
                  bsSize="xsmall"
                  onClick={() => onSendSms(employee)}
                >
                  {t("Send SMS")}
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function Employees(props) {
  const {
    employees,
    sortedBy,
    onEditEmployee,
    onChangeEmployeeState,
    onSortEmployees,
  } = props;
  const [showEditEmployee, setShowEditEmployee] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showSendLoginInformation, setShowSendLoginInformation] =
    useState(null);

  const openEditEmployeeModal = employee => {
    setShowEditEmployee(true);
    setSelectedEmployee(employee);
  };
  const closeEditEmployeeModal = () => {
    setShowEditEmployee(false);
    setSelectedEmployee(null);
  };
  const openSendLoginInformationModal = employee => {
    setShowSendLoginInformation(true);
    setSelectedEmployee(employee);
  };
  const closeSendLoginInformationModal = () => {
    setShowSendLoginInformation(false);
    setSelectedEmployee(null);
  };

  const selectedEmployeeID = (selectedEmployee && selectedEmployee.id) || 0;
  const disabledEmployees = employees.filter(employee => employee.disabled);
  const hasAnyDisabledEmployees = disabledEmployees.length !== 0;

  return (
    <div>
      {hasAnyDisabledEmployees ? <h3>{t("Active employees")}</h3> : null}
      <EmployeesTable
        employees={employees.filter(employee => !employee.disabled)}
        sortedBy={sortedBy}
        onEditEmployee={openEditEmployeeModal}
        onSendSms={openSendLoginInformationModal}
        onSortEmployees={onSortEmployees}
      />

      {hasAnyDisabledEmployees ? (
        <div style={{ marginTop: "3em" }}>
          <h3>{t("Inactive employees")}</h3>
          <EmployeesTable
            employees={disabledEmployees}
            sortedBy={sortedBy}
            onEditEmployee={openEditEmployeeModal}
            onSendSms={openSendLoginInformationModal}
            onSortEmployees={onSortEmployees}
          />
        </div>
      ) : null}

      <EditEmployee
        show={showEditEmployee}
        onSubmit={onEditEmployee}
        onCloseModal={closeEditEmployeeModal}
        employee={selectedEmployee}
        onChangeEmployeeState={onChangeEmployeeState}
        key={selectedEmployeeID}
      />
      <SendLoginInformation
        show={showSendLoginInformation}
        onSubmit={onEditEmployee}
        onCloseModal={closeSendLoginInformationModal}
        employee={selectedEmployee}
        key={selectedEmployeeID + 1}
      />
    </div>
  );
}

export default Employees;
