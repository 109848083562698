import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  FormControl,
  FormGroup,
} from "react-bootstrap/lib";

import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Edit project": { is: "Breyta verkefni" },
  "Close window": { is: "Loka glugga" },
};

const t = key => translate(key, translations);

function EditProject({ show, project, onSubmit, onCloseModal }) {
  const [projectName, setProjectName] = useState(project ? project.name : "");

  const projectNameHasNotChanged = projectName === (project && project.name);
  const projectNameIsEmpty = projectName.length === 0;
  const submitDisabled = projectNameIsEmpty || projectNameHasNotChanged;

  if (!project) return <div />;

  return (
    <Modal show={show} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Edit project")}</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmit({ id: project.id, new_name: projectName });
          onCloseModal();
        }}
      >
        <Modal.Body>
          <FormGroup controlId="formProjectNameControl">
            <FormControl
              type="text"
              onChange={event => setProjectName(event.target.value)}
              value={projectName}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={submitDisabled}
            type="submit"
            bsStyle="success"
            block={true}
            onClick={event => {
              event.preventDefault();
              onSubmit({ id: project.id, new_name: projectName });
              onCloseModal();
            }}
          >
            {t("Edit project")}
          </Button>
          <Button block={true} onClick={onCloseModal}>
            {t("Close window")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EditProject;
