import React from "react";
import { Button, Col, Grid, Image, Row } from "react-bootstrap";

import { translateWithGlobals, lang } from "../../utils/Translations";
import { Spacer } from "../setup/SetupLayout";

import enAppScreenshotSplash from "../../images/splash/time-tracking-app.png";
import isAppScreenshotSplash from "../../images/splash/tímaskráning-app.png";

const appScreenshotSplash =
  lang === "is" ? isAppScreenshotSplash : enAppScreenshotSplash;

// prettier-ignore
const translations = {
  "Start Trial": { is: "Nýskráning" },

  "tagline-subtext": {
    en: "Save money by accurately tracking project & employee hours with a " +
      "simple app. Everything in a single place. Made in Seattle, USA 🇺🇸",
    is: "Sparaðu með því að halda utan um nákvæmar tímaskráningar verkefna " +
      "og starfsmanna í einföldu appi. Allt á einum stað. Íslenskt hugvit 🇮🇸",
  },

  "screenshot-alt": {
    en: "Screenshot of the Tímavera time tracking app for contractors",
    is: "Skjáskot af Tímaveru tímaskráningar appinu fyrir verktaka",
  }
};

const t = key => translateWithGlobals(key, translations);

const dropShadow = {
  filter: "drop-shadow(5px 5px 10px #2222227F)",
};

/** First thing a user sees when landing on our website, top of front page. */
function SplashScreen(props) {
  const title = t("Simple time tracking for contractors");

  return (
    <section>
      <Grid>
        <Row>
          <Col xs={10} xsOffset={1} md={8} mdOffset={2}>
            <h1 className="brand-name" style={{ marginTop: "3em" }}>
              {title}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col sm={8} smOffset={2} md={6} mdOffset={3}>
            <p className="brand-name-subtext">{t("tagline-subtext")}</p>
          </Col>
        </Row>

        <Row>
          <Col xs={10} xsOffset={1} className="text-center">
            <Button
              href="#trial"
              bsStyle="primary"
              bsSize="large"
              style={{
                marginTop: "2em",
                marginBottom: "2em",
                padding: "1em",
                textDecoration: "none",
                // Temporary style to match splash screenshots below
                backgroundColor: "#ae0002",
                borderColor: "#ae0002",
              }}
            >
              {t("Start Trial")}
            </Button>
          </Col>
        </Row>

        <Spacer padding="4em" />

        <Row>
          <Col md={8} mdOffset={2} sm={10} smOffset={1} xs={8} xsOffset={2}>
            <Image
              src={appScreenshotSplash}
              responsive
              alt={t("screenshot-alt")}
              style={dropShadow}
              loading="lazy"
            />
          </Col>
        </Row>
      </Grid>
    </section>
  );
}

export default SplashScreen;
