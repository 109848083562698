import React from "react";
import { Helmet } from "react-helmet";

/**
 * Adds <link> to the <head> of a page telling search engines where they can
 * find the same URL at a different language that we offer.
 *
 * Note that this was the first naive version of the component. It offers
 * alternative href lang for different domains but it assumed that the URL
 * paths were all in English. This has been improved on slightly in
 * <HrefLang2 /> but it may require more work and context to get it right.
 *
 * Use HrefLang2 instead if possible and offer routes localised in Icelandic as
 * well. However if the path is the same on all website it is okay to use this.
 */
export default function HrefLang(props) {
  // Example routes: "/" (front page), "/login", "/privacy"
  const { route } = props;

  const hrefs = {
    isHref: `https://timavera.is${route}`,
    enHref: `https://timavera.com${route}`,
    ukHref: `https://timavera.co.uk${route}`,
    ieHref: `https://timavera.ie${route}`,
  };

  return <HrefLangRegions {...hrefs} />;
}

/**
 * Adds <link> to the <head> of a page telling search engines where they can
 * find the same URL at a different language that we offer.
 *
 * It assumes we will localise URL paths in Icelandic and English. If we start
 * to localise in more languages this component needs to be updated. One idea
 * would be to default to the English path if a localised path prop is missing.
 *
 * Receives two URL path props of the following form:
 *   + pathIs: "/timavera-er-timaskraningar-kerfi"
 *   + pathEn: "/timavera-is-a-time-tracking-system"
 */
export function HrefLang2(props) {
  const { pathIs, pathEn } = props;

  const hrefs = {
    isHref: `https://timavera.is${pathIs}`,
    enHref: `https://timavera.com${pathEn}`,
    ukHref: `https://timavera.co.uk${pathEn}`,
    ieHref: `https://timavera.ie${pathEn}`,
  };

  return <HrefLangRegions {...hrefs} />;
}

/**
 * Adds <link> to the <head> of a page telling search engines where they can
 * find the same URL at a different language that we offer. For example the
 * Icelandic version of our privacy policy might be at
 * https://timavera.is/personuverndarstefna but the English version could be
 * at https://timavera.com/privacy.
 *
 * The component accepts a URL string prop for every version of our website we
 * offer. Example for isHref: "https://timavera.is".
 *
 * See:
 *   + https://support.google.com/webmasters/answer/182192?hl=en
 *   + https://support.google.com/webmasters/answer/189077
 *   + https://app.asana.com/0/1127547622593744/1128488970297840/f
 *   + https://app.asana.com/0/1114857724208938/1127547622593750/f
 */
function HrefLangRegions(props) {
  const { isHref, enHref, ukHref, ieHref } = props;

  return (
    <Helmet>
      <link rel="alternate" hrefLang="is" href={isHref} />
      <link rel="alternate" hrefLang="en" href={enHref} />
      <link rel="alternate" hrefLang="en-gb" href={ukHref} />
      <link rel="alternate" hrefLang="en-ie" href={ieHref} />
      <link rel="alternate" hrefLang="x-default" href={isHref} />
    </Helmet>
  );
}
