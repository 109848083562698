import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Col, Grid, Row } from "react-bootstrap";

import { t } from "../../utils/Translations";
import { dismissUserError } from "../../actions";
import { scrollToTopOfPage } from "../../utils/HelperFunctions";
import CompanyLoginForm from "../../components/CompanyLoginForm";
import EmployeeLoginForm from "../../components/EmployeeLoginForm";

import "./frontpage-styles.css";

/** Defines a common section and grid layout for company & employee login */
const LoginSection = ({ heading, sectionId, children }) => (
  <section id={sectionId} className="bg-dark">
    <Grid className="container">
      <Row>
        <Col
          xs={10}
          sm={10}
          md={8}
          lg={8}
          xsOffset={1}
          smOffset={1}
          mdOffset={2}
          className="text-center"
        >
          <h2>{heading}</h2>
          <hr className="colored" />
        </Col>
      </Row>
      <Row>
        <Col
          xs={10}
          sm={10}
          md={8}
          lg={8}
          xsOffset={1}
          smOffset={1}
          mdOffset={2}
        >
          {children}
        </Col>
      </Row>
    </Grid>
  </section>
);

/** The sections of the front page for company and employee logins */
const FrontpageLogin = props => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  useEffect(() => {
    // In case someone comes here after having tried to log in as an employee we clear the employee login error.
    dispatch(dismissUserError());
  }, [dispatch]);

  if (user.fetched && user.role === "COMPANY") {
    scrollToTopOfPage();
    // Newly created company
    if (user.data.latest_work_log === null) return <Redirect to="/app/setup" />;
    // Company gone through setup before
    return <Redirect to="/app" />;
  }

  if (user.fetched && user.role === "COMPANY_TIME_CLOCK") {
    scrollToTopOfPage();
    return <Redirect to="/time_clock" />;
  }

  if (user.fetched && user.role === "EMPLOYEE") {
    scrollToTopOfPage();
    return <Redirect to="/employee/app" />;
  }

  return (
    <>
      {/* Company login */}
      <LoginSection heading={t("Company login")} sectionId="login">
        <CompanyLoginForm />
        <p style={{ marginTop: "1em", textAlign: "center" }}>
          <Link to="/forgot_password">{t("Forgot password?")}</Link>
        </p>
        <p style={{ marginTop: "1em", textAlign: "center" }}>
          {t("Don't have an account?")} <br />
          <a href="#trial">{t("Start a trial")}.</a>
        </p>
      </LoginSection>

      {/* Employee login */}
      <LoginSection heading={t("Employee login")} sectionId="employeeLogin">
        <EmployeeLoginForm />
      </LoginSection>

      <div style={{ height: "100px" }} />
    </>
  );
};

export default FrontpageLogin;
