import React from "react";
import { Helmet } from "react-helmet";

import { t } from "../../utils/Translations";
import SplashScreen from "./SplashScreen";
import SocialProof from "./SocialProof";
import About from "./About";
import Description from "./Description";
import Pricing from "./Pricing";
import TestimonialsText from "./TestimonialsText";
import TestimonialsVideos from "./TestimonialsVideos";
import ContactUs from "./ContactUs";
import Trial from "./Trial";
import AboutTheCompany from "./AboutTheCompany";
import HeaderFooter from "../../components/HeaderFooter";
import HrefLang from "../../components/HrefLang";
import FrontpageLogin from "./FrontpageLogin";
import { aasAppID } from "../../utils/Constants";

import "./frontpage-styles.css";

const Frontpage = props => {
  return (
    <HeaderFooter>
      <HrefLang route="/" />
      <Helmet>
        <title>
          Tímavera | {t("Simple time tracking for contractors").toLowerCase()}
        </title>
        {/* Native app download banner on iOS Safari */}
        <meta name="apple-itunes-app" content={`app-id=${aasAppID}`} />
      </Helmet>

      <>
        <SplashScreen />
        <SocialProof />
        <About />
        <TestimonialsVideos />
        <Description />
        <Pricing />
        <Trial />
        <ContactUs />
        <AboutTheCompany />
        <TestimonialsText />
        <FrontpageLogin />
      </>
    </HeaderFooter>
  );
};

export default Frontpage;
