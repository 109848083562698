import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  Alert,
} from "react-bootstrap";

import API from "./../../api";
import { translate } from "../../utils/Translations";
import LoadingSpinner from "../../components/LoadingSpinner";
import { typeOfErrors, handleNetworkErrors } from "../../utils/ErrorHandling";
import { isIcelandicDomain, isInvalidEmail } from "../../utils/HelperFunctions";
import { RollbarWarning } from "../../utils/Rollbar";

// prettier-ignore
const translations = {
  "Company name": { is: "Nafn fyrirtækis" },
  "Phone number": { is: "Símanúmer" },
  "PHONE_NUMBER_PLACEHOLDER": {
    en: "Example: +354 539 5118",
    is: "Símanúmer",
  },
  "Email": { is: "Tölvupóstur" },
  "Start Trial": { is: "Stofna aðgang" },
  "Start trial again": { is: "Stofna aðgang aftur" },

  "14 day free trial": { is: "14 daga frí prufa" },
  "No up front payment": { is: "Áskrift staðfest eftirá" },

  "creating": { is: "stofna" },
  "account": { is: "aðgang" },
  "Already have an account?": { is: "Nú þegar með aðgang?"},
  "Click here to log in.": { is: "Smelltu hér til að innskrá."},

  // Input validation & error messages
  "Whoops! Something went wrong": { is: "Obbosí! Eitthvað fór úrskeiðis" },
  "errorEmptyCompanyName": {
    en: "Company name can not be empty, it will become the account name.",
    is: "Fyrirtækja nafn getur ekki verið tómt, það verður að aðgangsnafninu.",
  },
  "errorInvalidEmail": {
    en: "A valid email is required so we can send the login credentials.",
    is: "Við þurfum gilt tölvupóstfang til að senda innskráningar upplýsingar.",
  },
  "companyAlreadyExistsPart1": {
    en: "The account name ",
    is: "Fyrirtæki með nafnið ",
  },
  "companyAlreadyExistsPart2": {
    en: " already exists. Please pick a different name.",
    is: " er til nú þegar. Vinsamlegast veljið annað nafn.",
  },
};

const t = key => translate(key, translations);

/** The Start Trial submit button or a loading indicator */
function SubmitLoadingButton({ onClick, isLoading }) {
  const spinnerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    // The 0.65em margin is a lazy hack to get the loading spinner to take up
    // the same amount of space as the submit button. Could use improvements.
    margin: "0.65em 0",
  };

  const creationLabelStyle = {
    color: "#222",
    fontSize: "18px",
    textTransform: "uppercase",
    fontWeight: "900",
    margin: "0 1em",
  };

  if (isLoading) {
    return (
      <>
        <div style={spinnerStyle}>
          <span style={creationLabelStyle}>{t("creating")}</span>
          <LoadingSpinner />
          <span style={creationLabelStyle}>{t("account")}</span>
        </div>
      </>
    );
  }

  return (
    <Button
      type="submit"
      bsStyle="primary"
      bsSize="large"
      block
      onClick={onClick}
    >
      {t("Start Trial")}
    </Button>
  );
}

/** A red alert box showing a bullet list of error messages. E.g. validation. */
function ErrorMessages({ errorMessages }) {
  if (errorMessages.length === 0) return null;

  return (
    <Alert bsStyle="danger">
      <h4>
        <span role="img" aria-label="cat gasping">
          🙀
        </span>{" "}
        {t("Whoops! Something went wrong")}
      </h4>
      <ul>
        {errorMessages.map((errorMsg, index) => (
          <li key={index}>{errorMsg}</li>
        ))}
      </ul>
    </Alert>
  );
}

function PhoneInputIfIceland({ setPhone, isLoading }) {
  if (!isIcelandicDomain) return null;

  return (
    <FormGroup controlId="formCompanyPhoneControl" bsSize="large">
      <ControlLabel>{t("Phone number")}</ControlLabel>
      <FormControl
        type="text"
        onChange={e => setPhone(e.target.value)}
        placeholder={t("PHONE_NUMBER_PLACEHOLDER")}
        disabled={isLoading}
      />
    </FormGroup>
  );
}

/**
 * Trial form inputs for company name, phone, and email. When the user presses
 * submit on the form the submit button changes to a loading indicator. If the
 * trial request was successful the page redirects to the dashboard. If there
 * were any errors for example input validation an alert box is shown below the
 * submit button.
 */
function TrialForm(props) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [externalErrors, setExternalErrors] = useState([]);

  // Basic input validation and error handling
  let errors = [...externalErrors];
  const emptyCompanyName = name === "";
  const invalidEmail = isInvalidEmail(email);
  if (emptyCompanyName) errors.push(t("errorEmptyCompanyName"));
  if (invalidEmail) errors.push(t("errorInvalidEmail"));
  const validationErrors = emptyCompanyName || invalidEmail;

  const getCompanyAlreadyExistErrorMessage = () =>
    t("companyAlreadyExistsPart1") +
    '"' +
    name +
    '"' +
    t("companyAlreadyExistsPart2");

  const submit = e => {
    e.preventDefault();

    if (invalidEmail && email !== "") {
      const msg = `Trial: invalid email. Company: ${name}, email: "${email}"`;

      // This manual error report is a false positive safety valve for our
      // email validation. That is, if a prospect attempts to create an account
      // with a non-empty email our validation rejects, we get notified of it.
      // If we notice a valid email that our validation rejected we at least
      // get to know about it and correct the mistake.
      RollbarWarning(msg, { companyName: name, email: email });
    }

    // If we have invalid input, show warnings and abort network request
    if (validationErrors) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);
    setExternalErrors([]);

    API.requestTrial({ name: name, phone: phone, email: email }).then(
      resp => {
        if (resp) {
          setIsLoading(false);
          dispatch({ type: "FETCH_AUTHENTICATION_FULFILLED", payload: resp });
        }
      },
      error => {
        const { companyAlreadyExists } = typeOfErrors(error);

        const onError = errorMessage => {
          setExternalErrors([errorMessage]);
          setShowErrors(true);
          setIsLoading(false);
        };

        const onNetworkError = errorMessage => onError(errorMessage);

        if (companyAlreadyExists) {
          onError(getCompanyAlreadyExistErrorMessage());
        } else {
          handleNetworkErrors(error, "Trial Requests", onNetworkError);
        }
      }
    );
  };

  return (
    <div>
      <Form onSubmit={submit}>
        <FormGroup controlId="formCompanyNameControl" bsSize="large">
          <ControlLabel>{t("Company name")}</ControlLabel>
          <FormControl
            type="text"
            onChange={e => setName(e.target.value)}
            placeholder={t("Company name")}
            disabled={isLoading}
          />
        </FormGroup>
        <PhoneInputIfIceland setPhone={setPhone} isLoading={isLoading} />
        <FormGroup controlId="formCompanyEmailControl" bsSize="large">
          <ControlLabel>{t("Email")}</ControlLabel>
          <FormControl
            type="email"
            onChange={e => setEmail(e.target.value)}
            placeholder={t("Email")}
            disabled={isLoading}
          />
        </FormGroup>
        <SubmitLoadingButton onClick={submit} isLoading={isLoading} />
      </Form>

      <br />

      <ErrorMessages errorMessages={showErrors ? errors : []} />

      <p className="text-center" style={{ marginTop: "1em" }}>
        {t("Already have an account?")} <br />
        <a href="#login">{t("Click here to log in.")}</a>
      </p>

      <p style={{ marginTop: "2em", textAlign: "center" }}>
        {t("14 day free trial")}
        <br />
        {t("No up front payment")}
      </p>
    </div>
  );
}

export default TrialForm;
