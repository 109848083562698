import React, { useState } from "react";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";

import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Add Comment": { "is": "Bæta við athugsemd" },
  "Your comment ...": { "is": "Þín athugasemd ..." },
  "Close window": { "is": "Loka glugga" },
};

const t = key => translate(key, translations);

function CreateWorkLogComment(props) {
  const { show, onCloseModal, onSubmit } = props;
  const [comment, setComment] = useState("");

  const closeModal = () => {
    setComment("");
    onCloseModal();
  };

  const submit = e => {
    e.preventDefault();
    onSubmit(comment);
    closeModal();
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Add Comment")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submit}>
        <Modal.Body>
          <FormGroup controlId="formWorkLogCommentControl">
            <FormControl
              type="text"
              value={comment}
              onChange={e => setComment(e.target.value)}
              placeholder={t("Your comment ...")}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            bsStyle="success"
            disabled={!comment.length}
            block={true}
            onClick={submit}
          >
            {t("Add Comment")}
          </Button>
          <Button block={true} onClick={closeModal}>
            {t("Close window")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateWorkLogComment;
