import React from "react";
import { Helmet } from "react-helmet";

import { translate } from "../utils/Translations";

// prettier-ignore
const translations = {
  // The HTML meta description translation strings are very important for SEO.
  // Word choice can have a large impact on organic and paid search results.
  "htmlMetaDescription": {
    // Meta description as suggested by Marta's copy editing work of Mar 2024.
    en: "The Tímavera app and website makes time tracking and management" +
      " for contractors and small business owners simple. Try for free.",

    // The three most important and well performing keywords to include:
    //   + stimpilklukka
    //   + tímaskráning
    //   + tímaskráningarkerfi
    is: "Tímavera er einfalt tímaskráningarkerfi sem notar síma starfsmanna " +
      "í stimpilklukku appi. Tímaskráning verkefna og starfsmanna öll á " +
      "einum stað. Frí prufa.",
  }
};

const t = key => translate(key, translations);

/**
 * Contents in the <head> tag on the HTML page that we want control or add
 * dynamically. For stuff that is static and doesn't change see the file
 * public/index.html.
 */
export default function Header(props) {
  return (
    <Helmet>
      {/*
        The brief description of the site. It is our suggestion how search
        engines should describe the site in search results. Search engines can
        however choose to ignore it if it feels the content of the site does
        not match up with our description and make its own. Translated so that
        the correct language is used for domain.
      */}
      <meta name="description" content={t("htmlMetaDescription")} />
    </Helmet>
  );
}
