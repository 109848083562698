export default function reducer(
  state = {
    data: [],
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case "CREATE_REPORT": {
      return { ...state, fetching: true };
    }
    case "CREATE_REPORT_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "CREATE_REPORT_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload,
      };
    }
    case "CLEAR_REPORT": {
      return {
        ...state,
        data: [],
        fetched: false,
      };
    }
    default: {
      return state;
    }
  }
}
