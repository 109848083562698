import React, { useState, useEffect } from "react";
import Button from "react-bootstrap-button-loader";
import { Glyphicon, ProgressBar } from "react-bootstrap";
import moment from "moment";

import { translate } from "../utils/Translations";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

// prettier-ignore
const translations = {
  "Download Excel document": { is: "Hlaða niður Excel" },
  "Date": { is: "Dagsetning" },
  "In": { is: "Inn" },
  "Out": { is: "Út" },
  "Entries": { is: "Færslur" },
  "Employee": { is: "Starfsmaður" },
  "Project": { is: "Verkefni" },
  "Projects": { is: "Verkefni" },
  "Hours": { is: "Tímar" },
  "Regular Time": { is: "Dagvinna" },
  "Over Time": { is: "Yfirvinna" },
  "Lunch": { is: "Hádegi" },
  "Comments": { is: "Athugasemdir" },
  "Images": { is: "Myndir" },
  "Image": { is: "Mynd" },
  "Total": { is: "Samtals" },
  "to": { is: "til" },
};

const t = key => translate(key, translations);

/**
 * Returns a compact and human-readable string for time period for a file name.
 *
 * Forms a part in the Excel file name string. The string is as compact as
 * possible. For example if the range spans the same month the month name is
 * not repeated. Same goes for year. If month and year differ they appear.
 *
 * Examples:
 *   + "1 to 24 Oct 2024"          // same month and year
 *   + "1 Sep to 24 Oct 2024"      // different months but same year
 *   + "1 Jan 2021 to 24 Oct 2024" // different month and years
 *
 * @param from {string} start ISO 8601, example: "2024-11-25T02:59:55Z"
 * @param to {string} end ISO 8601, example: "2024-12-25T02:59:55Z"
 * @returns {string} Example: "1 Jan 2021 to 24 Oct 2024"
 */
export const getExcelFileNameDateRange = (from, to) => {
  const start = moment(from);
  const end = moment(to);

  let startFrm = "D MMM YYYY";
  let endFrm = "D MMM YYYY";
  if (start.isSame(end, "year")) {
    startFrm = "D MMM";
    if (start.isSame(end, "month")) {
      startFrm = "D";
    }
  }

  return `${start.format(startFrm)} ${t("to")} ${end.format(endFrm)}`;
};

export const DownloadTimeEntriesExcel = props => {
  // Do not show a download button if there are no time entries
  if (props?.data?.length === 0) return null;

  return (
    <DownloadExcel
      {...props}
      block={true}
      bsStyle="default"
      exportFunction={exportTimeEntriesToExcel}
    />
  );
};

export const DownloadSummaryProjectsExcel = props => (
  <DownloadExcel
    {...props}
    exportComments={false}
    exportImages={false}
    exportFunction={exportSummaryProjectsToExcel}
    bsStyle="default"
  />
);

export const DownloadSummaryEmployeesExcel = props => (
  <DownloadExcel
    {...props}
    exportComments={false}
    exportImages={false}
    exportFunction={exportSummaryEmployeesToExcel}
    bsStyle="default"
  />
);

export const DownloadProjectExcel = props => (
  <DownloadExcel {...props} exportFunction={exportProjectToExcel} />
);

export const DownloadEmployeeExcel = props => (
  <DownloadExcel {...props} exportFunction={exportEmployeeToExcel} />
);

/** A button to download an Excel file export */
const DownloadExcel = ({
  data,
  filename,
  exportComments,
  exportImages,
  exportFunction,
  block = false, // if the button should take full width
  bsStyle = "warning",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Reset this on changes to how the export would look like.
    setProgress(0);
  }, [exportComments, exportImages]);

  const handleClick = async () => {
    setProgress(0);
    setIsLoading(true);
    await exportFunction(
      data,
      filename,
      exportComments,
      exportImages,
      setProgress
    );
    setIsLoading(false);
  };

  return (
    <Button
      disabled={isLoading}
      loading={isLoading}
      onClick={handleClick}
      block={block}
      bsStyle={bsStyle}
      className="hidden-print"
    >
      {t("Download Excel document")}
      {isLoading ? <ProgressBar now={progress} /> : null}
      {progress === 100 ? <Glyphicon glyph="ok" /> : null}
    </Button>
  );
};

// Width in Excel is measured in a unit called "character units", a measurement
// in points based on the width of the zero character ("0") in the default font
// of the workbook. The width may vary based on default settings of a workbook.
// The number 6 here is a very good approximation based on the default fonts of
// Excel to get a one to one mapping.
const pixelToExcelWidth = pixel => pixel / 6;
// Height in Excel is measured in points, where 1 point is measured as 1/72
// of an inch. The conversion factor between pixels and points can vary based
// on the display's pixel density (DPI - dots per inch). DPI can vary based on
// screens, but the standard screen DPI is 96 DPI.
// point = pixels * (72/DPI) = pixel * (72/96) = pixel * 0.75
const pixelToExcelPoint = pixel => pixel * 0.75;
const excelPointToPixel = point => point / 0.75;

const desiredImageWidthInPixels = 100;

// Styles for columns, rows and cells
const center = { alignment: { horizontal: "center" } };
const left = { alignment: { horizontal: "left" } };
const centerTop = { alignment: { horizontal: "center", vertical: "top" } };
// There might be an opportunity to localize the number formatting below.
// For example "0,00" for Icelandic customers. For now leaving as "0.00" for
// the sake of consistency, number of hours has always been formatted that way.
const twoDecimals = { numFmt: "0.00" };
const centerTwoDecimals = { ...center, ...twoDecimals };
const centerTopTwoDecimals = { ...centerTop, ...twoDecimals };

const exportTimeEntriesToExcel = async (
  logData,
  filename,
  shouldExportComments,
  shouldExportImages,
  setProgress
) => {
  let columns = [
    { header: t("Date"), key: "date", width: 12, style: center },
    { header: t("In"), key: "in", width: 7, style: center },
    { header: t("Out"), key: "out", width: 7, style: center },
    { header: t("Hours"), key: "hours", width: 9, style: centerTwoDecimals },
    { header: t("Employee"), key: "employee", width: 15, style: center },
    { header: t("Project"), key: "project", width: 30, style: left },
  ];

  await exportToExcel(logData, filename, columns, setProgress);
};

/** Summary projects report table in Excel */
const exportSummaryProjectsToExcel = async (
  logData,
  filename,
  shouldExportComments,
  shouldExportImages,
  setProgress
) => {
  let columns = [
    {
      header: t("Hours"),
      key: "hours",
      width: 9,
      style: centerTwoDecimals,
    },
    {
      header: t("Regular Time"),
      key: "regulartime",
      width: 9,
      style: centerTwoDecimals,
    },
    {
      header: t("Over Time"),
      key: "overtime",
      width: 9,
      style: centerTwoDecimals,
    },
    {
      // Project name column is last for increased readability because names
      // can vary greatly in length.
      header: t("Project"),
      key: "project",
      width: 30,
      style: left,
    },
  ];

  await exportToExcel(logData, filename, columns, setProgress);
};

/** Summary employees report table in Excel */
const exportSummaryEmployeesToExcel = async (
  logData,
  filename,
  shouldExportComments,
  shouldExportImages,
  setProgress
) => {
  let columns = [
    {
      header: t("Hours"),
      key: "hours",
      width: 9,
      style: centerTwoDecimals,
    },
    {
      header: t("Regular Time"),
      key: "regulartime",
      width: 9,
      style: centerTwoDecimals,
    },
    {
      header: t("Over Time"),
      key: "overtime",
      width: 9,
      style: centerTwoDecimals,
    },
    {
      // Employee name column is last for increased readability because names
      // can vary greatly in length.
      header: t("Employee"),
      key: "employee",
      width: 15,
      style: left,
    },
  ];

  await exportToExcel(logData, filename, columns, setProgress);
};

const exportProjectToExcel = async (
  logData,
  filename,
  shouldExportComments,
  shouldExportImages,
  setProgress
) => {
  let columns = [
    {
      header: t("Date"),
      key: "date",
      width: pixelToExcelWidth(80),
      style: centerTop,
    },
    {
      header: t("Entries"),
      key: "entries",
      width: pixelToExcelWidth(80),
      style: center,
    },
    {
      header: t("Employee"),
      key: "employee",
      width: pixelToExcelWidth(80),
    },
    { header: t("Hours"), key: "hours", style: centerTopTwoDecimals },
    {
      header: t("Regular Time"),
      key: "regulartime",
      width: pixelToExcelWidth(65),
      style: centerTopTwoDecimals,
    },
    {
      header: t("Over Time"),
      key: "overtime",
      width: pixelToExcelWidth(65),
      style: centerTopTwoDecimals,
    },
  ];

  if (shouldExportComments) {
    columns.push({ header: t("Comments"), key: "textComments" });
  }

  if (shouldExportImages) {
    columns.push({ header: t("Images"), key: "images" });
  }

  await exportToExcel(logData, filename, columns, setProgress);
};

const exportEmployeeToExcel = async (
  logData,
  filename,
  shouldExportComments,
  shouldExportImages,
  setProgress
) => {
  let columns = [
    {
      header: t("Date"),
      key: "date",
      width: pixelToExcelWidth(80),
      style: centerTop,
    },
    {
      header: t("Entries"),
      key: "entries",
      width: pixelToExcelWidth(80),
      style: center,
    },
    {
      header: t("Projects"),
      key: "projects",
      width: pixelToExcelWidth(80),
    },
    { header: t("Hours"), key: "hours", style: centerTopTwoDecimals },
    {
      header: t("Regular Time"),
      key: "regulartime",
      width: pixelToExcelWidth(65),
      style: centerTopTwoDecimals,
    },
    {
      header: t("Over Time"),
      key: "overtime",
      width: pixelToExcelWidth(65),
      style: centerTopTwoDecimals,
    },
  ];

  if (shouldExportComments) {
    columns.push({ header: t("Comments"), key: "textComments" });
  }

  if (shouldExportImages) {
    columns.push({ header: t("Images"), key: "images" });
  }

  await exportToExcel(logData, filename, columns, setProgress);
};

const exportToExcel = async (logData, filename, columns, setProgress) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet();
  worksheet.columns = columns;

  let maxCommentColumnWidth = 15; // Start with a default minimum width
  const columnLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];
  let commentColumn;
  let imageColumn;
  let imageColumnIndex;
  columns.forEach((column, index) => {
    if (column.key === "textComments") {
      commentColumn = columnLetters[index];
    }
    if (column.key === "images") {
      imageColumnIndex = index;
      imageColumn = columnLetters[index];
    }
  });

  let rowCount = 0;
  // +1 for the total/sum row at the bottom
  const totalRowCount = logData.length + 1;
  // Add rows and process comments
  for (const item of logData) {
    const row = worksheet.addRow(item);

    if (commentColumn) {
      // Separate text and image comments
      const textComments = item.comments.filter(
        comment => !comment.startsWith("https://timavera-images")
      );
      worksheet.getCell(`${commentColumn}${row.number}`).value =
        textComments.join("\n"); // Concatenate text comments

      // To determine the height of the row, we want the total line count for
      // all the comments for this log. Since each comment adds a new line, and
      // some comments can have new lines within them, we need: all comments
      // count + sum of all new lines in all the comments.
      const textCommentsLineCount =
        textComments.length +
        textComments.reduce(
          (accumulator, comment) => accumulator + comment.split("\n").length,
          0
        );

      if (textCommentsLineCount > 2) {
        // 15 size per newline in comments
        row.height = pixelToExcelPoint(15 * textCommentsLineCount);
      }

      // To determine the length of the column,
      // we need to find the longest single line comment
      const maxCommentLengthInRow = Math.max(
        ...textComments.map(comment =>
          Math.max(...comment.split("\n").map(line => line.length))
        )
      );

      // Update maxCommentColumnWidth if this row has a longer comment
      if (maxCommentLengthInRow > maxCommentColumnWidth) {
        maxCommentColumnWidth = maxCommentLengthInRow;
      }
    }

    if (imageColumn) {
      const imageComments = item.comments
        .filter(comment => comment.startsWith("https://timavera-images"))
        .map(imageComment =>
          imageComment.replace(
            "https://timavera-images.s3-eu-west-1.amazonaws.com/",
            "https://timavera.imgix.net/"
          )
        );

      // If there are no images we just continue without any changes.
      if (!imageComments.length) continue;

      // Process all image comments in parallel
      const imageProcessingPromises = imageComments.map(comment =>
        addImageToWorkbook(workbook, comment)
      );
      const processedImages = await Promise.all(imageProcessingPromises);
      const validImages = processedImages.filter(image => image !== null);

      // If there are no valid images we just continue without any changes.
      if (!validImages.length) continue;

      // See below for explanation.
      const imageOffset = 0.01;
      // Accumulate total height in order to adjust row height to fit all
      // images. We do this before adding the images in order to have correct
      // ratios between all images.
      let totalImageHeight = 0;
      for (const { height } of validImages) {
        totalImageHeight += height + imageOffset;
      }

      // Either the text comment lines or the image height, which ever one is
      // higher. We need to set the height before adding the images in order
      // for the images to fit. The "|| 10" here is just in case that there is
      // no height set to the row (the default).
      row.height = Math.max(row.height || 10, totalImageHeight);

      let previousImagePosition = 0;

      // I am getting a "Function declared in a loop contains unsafe references
      // to variable(s) 'imageColumnIndex'"
      // eslint-disable-next-line
      validImages.forEach(image => {
        // If you have an image and want to add it to row 3 you can select all
        // numbers between 3 and 4. If you select 3.5, the image will start
        // rendering at the middle of row 3 etc. We are selecting to start the
        // next image where the previous one ended. The imageOffset is to make
        // sure that we are never selecting only 3.0 as it might overflow to
        // the above row.
        const relativePositionWithinRow =
          previousImagePosition / totalImageHeight;
        const rowPosition =
          row.number - 1 + relativePositionWithinRow + imageOffset;
        worksheet.addImage(image.imageId, {
          tl: { col: imageColumnIndex + imageOffset, row: rowPosition },
          ext: { width: image.width, height: image.height },
          editAs: "oneCell",
        });
        previousImagePosition += image.height;
      });

      // Set our row height to desired height to make room for all images
      const excelImageMargin = 5;
      row.height =
        pixelToExcelPoint(row.height) +
        excelPointToPixel(
          (excelImageMargin + imageOffset) * validImages.length
        );

      // A more accurate "feel" would be to do this per total images we are
      // handling since text is super quick. This is going to stop per row with
      // comment and hang there for some time. A bad scenario is a report with
      // multiple logs but only a single log contains all the images. We are
      // mostly waiting for the calls to get the images to succeed. Another
      // performance improvement is to fetch ALL images in parallel (not only
      // all images per log in parallel). But leaving both these improvements
      // for a later date.
      rowCount += 1;
      setProgress((rowCount / totalRowCount) * 100);
    }
  }

  if (commentColumn) {
    worksheet.getColumn(commentColumn).width = pixelToExcelPoint(
      maxCommentColumnWidth
    );
  }

  if (imageColumn) {
    worksheet.getColumn(imageColumn).width = pixelToExcelWidth(
      desiredImageWidthInPixels
    );
  }

  // Ensures that cells with line-breaks are displayed as expected by text
  // wrapping instead of being smashed together into long unreadable strings.
  // Happens for example when there are multiple time entries on a single
  // day. Displays each time entry and project one per line in the same row.
  worksheet.eachRow(row => {
    row.eachCell(cell => {
      // Declaring this value seems to be required. Looks like ".value" is some
      // sort of promise or getter function. It is not a direct string prop.
      const value = cell.value;

      if (typeof value === "string" && value.includes("\n")) {
        cell.alignment = { ...cell.alignment, wrapText: true };
      }
    });
  });

  const totalRowIndex = logData.length + 1; // +1 because of the header row
  let totalRowContents = {};
  const keyExists = key => columns.some(column => column.key === key);
  const addTotalIfExists = key => {
    // Ensures the key exists, prevents Exceljs column overflow in getColumn()
    if (keyExists(key)) {
      const columnLetter = worksheet.getColumn(key).letter;

      totalRowContents[key] = {
        // Example formula: "SUM(A2:A10)". Starts at 2 because header is row 1.
        formula: `SUM(${columnLetter}2:${columnLetter}${totalRowIndex})`,
      };
    }
  };

  // Add the total sum row. Only add column keys if they exist in the data.
  if (keyExists("date")) totalRowContents["date"] = t("Total");
  ["hours", "regulartime", "overtime"].forEach(addTotalIfExists);
  const totalRow = worksheet.addRow(totalRowContents);

  // Apply border and style to column header cells
  worksheet.getRow(1).eachCell(cell => {
    cell.font = { bold: true };
    cell.alignment = { horizontal: "center" };
    cell.border = { bottom: { style: "thin" } };
  });

  // Apply border and style to last row that displays total sums
  columns.forEach((value, index) => {
    const cell = totalRow.getCell(index + 1);
    cell.font = { bold: true };
    cell.border = { top: { style: "thin" } };
  });

  // Export workbook
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), filename + ".xlsx");
  setProgress(100);
};

/**
 * The image needs to be available in the workbook before it can be added to a
 * specific sheet/cell.
 */
const addImageToWorkbook = async (workbook, imageUrl) => {
  const response = await fetch(imageUrl + "?fm=jpg&q=60");
  if (!response.ok) return null;
  const blob = await response.blob();
  const base64 = await blobToBase64(blob);

  // Create an image object to get original dimensions
  const img = new Image();
  img.src = base64;
  await new Promise(resolve => {
    img.onload = () => resolve();
  });

  // Calculate new height to maintain aspect ratio. We could provide some
  // parameters to the imgix url in order to get some desired ratios, but this
  // is fine for now.
  const scaleFactor = desiredImageWidthInPixels / img.width;
  const resizedHeight = img.height * scaleFactor;

  const imageId = workbook.addImage({ base64: base64, extension: "jpeg" });
  return {
    imageId: imageId,
    width: desiredImageWidthInPixels,
    height: resizedHeight,
  };
};

const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
