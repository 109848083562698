import sortBy from "../utils/SortBy";

export default function reducer(
  state = {
    data: [],
    sortedData: [],
    sortedBy: {
      attribute: "name",
      isDesc: false,
    },
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case "CREATE_EMPLOYEE":
    case "FETCH_EMPLOYEES": {
      return { ...state, fetching: true };
    }
    case "CREATE_EMPLOYEE_REJECTED":
    case "FETCH_EMPLOYEES_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_EMPLOYEES_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.slice(0),
        sortedData: action.payload.slice(0),
      };
    }
    case "CREATE_EMPLOYEE_FULLFILLED": {
      const newState = {
        ...state,
        fetching: false,
        fetched: true,
      };
      newState.data.unshift(action.payload);
      newState.sortedData.unshift(action.payload);
      return newState;
    }
    case "ENABLE_EMPLOYEE_FULFILLED":
    case "DISABLE_EMPLOYEE_FULFILLED":
    case "EDIT_EMPLOYEE_FULFILLED": {
      state.data.forEach((item, i) => {
        if (item.id === action.payload.id) state.data[i] = action.payload;
      });
      state.sortedData.forEach((item, i) => {
        if (item.id === action.payload.id) state.sortedData[i] = action.payload;
      });
      return { ...state, data: state.data, sortedData: state.sortedData };
    }
    case "SORT_EMPLOYEE": {
      const attribute = action.payload;
      const res = sortBy(
        state.sortedBy.attribute,
        attribute,
        state.sortedBy.isDesc,
        state.data
      );

      return {
        ...state,
        sortedData: res.sorted,
        sortedBy: {
          attribute: attribute,
          isDesc: res.isDesc,
        },
      };
    }
    default: {
      return state;
    }
  }
}
