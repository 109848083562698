export function toHour(number) {
  return number / 3600;
}

export function numberToFixed(number) {
  return toHour(number).toFixed(2);
}

export function numberToFixedString(number) {
  return numberToFixed(number).toString();
}
