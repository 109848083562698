import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
} from "react-bootstrap/lib";

import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Change Password": { is: "Breyta Lykilorði" },
  "Current password": { is: "Núverandi lykilorð" },
  "New password": { is: "Nýtt lykilorð" },
  "Confirm new password": { is: "Staðfesta nýtt lykilorð" },
  "New password and confirmed new password do not match": { is: "Lykilorð og staðfest lykilorð er ekki eins" },
  "Change": { is: "Breyta" },
  "Close": { is: "Loka" },
};

const t = key => translate(key, translations);

const ChangePassword = props => {
  const { show, onSubmit, onCloseModal } = props;
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const enableSubmit =
    currentPassword.length > 0 &&
    newPassword.length > 0 &&
    newPassword === confirmNewPassword;

  return (
    <Modal show={show} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Change Password")}</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmit({
            current_password: currentPassword,
            new_password: newPassword,
          });
          onCloseModal();
        }}
      >
        <Modal.Body>
          <FormGroup controlId="formCurrentPasswordControl">
            <FormControl
              type="password"
              onChange={e => setCurrentPassword(e.target.value)}
              placeholder={t("Current password")}
            />
          </FormGroup>
          <FormGroup controlId="formNewPasswordControl">
            <FormControl
              type="password"
              onChange={e => setNewPassword(e.target.value)}
              placeholder={t("New password")}
            />
          </FormGroup>
          <FormGroup controlId="formConfirmNewPasswordControl">
            <FormControl
              type="password"
              onChange={e => setConfirmNewPassword(e.target.value)}
              placeholder={t("Confirm new password")}
            />
            {newPassword.length > 0 && newPassword !== confirmNewPassword && (
              <HelpBlock style={{ color: "red" }}>
                {t("New password and confirmed new password do not match")}
              </HelpBlock>
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!enableSubmit}
            type="submit"
            bsStyle="success"
            onClick={e => {
              e.preventDefault();
              onSubmit({
                current_password: currentPassword,
                new_password: newPassword,
              });
              onCloseModal();
            }}
          >
            {t("Change")}
          </Button>
          <Button onClick={onCloseModal}>{t("Close")}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ChangePassword;
